import CloseIcon from '@mui/icons-material/Close';
import LanguageIcon from '@mui/icons-material/Language';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import ImportFileButton from 'components/google/drive/files/ImportFileButton';
import MentionCard from 'components/mention/MentionCard';
import MentionCardOrganization from 'components/mention/MentionCardOrganization';
import MentionCardUser from 'components/mention/MentionCardUser';
import dayjs from 'dayjs';
import useGoogleAskCode from 'hooks/google/authentication/useGoogleAskCode';
import useProviderPermissionState from 'hooks/authorization/useProviderPermissionState';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import appActions from 'redux/App/app.actions';
import appSelectors from 'redux/App/app.selectors';
import { useGetFilesQuery } from 'redux/services/drive';
import { useUpdateSharingMutation } from 'redux/services/video';
import { GoogleDriveFile, Permission, Video } from 'redux/services/video.types';
import GoogleDriveLogo from 'ui-kit/logo/google/google-drive-logo.svg';

const DialogStyled = styled(Dialog)(() => ({
  '& .MuiDialog-container': {
    alignItems: 'inherit',
    height: 'inherit',
    maxHeight: '100%',
  },
}));

const UploadVideoModal = () => {
  const dispatch = useDispatch();
  const open = useSelector(appSelectors.isVideoImportModalOpened);

  const [page, setPage] = React.useState<'list' | 'share'>('list');
  const [video, setVideo] = React.useState<Video | undefined>();

  const handleClose = () => {
    dispatch(appActions.videoImportModalChanged(false));
    setVideo(undefined);
  };

  const goToShare = (video: Video) => {
    setPage('share');
    setVideo(video);
  };

  useEffect(() => {
    if (open) {
      setPage('list');
      setVideo(undefined);
      dispatch(appActions.videoImportModalChanged(true));
    }
  }, [dispatch, open]);

  return (
    <DialogStyled
      open={open}
      onClose={handleClose}
      maxWidth="md"
      sx={{ container: { alignItems: 'inherit', maxHeight: '100%' } }}
    >
      {page === 'list' && <DialogContentList goToShare={goToShare} handleClose={handleClose} />}
      {page === 'share' && video && <DialogContentShare video={video} handleClose={handleClose} />}
    </DialogStyled>
  );
};

/**
 * -----------------
 * DialogContentList
 * -----------------
 */
type DialogContentListProps = {
  handleClose: () => void;
  goToShare: (video: Video) => void;
};
const DialogContentList = ({ handleClose, goToShare }: DialogContentListProps) => {
  const { googleDriveReadPermission } = useProviderPermissionState();
  const { askGoogleCode } = useGoogleAskCode();
  const [trashed, setTrashed] = React.useState(false);
  const [starred, setStarred] = React.useState(false);
  const { data: files } = useGetFilesQuery({ trashed, starred });
  const handleImportFile = (file: GoogleDriveFile) => {
    if (!file.video) return;
    goToShare(file.video);
  };

  return (
    <>
      <DialogTitle>
        Import Video from your Google Drive
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {!googleDriveReadPermission && (
          <Box display="flex" flexDirection="row" p={2}>
            <Box width="100px" minHeight="75px">
              <img src={GoogleDriveLogo} alt="Google Drive Logo" width="48px" height="48px" />
            </Box>
            <Box flexGrow={1}>
              <Typography variant={'h3'} gutterBottom>
                Please, authorize Google Drive
              </Typography>
              <Typography variant={'body1'} gutterBottom>
                Goelo does not have access to Google Drive. Please authorize Goelo to access your Google Drive.
              </Typography>
              <Typography variant={'body2'} gutterBottom>
                This permission is required to list videos from your Google Drive.
              </Typography>
              <Box display="flex" justifyContent="space-between" width="100%" mt={3}>
                <Button color="primary" variant="contained" onClick={askGoogleCode}>
                  Authorize Google Drive
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        {googleDriveReadPermission && files && (
          <Box display="flex" gap={2} flexDirection="column">
            <Card>
              <CardContent>
                <Typography variant="body1">Google Drive filters:</Typography>
                <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => {
                          setTrashed(event.target.checked);
                        }}
                      />
                    }
                    label="Including trashed files"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => {
                          setStarred(event.target.checked);
                        }}
                      />
                    }
                    label="Starred files only"
                  />
                </FormGroup>
              </CardContent>
            </Card>
            {files.map((file) => (
              <MediaFileItem key={file.uuid} file={file} handleClose={handleClose} handleImport={handleImportFile} />
            ))}
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} color="info">
          Close
        </Button>
      </DialogActions>
    </>
  );
};

type MediaFileItemProps = {
  file: GoogleDriveFile;
  handleClose: () => void;
  handleImport?: (file: GoogleDriveFile) => void;
};

const MediaFileItemStyled = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 8px;

  .drive-file-image {
    width: 110px;
    height: 62px;
    background-image: url('/assets/google-drive-mp4-64.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30px;
    background-color: #000000;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .drive-file-information {
    flex: 1;
  }
`;

const MediaFileItem = ({ file, handleClose, handleImport }: MediaFileItemProps) => {
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const navigate = useNavigate();

  const handleViewVideo = () => {
    if (!file.video) return;
    handleClose();
    navigate(`/o/${currentOrganizationUuid}/v/${file.video.uuid}`);
  };

  return (
    <MediaFileItemStyled>
      <div className="drive-file-image">
        {file.hasThumbnail && (
          <img src={file.thumbnailLink} loading="lazy" referrerPolicy="no-referrer" alt="thumbnail" />
        )}
      </div>
      <div className="drive-file-information">
        <Typography variant="body1">{file.name}</Typography>
        <Typography variant="body2">{dayjs(file.createdAt).format('MMMM D, YYYY h:mm A')}</Typography>
      </div>
      <div className="drive-file-actions">
        {!file.video && <ImportFileButton uuid={file.uuid} onResult={handleImport} />}
        {file.video && (
          <Link to={`/o/${currentOrganizationUuid}/v/${file.video.uuid}`}>
            <Button variant="outlined" color="info" onClick={handleViewVideo}>
              View video
            </Button>
          </Link>
        )}
      </div>
    </MediaFileItemStyled>
  );
};

/**
 * ------------------
 * DialogContentShare
 * ------------------
 */
type DialogContentShareProps = {
  video: Video;
  handleClose: () => void;
};
const DialogContentShare = ({ video, handleClose }: DialogContentShareProps) => {
  // Global
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const navigate = useNavigate();

  // Organization Sharing
  const [organizationSharing, setOrganizationSharing] = React.useState<Permission>(video.organizationPermission);
  const handleOrganizationSharingChange = (event: SelectChangeEvent) => {
    setOrganizationSharing(event.target.value as Permission);
  };

  // Link Sharing
  const [linkSharing, setLinkSharing] = React.useState<Permission>(video.linkPermission);
  const handleLinkSharingChange = (event: SelectChangeEvent) => {
    setLinkSharing(event.target.value as Permission);
  };

  // Save Sharing settings
  const handleKeepPrivate = async () => {
    handleClose();
    navigate(`/o/${currentOrganizationUuid}/v/${video.uuid}`);
  };
  const [updateSharingMutation] = useUpdateSharingMutation();
  const handleSaveSharing = async () => {
    await updateSharingMutation({
      uuid: video.uuid,
      sharing: { linkPermission: linkSharing, organizationPermission: organizationSharing, teamPermissions: {} },
    });
    handleClose();
    navigate(`/o/${currentOrganizationUuid}/v/${video.uuid}`);
  };

  return (
    <>
      <DialogTitle>
        Share the video
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <MediaFileItemStyled>
          <div className="drive-file-image">
            {video.thumbnailsUrl && (
              <img src={video.thumbnailsUrl} loading="lazy" referrerPolicy="no-referrer" alt="thumbnail" />
            )}
          </div>
          <div className="drive-file-information">
            <Typography variant="body1">{video.title}</Typography>
            <Typography variant="body2">{dayjs(video.createdAt).format('MMMM D, YYYY h:mm A')}</Typography>
          </div>
        </MediaFileItemStyled>
      </DialogContent>
      <Divider />
      <DialogContent>
        <ListItem>
          <ListItemText>
            <MentionCardUser user={video.user} />
          </ListItemText>
          <Button disabled variant="outlined" color="info" sx={{ mx: 2 }}>
            Owner
          </Button>
        </ListItem>
        <ListItem>
          <ListItemText>
            <MentionCardOrganization secondary="Organization" organization={video.organization} />
          </ListItemText>
          <Box sx={{ mx: 2, minWidth: '150px', textAlign: 'right' }}>
            <Select value={organizationSharing} onChange={handleOrganizationSharingChange} size="small">
              <MenuItem value={Permission.COMMENT}>Can comment</MenuItem>
              <MenuItem value={Permission.NONE}>No access</MenuItem>
            </Select>
          </Box>
        </ListItem>
        <ListItem>
          <ListItemText>
            <MentionCard
              avatar={<LanguageIcon />}
              avatarBackgroundColor="transparent"
              primary="Anyone with the link"
              secondary="Anyone on the internet with the link"
            />
          </ListItemText>
          <Box sx={{ mx: 2, minWidth: '150px', textAlign: 'right' }}>
            <Select value={linkSharing} onChange={handleLinkSharingChange} size="small">
              <MenuItem value={Permission.VIEW}>Can view</MenuItem>
              <MenuItem value={Permission.NONE}>No access</MenuItem>
            </Select>
          </Box>
        </ListItem>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleKeepPrivate} color="info">
          Keep the video private
        </Button>
        <Button variant="contained" onClick={handleSaveSharing} color="primary">
          Share
        </Button>
      </DialogActions>
    </>
  );
};

export default UploadVideoModal;
