import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import TranslateIcon from '@mui/icons-material/Translate';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';
import { ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { Bot } from 'redux/services/api.types';
import { useUpdateBotLanguageMutation } from 'redux/services/bot';

type BotLanguageSelectProps = {
  bot: Bot;
};

const languages = [
  { code: 'AUTO', name: 'Auto detect' },
  { code: 'DA', name: 'Danish' },
  { code: 'NL', name: 'Dutch' },
  { code: 'EN', name: 'English' },
  { code: 'FR', name: 'French' },
  { code: 'DE', name: 'German' },
  { code: 'PL', name: 'Polish' },
  { code: 'PT', name: 'Portuguese' },
  { code: 'RU', name: 'Russian' },
  { code: 'ES', name: 'Spanish' },
  { code: 'SV', name: 'Swedish' },
];

const BotLanguageSelect = ({ bot }: BotLanguageSelectProps) => {
  const [updateBotLanguageMutation, updateBotLanguageResult] = useUpdateBotLanguageMutation();
  const menuState = usePopupState({ variant: 'popover', popupId: 'language-select' });
  return (
    <>
      <Tooltip title="Language of the transcript" placement="top" arrow enterDelay={500}>
        <LoadingButton
          loading={updateBotLanguageResult.isLoading}
          loadingPosition="start"
          size="small"
          variant="outlined"
          color="info"
          startIcon={<TranslateIcon />}
          endIcon={menuState.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          {...bindTrigger(menuState)}
        >
          {languages.find((language) => language.code === bot.language)?.name}
        </LoadingButton>
      </Tooltip>
      <Menu {...bindMenu(menuState)}>
        {languages.map((language) => (
          <MenuItem
            key={language.code}
            onClick={() => {
              updateBotLanguageMutation({ uuid: bot.uuid, language: language.code });
              menuState.close();
            }}
            selected={language.code === bot.language}
          >
            <ListItemText>{language.name}</ListItemText>
            <ListItemIcon sx={{ paddingLeft: '12px' }}>
              {language.code === bot.language ? <CheckIcon /> : null}
            </ListItemIcon>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default BotLanguageSelect;
