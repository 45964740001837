import api from 'redux/services/api';
import { SalesforceResource, Tag, TagAddition, TagDeletion, Transcript } from 'redux/services/api.types';
import { Video, VideoSharingSettings } from 'redux/services/video.types';

const videoApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getVideos: builder.query<Video[], { sharing: 'PRIVATE' | 'ORGANIZATION' | 'TEAM'; teamUuid?: string }>({
      query: (filters) => ({
        url: '/videos',
        params: filters,
      }),
      providesTags: ['Video'],
    }),
    searchVideos: builder.query<Video[], { tagUuid: string | undefined }>({
      query: (filters) => ({
        url: '/videos/search',
        params: filters,
      }),
      providesTags: ['Video'],
    }),
    getVideo: builder.query<Video, string | undefined>({
      query: (uuid) => ({
        url: `/videos/${uuid}`,
      }),
      providesTags: ['Video'],
    }),
    deleteVideo: builder.mutation<Video, string | undefined>({
      query: (uuid) => ({
        url: `/videos/${uuid}`,
        method: 'DELETE',
        invalidatesTags: ['Video'],
      }),
    }),
    updateSharing: builder.mutation<Video, { uuid: string; sharing: VideoSharingSettings }>({
      query: ({ uuid, sharing }) => ({
        url: `/videos/${uuid}/sharing`,
        method: 'PUT',
        body: { sharing },
      }),
      invalidatesTags: ['Video'],
    }),
    countVideos: builder.query<{ total: number; overLimit: boolean }, void>({
      query: () => ({
        url: '/videos/count',
      }),
      providesTags: ['VideoCount'],
    }),
    renameVideo: builder.mutation<Video, { uuid: string; title: string }>({
      query: ({ uuid, title }) => ({
        url: `/videos/${uuid}/rename`,
        method: 'PUT',
        body: { title },
      }),
      invalidatesTags: ['Video'],
    }),
    getTranscripts: builder.query<Transcript[], string | undefined>({
      query: (uuid) => ({
        url: `/videos/${uuid}/transcripts`,
      }),
      providesTags: ['Transcript'],
    }),
    vectorize: builder.mutation<Video, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/videos/${uuid}/vectorize`,
        method: 'POST',
      }),
      invalidatesTags: ['Video'],
    }),
    addTag: builder.mutation<Video, TagAddition>({
      query: ({ videoUuid, tag }) => ({
        url: `/videos/${videoUuid}/tags`,
        method: 'POST',
        body: { tag },
      }),
      invalidatesTags: ['Video', 'Tag'],
    }),
    removeTag: builder.mutation<Video, TagDeletion>({
      query: ({ videoUuid, tag }) => ({
        url: `/videos/${videoUuid}/tags`,
        method: 'DELETE',
        body: { tag },
      }),
      invalidatesTags: ['Video', 'Tag'],
    }),
    getTags: builder.query<Tag[], void>({
      query: () => ({
        url: '/tags',
        method: 'GET',
      }),
      providesTags: ['Tag'],
    }),
    addCollection: builder.mutation<Video, { uuid: string; collectionUuid: string }>({
      query: ({ uuid, collectionUuid }) => ({
        url: `/videos/${uuid}/collections`,
        method: 'POST',
        body: { collectionUuid },
      }),
      invalidatesTags: ['Video', 'Collection'],
    }),
    removeCollection: builder.mutation<Video, { uuid: string; collectionUuid: string }>({
      query: ({ uuid, collectionUuid }) => ({
        url: `/videos/${uuid}/collections`,
        method: 'DELETE',
        body: { collectionUuid },
      }),
      invalidatesTags: ['Video', 'Collection'],
    }),
    addSalesforceRecord: builder.mutation<Video, { videoUuid: string; salesforceResource: SalesforceResource }>({
      query: ({ videoUuid, salesforceResource }, ...rest) => {
        return {
          url: `/videos/${videoUuid}/salesforce-records`,
          method: 'POST',
          body: { salesforceResource },
        };
      },
      invalidatesTags: ['Video', 'SalesforceRecord'],
    }),
    removeSalesforceRecord: builder.mutation<Video, { uuid: string; salesforceRecordUuid: string }>({
      query: ({ uuid, salesforceRecordUuid }) => ({
        url: `/videos/${uuid}/salesforce-records/${salesforceRecordUuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Video', 'SalesforceRecord'],
    }),
  }),
});

export const {
  useGetVideosQuery,
  useGetVideoQuery,
  useSearchVideosQuery,
  useUpdateSharingMutation,
  useCountVideosQuery,
  useRenameVideoMutation,
  useDeleteVideoMutation,
  useGetTranscriptsQuery,
  useVectorizeMutation,
  useAddTagMutation,
  useRemoveTagMutation,
  useGetTagsQuery,
  useAddCollectionMutation,
  useRemoveCollectionMutation,
  useAddSalesforceRecordMutation,
  useRemoveSalesforceRecordMutation,
} = videoApi;
