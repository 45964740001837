import MentionCard from 'components/mention/MentionCard';
import React from 'react';
import Avatar from 'ui-kit/avatar/Avatar';
import { MentionCardProps } from './MentionCard';

type MentionCardUserProps = Partial<MentionCardProps> & {
  user: {
    name: string;
    email?: string;
    pictureUrl?: string;
  };
};

const MentionCardUser: React.FC<MentionCardUserProps> = ({ user, ...props }) => {
  return (
    <MentionCard
      avatar={<Avatar src={user.pictureUrl} name={user.name} imgProps={{ referrerPolicy: 'no-referrer' }} />}
      avatarShape="circle"
      primary={user.name}
      secondary={user.email}
      {...props}
    />
  );
};

export default MentionCardUser;
