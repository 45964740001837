import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Menu } from '@mui/material';
import React, { useState } from 'react';
import { GridRowId } from '@mui/x-data-grid/models/gridRows';
import { useSelector } from 'react-redux';
import { appSelectors } from '../../../../redux/App';
import { useGetOrganizationQuery } from '../../../../redux/services/organization';
import { StyledFilterMenu } from '../filter.style';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import Divider from '@mui/material/Divider';
import { useGetTagsQuery } from '../../../../redux/services/video';
import { LoadingButton } from '@mui/lab';
import { useAddVideosToCollectionMutation } from '../../../../redux/services/collection';

interface VideoActionsProps {
  videoUuids: GridRowId[];
}

const VideoActions = ({ videoUuids }: VideoActionsProps) => {
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization } = useGetOrganizationQuery(currentOrganizationUuid);
  const { data: tags } = useGetTagsQuery();

  const [addVideosToCollectionMutation, { isLoading }] = useAddVideosToCollectionMutation();

  // Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setItemUuid(null);
    setMenuSelected(null);
    setAnchorEl(null);
  };

  const [menuSelected, setMenuSelected] = useState<'collection' | 'tag' | null>(null);
  const [itemUuid, setItemUuid] = useState<string | null>(null);

  // Menu Click
  const handleMenuClick = (name: 'collection' | 'tag') => {
    if (menuSelected === name) {
      setMenuSelected(null);
    } else {
      setMenuSelected(name);
    }
  };

  // Add Collection
  const handleAddCollection = (collectionUuid: string) => {
    setItemUuid(collectionUuid);
    addVideosToCollectionMutation({ uuid: collectionUuid, videoUuids }).finally(() => {
      handleClose();
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="info"
        disabled={videoUuids.length === 0}
        endIcon={<ExpandMoreIcon />}
        sx={{ height: '30px' }}
        onClick={handleClick}
      >
        Actions
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className="filter-menu"
        slotProps={{
          paper: {
            sx: {
              marginTop: '6px',
              filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.10))',
            },
          },
        }}
      >
        <StyledFilterMenu>
          <div className="menu-item menu-name">{`${videoUuids.length} recording${
            videoUuids.length > 1 ? 's' : ''
          } selected`}</div>
          <Box display="flex" flexDirection="row" gap={1}>
            <Box display="flex" flexDirection="column">
              <Button
                variant="text"
                startIcon={<VideoLibraryIcon />}
                endIcon={<KeyboardArrowRightIcon />}
                className={`menu-item-button ${menuSelected === 'collection' ? 'menu-item-button-active' : ''}`}
                onClick={() => handleMenuClick('collection')}
              >
                Add to Collection
              </Button>
              {/*<Button*/}
              {/*  variant="text"*/}
              {/*  startIcon={<TagIcon />}*/}
              {/*  endIcon={<KeyboardArrowRightIcon />}*/}
              {/*  className={`menu-item-button ${menuSelected === 'tag' ? 'menu-item-button-active' : ''}`}*/}
              {/*  onClick={() => handleMenuClick('tag')}*/}
              {/*>*/}
              {/*  <Box flexGrow={1}>Add to Tag</Box>*/}
              {/*</Button>*/}
            </Box>

            {menuSelected !== null && <Divider orientation="vertical" flexItem />}

            {menuSelected === 'collection' && (
              <Box display="flex" flexDirection="column" minWidth="180px">
                {currentOrganization &&
                  currentOrganization.collections.map((collection) => {
                    return (
                      <LoadingButton
                        variant="text"
                        className="menu-item-button"
                        startIcon={<VideoLibraryIcon />}
                        onClick={() => handleAddCollection(collection.uuid)}
                        loading={isLoading && collection.uuid === itemUuid}
                        loadingPosition="start"
                      >
                        {collection.name}
                      </LoadingButton>
                    );
                  })}
              </Box>
            )}
            {menuSelected === 'tag' && (
              <Box display="flex" flexDirection="column" minWidth="180px">
                {tags &&
                  tags.map((tag) => {
                    return (
                      <Button variant="text" className="menu-item-button">
                        {tag.label}
                      </Button>
                    );
                  })}
              </Box>
            )}
          </Box>
        </StyledFilterMenu>
      </Menu>
    </>
  );
};

export default VideoActions;
