import { Box, Button, Card, Typography } from '@mui/material';
import useMicrosoftAskCode from 'hooks/microsoft/authentication/useMicrosoftAskCode';
import React from 'react';
import MicrosoftOutlookCalendarLogo from 'ui-kit/logo/microsoft/microsoft-outlook-calendar.svg';

const OnboardingMicrosoftPermissionCard = () => {
  const { askMicrosoftCode } = useMicrosoftAskCode();

  return (
    <Card sx={{ width: '400px', margin: '0 20px' }}>
      <Box display="flex" flexDirection="column" p={2}>
        <Box width="100px" minHeight="75px">
          <img src={MicrosoftOutlookCalendarLogo} alt="Microsoft Outlook Agenda Logo" width="48px" height="48px" />
        </Box>
        <Box flexGrow={1}>
          <Typography variant={'h3'} gutterBottom>
            Please, authorize Microsoft Outlook Calendar
          </Typography>
          <Typography variant={'body1'} gutterBottom>
            Goelo does not have access to your meetings.
            <br />
            Please authorize Goelo to access your Microsoft Outlook Calendar.
          </Typography>
          <Typography variant={'body2'} gutterBottom>
            This permission is required to list your meetings.
          </Typography>
          <Box display="flex" justifyContent="space-between" width="100%" mt={3} style={{ justifyContent: 'end' }}>
            <Button color="primary" variant="contained" onClick={askMicrosoftCode}>
              Authorize Microsoft Outlook Calendar
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default OnboardingMicrosoftPermissionCard;
