import { Box, Grid } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import tokens from 'ui-kit/tokens';

function Header({ children }: PropsWithChildren<{}>) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        minHeight: '56px',
        borderBottom: `1px solid ${tokens.border.colors.transparent}`,
      }}
    >
      <Grid direction="row" container>
        <Grid item flexGrow={1}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Header;
