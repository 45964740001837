import { Delete } from '@mui/icons-material';
import { ListItem } from '@mui/material';
import Button from '@mui/material/Button';
import MentionCardInvitation from 'components/mention/MentionCardInvitation';
import React from 'react';
import { Invitation } from 'redux/services/api.types';
import { useRevokeInvitationMutation } from 'redux/services/invitation';
import { useConfirm } from 'ui-kit/confirm';

const InvitationItem = ({ invitation, divider }: { invitation: Invitation; divider: boolean }) => {
  const confirm = useConfirm();
  const [revokeInvitationMutation] = useRevokeInvitationMutation();
  const revokeInvitation = function (uuid: string) {
    confirm({
      title: 'Revoke Invite',
      content: `Are you sure you want to revoke this invite? (${invitation.email})`,
      confirmationText: 'Revoke',
      cancellationText: 'Cancel',
    })
      .then(() => {
        revokeInvitationMutation({ uuid });
      })
      .catch(() => {});
  };

  return (
    <ListItem
      key={invitation.uuid}
      secondaryAction={
        <>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            endIcon={<Delete />}
            onClick={() => {
              revokeInvitation(invitation.uuid);
            }}
          >
            Revoke Invite
          </Button>
        </>
      }
      divider={divider}
    >
      <MentionCardInvitation invitation={invitation} />
    </ListItem>
  );
};

export default InvitationItem;
