import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { Box, Button, Card, CardActionArea, CardActions } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import appSelectors from 'redux/App/app.selectors';
import { useCreateCollectionMutation } from 'redux/services/collection';
import tokens from 'ui-kit/tokens';

const CollectionCreateCard = () => {
  const navigate = useNavigate();
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const [createCollectionMutation, createCollectionResult] = useCreateCollectionMutation();
  const handleCreate = () => {
    if (createCollectionResult.isLoading) return;
    createCollectionMutation({ name: 'Collection to rename' });
  };
  useEffect(() => {
    if (createCollectionResult.isSuccess) {
      navigate(`/o/${currentOrganizationUuid}/c/${createCollectionResult.data.uuid}`);
    }
  });

  return (
    <Card
      sx={{ width: 320, height: 180, backgroundColor: tokens.colors.primary.light, borderRadius: '12px' }}
      onClick={handleCreate}
    >
      <CardActionArea sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: '90px',
            color: tokens.colors.neutral.dark,
            marginTop: '10px',
          }}
        >
          <AddToPhotosIcon sx={{ height: '80px', width: '80px' }} />
        </Box>
        <CardActions sx={{ flex: 1, width: '80%' }}>
          <Button startIcon={<AddToPhotosIcon />} fullWidth variant="contained" color="primary">
            New Collection
          </Button>
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

export default CollectionCreateCard;
