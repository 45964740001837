import { ActionReducerMapBuilder, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { appActions } from 'redux/App/index';
import actions from 'redux/Auth/auth.actions';
import { State } from './app.types';

export const initialState: State = {
  currentOrganizationUuid: null,
  videoImportModalOpened: false,
  currentVideoUuid: null,
  videoView: 'gallery',
};

const reducer = createReducer(initialState, (builder: ActionReducerMapBuilder<State>) =>
  builder
    .addCase(appActions.currentOrganizationChanged, setCurrentOrganizationUuid)
    .addCase(appActions.videoImportModalChanged, setVideoImportModalOpened)
    .addCase(appActions.currentVideoChanged, setCurrentVideoUuid)
    .addCase(appActions.videoViewChanged, setVideoView)
    .addCase(actions.logout, logout),
);

function setCurrentOrganizationUuid(state: State, action: PayloadAction<string>) {
  state.currentOrganizationUuid = action.payload;
}

function setVideoImportModalOpened(state: State, action: PayloadAction<boolean>) {
  state.videoImportModalOpened = action.payload;
}

function setCurrentVideoUuid(state: State, action: PayloadAction<string | null>) {
  state.currentVideoUuid = action.payload;
}

function setVideoView(state: State, action: PayloadAction<'gallery' | 'table'>) {
  state.videoView = action.payload;
}

function logout(state: State) {
  return initialState;
}

const persistConfig = {
  key: 'app',
  storage: storage,
  whiteList: ['currentOrganizationUuid', 'videoView'],
};

export default persistReducer(persistConfig, reducer);
