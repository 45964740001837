import useRedirectOrganization from 'hooks/routing/useRedirectOrganization';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { appSelectors } from 'redux/App';

const OrganizationRedirectionPage = () => {
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  useRedirectOrganization();
  if (currentOrganizationUuid) return <Navigate to={`/o/${currentOrganizationUuid}/v`} replace={true} />;
  return (
    <div>
      <h1>Organization Redirection Page</h1>
      <p>This is the organization redirection page. It is used to redirect the user to the organization page.</p>
    </div>
  );
};

export default OrganizationRedirectionPage;
