import api from './api';
import { Team } from './api.types';

export const teamApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTeams: builder.query<Team[], void>({
      query: () => 'teams',
      providesTags: ['Team'],
    }),
    createTeam: builder.mutation<Team, Partial<Team>>({
      query: (team) => ({
        url: 'teams',
        method: 'POST',
        body: team,
      }),
      invalidatesTags: ['Team'],
    }),
    updateTeam: builder.mutation<Team, Partial<Team>>({
      query: (team) => ({
        url: `teams/${team.uuid}`,
        method: 'PUT',
        body: team,
      }),
      invalidatesTags: ['Team'],
    }),
    deleteTeam: builder.mutation<void, string>({
      query: (uuid) => ({
        url: `teams/${uuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Team'],
    }),
  }),
});

export const {
  useGetTeamsQuery,
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
} = teamApi;