import { useSalesforceRecordSearchQuery } from 'redux/services/salesforce';

function useSalesforceRecordSearch(currentSearch: string) {
  const { data, isFetching } = useSalesforceRecordSearchQuery(
    {
      q: currentSearch,
      limit: 10,
    },
    {
      skip: currentSearch.length < 2,
    },
  );

  return {
    data,
    isFetching,
  };
}

export default useSalesforceRecordSearch;
