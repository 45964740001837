import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CommentIcon from '@mui/icons-material/Comment';
import { Box, Button, styled } from '@mui/material';
import SalesforceSection from 'components/salesforce/SalesforceSection';
import Timeline from 'components/video/Timeline';
import SeekBar from 'components/video/Timeline/SeekBar';
import { DisplayPeriod } from 'components/video/Timeline/Timeline.types';
import VideoPlayer from 'components/video/VideoPlayer';
import useHasSalesforceIdentity from 'hooks/salesforce/useHasSalesforceIdentity';
import TimelinesSection from 'pages/Video/components/TimelinesSection';
import TranscriptsSection from 'pages/Video/components/TranscriptsSection';
import VideoSettingsButton from 'pages/Video/components/VideoSettingsButton';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import appActions from 'redux/App/app.actions';
import { authActions } from 'redux/Auth';
import authSelectors from 'redux/Auth/auth.selectors';
import { useGetTranscriptsQuery, useGetVideoQuery } from 'redux/services/video';
import { useGetVideoCommentsQuery } from 'redux/services/video-comment';
import sidebarActions from 'redux/Sidebar/sidebar.actions';
import Header from 'ui-kit/header/Header';
import ContainerFluid from 'ui-kit/layout/ContainerFluid';
import tokens from 'ui-kit/tokens';
import SummariesSection from './components/SummariesSection';
import VideoCollectionButton from './components/VideoCollectionButton';
import VideoSharingButton from './components/VideoSharingButton';
import MentionCardVideoAndUser from '../../components/mention/MentionCardVideoAndUser';

const VideoPageStyled = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;

  .main {
    flex: 1;
    height: 100%;

    .video-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
`;

// @see https://github.com/bonjourio/demox/blob/master/manager/src/components/RecordingPlayer/RecordingPlayer.tsx#L3
function VideoPage() {
  // Generic
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Authentication
  const isAuthenticated = useSelector(authSelectors.isAuthenticated);
  const askGoogleCode = useCallback(() => {
    dispatch(authActions.googleCodeAsked());
  }, [dispatch]);

  // Video
  const { videoUuid } = useParams();
  const { data: video, isLoading: videoLoading, error: videoError } = useGetVideoQuery(videoUuid);
  useEffect(() => {
    if (videoError && 'status' in videoError && videoError.status === 401) {
      navigate('/login');
    }
  }, [navigate, videoError]);
  useEffect(() => {
    if (videoUuid) {
      dispatch(appActions.currentVideoChanged(videoUuid));
    }
    return () => {
      dispatch(appActions.currentVideoChanged(null));
    };
  }, [dispatch, videoUuid]);

  // Salesforce
  const hasSalesforceIdentity = useHasSalesforceIdentity();

  // Transcript
  const { data: transcripts } = useGetTranscriptsQuery(videoUuid);

  // Clips
  const [clipPeriods, setClipPeriods] = useState<DisplayPeriod[]>([]);
  const { data: comments } = useGetVideoCommentsQuery({ videoUuid }, { skip: !videoUuid });
  useEffect(() => {
    if (!comments) {
      setClipPeriods([]);
      return;
    }

    setClipPeriods(
      comments
        .filter((comment) => comment.offsetEnd)
        .map((comment) => ({
          start: comment.offset,
          end: comment.offsetEnd,
        })) as DisplayPeriod[],
    );
  }, [comments]);

  // Comment
  const handleCommentClick = useCallback(() => {
    if (!videoUuid) return;
    dispatch(sidebarActions.videoCommentSelected(videoUuid));
  }, [dispatch, videoUuid]);

  // Chat
  const handleChatClick = useCallback(() => {
    if (!videoUuid) return;
    dispatch(sidebarActions.videoChatSelected(videoUuid));
  }, [dispatch, videoUuid]);

  // Sidebar
  useEffect(() => {
    return () => {
      dispatch(sidebarActions.close());
    };
  }, [dispatch]);

  // Render
  return (
    <VideoPageStyled>
      <div className="main">
        <Header>
          <ContainerFluid>
            <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
              <Box flexGrow="1">{!videoLoading && video ? video.title : 'loading...'}</Box>
              <Box flexGrow="0" flexDirection="row" gap="10px" display="flex" alignSelf="start">
                {video && isAuthenticated && <VideoSharingButton video={video} />}
                {video && isAuthenticated && <VideoCollectionButton video={video} />}
                {video && isAuthenticated && <VideoSettingsButton video={video} />}
              </Box>
              {!isAuthenticated && (
                <Box display="flex" gap="20px">
                  <Button variant="contained" href={'https://www.goelo.com'}>
                    Try Goelo for free.
                  </Button>
                  <Button variant="outlined" onClick={askGoogleCode}>
                    Login
                  </Button>
                </Box>
              )}
            </Box>
          </ContainerFluid>
        </Header>
        <ContainerFluid className="video-content">
          {video && (
            <div>
              <VideoPlayer video={video} />
              <MentionCardVideoAndUser video={video} />
            </div>
          )}
          {isAuthenticated && video && (
            <Box display="flex" flexDirection="row" gap="10px">
              <Button variant="outlined" color="info" startIcon={<CommentIcon />} onClick={handleCommentClick}>
                {video._count.comments} Comment{video._count.comments > 1 ? 's' : ''}
              </Button>
              <Button variant="outlined" color="info" startIcon={<AutoAwesomeIcon />} onClick={handleChatClick}>
                Insights
              </Button>
            </Box>
          )}
          {isAuthenticated && video && hasSalesforceIdentity && <SalesforceSection video={video} />}
          <Box gap={0} position="relative">
            {video && video.duration && clipPeriods.length > 0 && (
              <Timeline
                label="Clips"
                duration={video.duration}
                periods={clipPeriods}
                minSizePeriod={2}
                periodColor={tokens.colors.primary.main}
              />
            )}
            {video && video.duration && transcripts && transcripts.length > 0 && (
              <TimelinesSection duration={video.duration / 1000} periods={transcripts[0].content as DisplayPeriod[]} />
            )}
            {video && video.duration && <SeekBar duration={video.duration / 1000} />}
          </Box>

          {video && video.summaries && <SummariesSection summaries={video.summaries} />}
          {transcripts && <TranscriptsSection transcripts={transcripts} />}
        </ContainerFluid>
      </div>
    </VideoPageStyled>
  );
}

export default VideoPage;
