import last from 'lodash/last';

import { Pixels, ComputedDisplayPeriod, Seconds, DisplayPeriod } from './Timeline.types';

function getPixelsForAPeriod(sizeTimeline: Pixels, totalDuration: Seconds, durationPeriod: Seconds): Pixels {
  return (sizeTimeline / totalDuration) * durationPeriod;
}

function clampPixels(pixels: Pixels, minSize: Pixels): Pixels {
  return pixels < minSize ? minSize : pixels;
}

function arePeriodCloseEnoughToBeMerged(
  previousPeriod: DisplayPeriod,
  currentPeriod: DisplayPeriod,
  maxSpaceBetweenPeriods: Pixels,
  timelineWidth: Pixels,
  duration: Seconds,
) {
  const intervalWithPreviousPeriod = getPixelsForAPeriod(
    timelineWidth,
    duration,
    currentPeriod.start - previousPeriod.end,
  );

  return intervalWithPreviousPeriod < maxSpaceBetweenPeriods;
}

export function computePeriods(
  periods: DisplayPeriod[],
  timelineWidth: Pixels,
  duration: Seconds,
  minSizePeriod: Pixels,
  maxSpaceBetweenPeriods: Pixels,
  shouldMergePeriods: (previous: DisplayPeriod, current: DisplayPeriod) => boolean,
): ComputedDisplayPeriod[] {
  const computedPeriods: ComputedDisplayPeriod[] = [];

  periods
    .filter((period: DisplayPeriod) => period.start <= duration && period.end <= duration)
    .forEach((period: DisplayPeriod) => {
      const previousPeriod: ComputedDisplayPeriod | undefined = last(computedPeriods);
      const width = getPixelsForAPeriod(timelineWidth, duration, period.end - period.start);
      const left = (period.start / duration) * 100;

      if (
        previousPeriod &&
        arePeriodCloseEnoughToBeMerged(previousPeriod, period, maxSpaceBetweenPeriods, timelineWidth, duration) &&
        shouldMergePeriods(previousPeriod, period)
      ) {
        previousPeriod.end = period.end;
        previousPeriod.width = clampPixels(
          getPixelsForAPeriod(timelineWidth, duration, previousPeriod.end - previousPeriod.start),
          minSizePeriod,
        );
      } else {
        computedPeriods.push({
          ...period,
          width: clampPixels(width, minSizePeriod),
          left: left,
        });
      }
    });

  return computedPeriods;
}
