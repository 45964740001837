import OnboardingGooglePermissionCard from 'pages/Onboarding/components/OnboardingGooglePermissionCard';
import OnboardingMicrosoftPermissionCard from 'pages/Onboarding/components/OnboardingMicrosoftPermissionCard';
import { useEffect, useState } from 'react';
import { useGetCurrentUserQuery } from 'redux/services/user';

const OnboardingPermissionCard = () => {
  const [provider, setProvider] = useState<'GOOGLE' | 'MICROSOFT' | null>(null);
  const { data: currentUser, isSuccess: currentUserSuccess } = useGetCurrentUserQuery();

  useEffect(() => {
    if (currentUserSuccess) {
      currentUser.identities.forEach((identity) => {
        if (identity.provider === 'GOOGLE') {
          setProvider('GOOGLE');
        }
        if (identity.provider === 'MICROSOFT') {
          setProvider('MICROSOFT');
        }
      });
    }
  }, [currentUser, currentUserSuccess]);

  if (provider === null) return null;

  return (
    <>
      {provider === 'GOOGLE' && <OnboardingGooglePermissionCard />}
      {provider === 'MICROSOFT' && <OnboardingMicrosoftPermissionCard />}
    </>
  );
};

export default OnboardingPermissionCard;
