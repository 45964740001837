import { Invitation, InvitationCreation, InvitationRevocation } from 'redux/services/api.types';
import api from 'redux/services/api';

const invitationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getInvitations: builder.query<Invitation[], { status: 'PENDING' }>({
      query: (args) => {
        return {
          url: '/invitations',
          params: args,
        };
      },
      providesTags: ['Invitation'],
    }),
    sendInvitation: builder.mutation<Invitation, InvitationCreation>({
      query(body) {
        return {
          url: '/invitations',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Invitation'],
    }),
    revokeInvitation: builder.mutation<void, InvitationRevocation>({
      query(body) {
        return {
          url: `/invitations/${body.uuid}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Invitation'],
    }),
  }),
});

export const { useGetInvitationsQuery, useSendInvitationMutation, useRevokeInvitationMutation } = invitationApi;
