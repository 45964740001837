import { Box, Button, Card, Typography } from '@mui/material';
import useGoogleAskCode from 'hooks/google/authentication/useGoogleAskCode';
import React from 'react';
import GoogleAgendaLogo from 'ui-kit/logo/google/google-agenda-logo.svg';

const OnboardingGooglePermissionCard = () => {
  const { askGoogleCode } = useGoogleAskCode({ state: { redirectionUri: '/onboarding/calendar' } });

  return (
    <Card sx={{ width: '400px', margin: '0 20px' }}>
      <Box display="flex" flexDirection="column" p={2}>
        <Box width="100px" minHeight="75px">
          <img src={GoogleAgendaLogo} alt="Google Drive Logo" width="48px" height="48px" />
        </Box>
        <Box flexGrow={1}>
          <Typography variant={'h3'} gutterBottom>
            Please, authorize Google calendar
          </Typography>
          <Typography variant={'body1'} gutterBottom>
            Goelo does not have access to your meetings.
            <br />
            Please authorize Goelo to access your Google Calendar.
          </Typography>
          <Typography variant={'body2'} gutterBottom>
            This permission is required to list your meetings.
          </Typography>
          <Box display="flex" justifyContent="space-between" width="100%" mt={3} style={{ justifyContent: 'end' }}>
            <Button color="primary" variant="contained" onClick={askGoogleCode}>
              Authorize Google Calendar
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default OnboardingGooglePermissionCard;
