import { ChatboxColors, Crisp } from 'crisp-sdk-web';
import { select, takeEvery } from 'redux-saga/effects';
import { authActions, authSelectors } from 'redux/Auth';

if (process.env.NODE_ENV === 'production') {
  Crisp.configure('90f00610-73b2-44e7-8219-8aa4560e78d3');
  Crisp.setColorTheme(ChatboxColors.Black);
}

const rootSaga = [takeEvery([authActions.loginSuccess, authActions.refreshTokenSuccess], onLogin)];

function* onLogin() {
  const displayName: string = yield select(authSelectors.getUsername);
  const email: string = yield select(authSelectors.getEmail);

  Crisp.user.setEmail(email);
  Crisp.user.setNickname(displayName);
}

export default rootSaga;
