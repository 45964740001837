import { Box } from '@mui/material';
import CollectionCard from 'components/video/CollectionCard/CollectionCard';
import CollectionCreateCard from 'components/video/CollectionCreateCard/CollectionCreateCard';
import React from 'react';
import { useGetCollectionsQuery } from 'redux/services/collection';
import Header from 'ui-kit/header/Header';
import ContainerFluid from 'ui-kit/layout/ContainerFluid';

function CollectionsPage() {
  const { data: collections } = useGetCollectionsQuery();
  return (
    <div>
      <Header>
        <ContainerFluid>Collections</ContainerFluid>
      </Header>
      <ContainerFluid>
        <Box display="flex" justifyContent="left" flexWrap="wrap" gap="10px">
          {collections &&
            collections.map((collection) => {
              return <CollectionCard collection={collection} />;
            })}
          <CollectionCreateCard />
        </Box>
      </ContainerFluid>
    </div>
  );
}

export default CollectionsPage;
