import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Container, IconButton, InputBase, Modal, Paper, styled, ThemeProvider } from '@mui/material';
import { useCompletion } from 'ai/react';
import { apiConfig } from 'config';
import VideoLink from 'layouts/organization/components/videoLink';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import appSelectors from 'redux/App/app.selectors';
import { authSelectors } from 'redux/Auth';
import { getDarkTheme } from 'ui-kit/theme';

function removeVideoTags(inputText: string) {
  const videos: { uuid: string; time: number | null }[] = [];
  const pattern = /\[video:([a-f0-9-]+)(?:\|time:(\d+))?\]/g; // Example: [video:c48c5856-fbcd-4977-b977-2f1945271703|t:1404]
  const text = inputText.replace(pattern, (match, uuid, time = null) => {
    videos.push({ uuid, time });
    return '';
  });
  return { text, videos };
}

const AskInputStyled = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 10px 8px 0 8px;

  .search-input {
    color: #9e9e9e;
    padding-left: 5px;
    flex: 1;
    cursor: text;
  }
`;

const AskInput = () => {
  const [open, setOpen] = React.useState(false);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AskInputStyled>
        <Paper
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', zIndex: 10 }}
          onClick={handleClick}
        >
          <IconButton size="small" aria-label="Awesile Icon">
            <AutoAwesomeIcon />
          </IconButton>
          <div className="search-input">Ask a question</div>
        </Paper>
      </AskInputStyled>
      <ThemeProvider theme={getDarkTheme}>
        <AskDialog open={open} onClose={handleClose} />
      </ThemeProvider>
    </>
  );
};

const AskModalStyled = styled(Modal)`
  backdrop-filter: blur(2px);

  .response {
    margin-top: 8px;
    padding: 24px;
    overflow: hidden;

    .content {
      white-space: pre-wrap;
    }
  }

  .videos {
    display: flex;
    flex-direction: row;
    gap: 10px;

    height: 0;
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.8s ease, opacity 0.8s ease;

    &.videos-displayed {
      padding-top: 24px;
      height: auto;
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

type AskDialogProps = {
  open: boolean;
  onClose: () => void;
};

const AskDialog = ({ open, onClose }: AskDialogProps) => {
  const accessToken = useSelector(authSelectors.getAccessToken);
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);

  const { completion, input, isLoading, handleInputChange, handleSubmit, setInput } = useCompletion({
    api: `${apiConfig.entrypoint}/ai/ask`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'x-app-organization-uuid': currentOrganizationUuid || '',
    },
    onFinish: () => {
      setDisplayVideos(true);
    },
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisplayVideos(false);
    handleSubmit(e);
  };

  const [response, setResponse] = React.useState<string | null>(null);
  const [videos, setVideos] = React.useState<{ uuid: string; time: number | null }[] | null>(null);
  const [displayVideos, setDisplayVideos] = React.useState<boolean>(false);

  useEffect(() => {
    if (completion) {
      const formattedCompletion = removeVideoTags(completion);
      setResponse(formattedCompletion.text);
      setVideos(formattedCompletion.videos);
    } else {
      setResponse(null);
      setVideos(null);
    }
  }, [completion]);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const examples = ['Who am I?', `How should I respond to this "It's too expensive" objection?`];
  const typeExample = (example: string) => {
    setInput(example);
    inputRef.current?.focus();
  };

  return (
    <AskModalStyled open={open} onClose={onClose}>
      <Container maxWidth="md" sx={{ marginTop: '32px', outline: 'none' }}>
        <Paper className="search" component="form" onSubmit={onSubmit}>
          <Box display="flex" alignItems="center" width="100%" padding="10px 10px 0 10px">
            <IconButton sx={{ p: '10px' }} aria-label="Awesome Icon" disabled>
              <AutoAwesomeIcon />
            </IconButton>
            <InputBase
              inputRef={inputRef}
              sx={{ flex: 1 }}
              placeholder={isLoading ? 'Generating' : 'Ask a question'}
              name="question"
              id="question"
              autoComplete="off"
              inputProps={{ 'aria-label': 'Ask a question' }}
              value={input}
              onChange={handleInputChange}
              disabled={isLoading}
              autoFocus={true}
            />
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="Send" disabled={isLoading}>
              <SendIcon />
            </IconButton>
          </Box>
          <Box display="flex" gap="8px" padding="10px">
            {examples.map((example, index) => (
              <Button
                key={`button-example-{index}`}
                size="small"
                variant="outlined"
                color="info"
                onClick={() => {
                  typeExample(example);
                }}
              >
                {example}
              </Button>
            ))}
          </Box>
        </Paper>
        {isLoading && !response && (
          <Paper className="response">
            <div className="content">Analysing conversations...</div>
          </Paper>
        )}
        {response && (
          <Paper className="response">
            <div className="content">{response}</div>
            <div className={`videos ${displayVideos && videos && videos.length > 0 ? 'videos-displayed' : ''}`}>
              {videos &&
                videos.map((video) => (
                  <div onClick={onClose}>
                    <VideoLink key={video.uuid} uuid={video.uuid} time={video.time} />
                  </div>
                ))}
            </div>
          </Paper>
        )}
      </Container>
    </AskModalStyled>
  );
};

export default AskInput;
