import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Menu } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import { useSelector } from 'react-redux';
import { appSelectors } from '../../../../redux/App';
import { useGetOrganizationQuery } from '../../../../redux/services/organization';
import { StyledFilterMenu } from '../filter.style';
import { useFormik } from 'formik';
import AddIcon from '@mui/icons-material/Add';

interface OrganizerFilterProps {
  onSubmit: (organizerUuids: string[]) => void;
  initialValues: string[];
}

function OrganizerFilter({ initialValues, onSubmit }: OrganizerFilterProps) {
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization } = useGetOrganizationQuery(currentOrganizationUuid);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    formik.resetForm();
    setAnchorEl(null);
  };

  // Form
  const formik = useFormik({
    initialValues: { organizers: initialValues },
    onSubmit: (values) => {
      onSubmit(values.organizers);
      setAnchorEl(null);
    },
  });

  // Display the first organizer fullname and x more
  const hasSelectedOrganizers = formik.values.organizers.length > 0;
  const firstOrganizerName =
    hasSelectedOrganizers && currentOrganization
      ? currentOrganization.members.find((member) => formik.values.organizers.includes(member.user.uuid))?.user.name
      : '';
  const additionalOrganizersCount = hasSelectedOrganizers ? formik.values.organizers.length - 1 : 0;
  const buttonLabel = firstOrganizerName
    ? `${firstOrganizerName}${additionalOrganizersCount > 0 ? ` and ${additionalOrganizersCount} more` : ''}`
    : '';

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        color="info"
        startIcon={
          formik.values.organizers.length > 0 ? (
            <ClearIcon
              sx={{ ':hover': { color: 'error.main' } }}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                onSubmit([]);
              }}
            />
          ) : (
            <AddIcon />
          )
        }
        endIcon={<ExpandMoreIcon />}
        onClick={handleClick}
        sx={{ height: '30px' }}
      >
        <Box display="flex" flexDirection="row" gap="6px">
          <div>Organizer</div>
          {formik.values.organizers.length > 0 && (
            <Divider orientation="vertical" variant="middle" flexItem sx={{ marginY: '3px' }} />
          )}
          {buttonLabel && <Box sx={{ color: 'primary.main' }}>{buttonLabel}</Box>}
        </Box>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className="filter-menu"
        slotProps={{
          paper: {
            sx: {
              marginTop: '6px',
              filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.10))',
            },
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <StyledFilterMenu>
            <div className="menu-item menu-name">Filter by Organizer</div>
            <FormGroup className="menu-form-group">
              {currentOrganization &&
                currentOrganization.members.map((member) => (
                  <FormControlLabel
                    key={member.uuid}
                    control={
                      <Checkbox
                        className="menu-item-checkbox"
                        size="small"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.organizers.includes(member.user.uuid)}
                        name="organizers"
                        value={member.user.uuid}
                      />
                    }
                    label={member.user.name}
                    className="menu-form-control"
                  />
                ))}
            </FormGroup>
            <div className="menu-item">
              <Button type="submit" className="menu-button" size="small" fullWidth variant="contained" color="primary">
                Apply
              </Button>
            </div>
            {formik.values.organizers.length > 0 && (
              <div className="menu-item">
                <Button
                  className="menu-button"
                  size="small"
                  fullWidth
                  variant="outlined"
                  color="info"
                  onClick={() => {
                    onSubmit([]);
                  }}
                >
                  Reset
                </Button>
              </div>
            )}
          </StyledFilterMenu>
        </form>
      </Menu>
    </>
  );
}

export default OrganizerFilter;
