import { LoadingButton } from '@mui/lab';
import { Box, Card, CardActions, CardContent, CardHeader, LinearProgress, styled } from '@mui/material';
import useProviderPermissionState from 'hooks/authorization/useProviderPermissionState';
import SynchronizingContainer from 'pages/Calendar/components/SynchronizingContainer';
import OnboardingPermissionCard from 'pages/Onboarding/components/OnboardingPermissionCard';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCalendarIntegrationQuery, useCountEventsQuery } from 'redux/services/calendar';
import { useGetOrganizationsQuery } from 'redux/services/organization';
import { useDispatch, useSelector } from 'react-redux';
import { appActions, appSelectors } from '../../redux/App';

const OnboardingCalendarPageStyled = styled('div')`
  .big-counter {
    font-size: 50px;
    text-align: center;
  }
`;

const OnboardingCalendarPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { calendarReadPermission } = useProviderPermissionState();
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: organizations } = useGetOrganizationsQuery();
  const { data: countEvents } = useCountEventsQuery();
  const { data: calendarIntegrationQuery } = useCalendarIntegrationQuery();

  useEffect(() => {
    if (organizations && organizations.length > 1) {
      navigate('/login/redirect');
    }
  }, [navigate, organizations]);

  /**
   * Set the first organization as the current organization if no organization is selected
   * Can happen if the user is redirected from the Google Calendar Authorization page
   */
  useEffect(() => {
    if (organizations && organizations.length > 0 && !currentOrganizationUuid) {
      console.log('[onboarding] Select the first organization as current organization. organizations[0].uuid:', organizations[0].uuid); // Debug
      dispatch(appActions.currentOrganizationChanged(organizations[0].uuid)); // Redux
    }
  }, [currentOrganizationUuid, dispatch, organizations]);

  const synchronized =
    calendarIntegrationQuery && calendarIntegrationQuery.active && !calendarIntegrationQuery.synchronizing;
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" gap={1} sx={{ width: '400px', margin: '5px 20px' }}>
        <LinearProgress variant="determinate" value={100} sx={{ flex: '1' }} />
        <LinearProgress variant="determinate" value={100} sx={{ flex: '1' }} />
        <LinearProgress variant="determinate" value={100} color="info" sx={{ flex: '1', opacity: '50%' }} />
      </Box>
      {!calendarReadPermission && <OnboardingPermissionCard />}
      {calendarReadPermission && (
        <OnboardingCalendarPageStyled>
          <Card sx={{ width: '400px', margin: '0 20px' }}>
            <CardHeader
              title="Synchronizing your Calendar"
              subheader="Your calendar events are currently being synchronized with Goelo."
            />
            <CardContent>
              <SynchronizingContainer />
              <div className="big-counter">
                <Counter value={countEvents} /> events
              </div>
            </CardContent>
            <CardActions style={{ justifyContent: 'end' }}>
              <LoadingButton
                loading={!synchronized}
                variant="contained"
                color="primary"
                onClick={() => navigate('/onboarding/record')}
              >
                Next
              </LoadingButton>
            </CardActions>
          </Card>
        </OnboardingCalendarPageStyled>
      )}
    </Box>
  );
};

export default OnboardingCalendarPage;

const Counter = ({ value = 0 }: { value: number | undefined }) => {
  const count = useRef(0);
  const [counter, setCounter] = useState(count.current);
  useEffect(() => {
    const difference = value - count.current;
    const increment = difference / 100;
    const interval = setInterval(() => {
      if (count.current >= value) {
        clearInterval(interval);
      } else {
        count.current += increment;
        setCounter(count.current);
      }
    }, 20);

    return () => {
      clearInterval(interval);
    };
  }, [value]);

  return <span>{Math.floor(counter)}</span>;
};
