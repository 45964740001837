import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from 'redux/Auth';

const useMicrosoftAskCode = () => {
  const dispatch = useDispatch();

  const askMicrosoftCode = useCallback(() => {
    dispatch(authActions.logout());
    dispatch(authActions.microsoftCodeAsked());
  }, [dispatch]);

  return { askMicrosoftCode };
};

export default useMicrosoftAskCode;
