import styled from '@emotion/styled';
import { Chip } from '@mui/material';
import Typography from '@mui/material/Typography';
import Avatar from 'ui-kit/avatar/Avatar';

type UserLineStyleProps = {
  variant?: 'outlined';
};

type UserLineProps = UserLineStyleProps & {
  user: {
    email: string;
    name?: string;
    pictureUrl?: string;
  };
  chip?: string;
};

const UserLineStyled = styled('div')<UserLineStyleProps>`
  display: flex;
  flex-direction: row;
  height: 60px;
  border: ${(props) => (props.variant === 'outlined' ? `1px solid #e0e0e0` : 'none')};

  .user-line-avatar-zone {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .user-line-info-zone {
    flex: 1;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

function UserLine({ user, chip, variant }: UserLineProps) {
  return (
    <UserLineStyled variant={variant}>
      <div className="user-line-avatar-zone">
        <Avatar src={user.pictureUrl} name={user.name || user.email} />
      </div>
      <div className="user-line-info-zone">
        <Typography variant="body1">
          {user.name || user.email} {chip && <Chip label={chip} size="small" />}
        </Typography>
        {user.name && (
          <Typography variant="body2" color="text.secondary" display="block">
            {user.email}
          </Typography>
        )}
      </div>
    </UserLineStyled>
  );
}

export default UserLine;
