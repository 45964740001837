import BusinessIcon from '@mui/icons-material/Business';
import MentionCard from 'components/mention/MentionCard';
import dayjs from 'dayjs';
import useDelayDisplay from 'hooks/display/useDelayDisplay';
import React from 'react';
import { Organization } from 'redux/services/api.types';
import Avatar from 'ui-kit/avatar/Avatar';
import { MentionCardProps } from './MentionCard';

type MentionCardOrganizationProps = Partial<MentionCardProps> & {
  organization?: Organization;
};

const MentionCardVideo: React.FC<MentionCardOrganizationProps> = ({ organization, secondary, ...props }) => {
  const { display: displayLoading } = useDelayDisplay();

  if (organization === undefined) {
    return <MentionCard avatar={<BusinessIcon />} primary={displayLoading ? 'loading...' : ''} {...props} />;
  }

  if (secondary === undefined) {
    secondary = dayjs(organization.createdAt).format('[Created on] MMMM D, YYYY h:mm A');
  }

  return (
    <MentionCard
      avatar={<Avatar name={organization.name} variant="square" />}
      primary={organization.name}
      secondary={secondary}
      {...props}
    />
  );
};

export default MentionCardVideo;
