const TOTAL_SECONDS_DAY = 86400;

function secondsToTime(seconds: number): string {
  if (seconds < 0 || seconds > TOTAL_SECONDS_DAY) {
    throw new Error(`Invalid params, valid seconds range is [0 ; ${TOTAL_SECONDS_DAY}]`);
  }

  const hour = Math.floor(seconds / 3600);
  const minute = Math.floor((seconds - hour * 3600) / 60);
  const second = Math.floor(seconds - hour * 3600 - minute * 60);

  if (seconds < 3600) {
    return `${minute < 10 ? '0' : ''}${minute}:${second < 10 ? '0' : ''}${second}`;
  } else {
    return `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}:${second < 10 ? '0' : ''}${second}`;
  }
}

export const timeUtils = {
  secondsToTime,
};
