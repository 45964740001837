import { Select as MuiSelect } from '@mui/material';
import { SelectProps } from '@mui/material/Select/Select';
import React from 'react';
import Input from 'ui-kit/form/Input/Input';

const Select = (props: SelectProps, ref: React.Ref<any>) => {
  return (
    <MuiSelect input={<Input />} ref={ref} {...props}>
      {props.children}
    </MuiSelect>
  );
};

export default React.forwardRef(Select);
