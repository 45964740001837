import api from 'redux/services/api';
import {
  AutoRecordSettingEdition,
  Invitation,
  InvitationAcceptance,
  User,
  UserSetting,
} from 'redux/services/api.types';

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<User, void>({
      query: () => {
        return {
          url: '/users/current',
        };
      },
      providesTags: ['User'],
    }),
    searchUsers: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/users/search',
          params: arg,
        };
      },
      providesTags: ['User'],
    }),
    getUserInvitations: builder.query<Invitation[], { status: 'PENDING' } | void>({
      query: (filters) => {
        const params = filters ? '?status=PENDING' : '';
        return {
          url: '/users/invitations' + params,
        };
      },
      providesTags: ['Invitation'],
    }),
    acceptOrDeclineInvitation: builder.mutation<void, InvitationAcceptance>({
      query(body) {
        return {
          url: `/users/invitations/${body.uuid}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Invitation', 'Organization'],
    }),
    getCurrentUserSetting: builder.query<UserSetting, void>({
      query: () => {
        return {
          url: '/users/current-setting',
        };
      },
      providesTags: ['UserSetting'],
    }),
    updateCurrentUserSettingAutoRecord: builder.mutation<UserSetting, AutoRecordSettingEdition>({
      query(body) {
        return {
          url: '/users/current-setting-auto-record',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['UserSetting', 'CalendarEvent'],
    }),
    salesforceConnectWithCode: builder.mutation<User, { code: string }>({
      query(body) {
        return {
          url: '/users/integrations/salesforce',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['User', 'Identity'],
    }),
    salesforceDisconnect: builder.mutation<User, void>({
      query() {
        return {
          url: '/users/integrations/salesforce',
          method: 'DELETE',
        };
      },
      invalidatesTags: ['User', 'Identity'],
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useSearchUsersQuery,
  useGetUserInvitationsQuery,
  useAcceptOrDeclineInvitationMutation,
  useGetCurrentUserSettingQuery,
  useUpdateCurrentUserSettingAutoRecordMutation,
  useSalesforceConnectWithCodeMutation,
  useSalesforceDisconnectMutation,
} = userApi;
