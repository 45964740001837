import Box from '@mui/material/Box';
import useLoggedOrRedirect from 'hooks/routing/useLoggedOrRedirect';
import React from 'react';
import { Outlet } from 'react-router-dom';
import tokens from 'ui-kit/tokens';
import logo from './assets/logo-goelo.png';
import background from './assets/jason-leung-unsplash.jpg';

export default function OnboardingLayout() {
  useLoggedOrRedirect();

  return (
    <Box display="flex" flexDirection="row" height="100vh">
      <Box display="flex" flexDirection="column" flex="1" sx={{ backgroundColor: tokens.colors.beige.main }}>
        <Box sx={{ margin: '20px' }} display="flex" flexDirection="row">
          <img src={logo} alt="logo" width="30px" height="30px" />
          <Box
            sx={{
              alignSelf: 'center',
              paddingLeft: '10px',
              fontSize: '26px',
              fontWeight: '700',
              lineHeight: '26px',
            }}
          >
            Goelo
          </Box>
        </Box>
        <Box flex="1" display="flex" alignItems="center" justifyContent="center">
          <Outlet />
        </Box>
      </Box>
      <Box
        flex="1"
        sx={{
          maxWidth: '50%',
          backgroundColor: 'white',
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></Box>
    </Box>
  );
}
