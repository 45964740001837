import { Business, CreditCard, Extension, Group, Notes } from '@mui/icons-material';
import { Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ContainerFluid from 'ui-kit/layout/ContainerFluid';

function SettingsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState('');

  useEffect(() => {
    // Set the initial value based on the current path
    const path = location.pathname.split('/').pop() || '';
    setValue(path);
  }, [location]);

  function handleChange(event: React.ChangeEvent<{}>, newValue: string): void {
    setValue(newValue);
    navigate(newValue);
  }

  return (
    <>
      <ContainerFluid sx={{ paddingTop: '20px' }}>
        <Typography gutterBottom variant="h1">
          Settings
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Settings tabs"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab icon={<Business />} iconPosition="start" label="Organization & Members" value="" />
          <Tab icon={<Group />} iconPosition="start" label="Teams" value="teams" />
          <Tab icon={<Extension />} iconPosition="start" label="Integrations" value="integrations" />
          <Tab icon={<Notes />} iconPosition="start" label="AI Summary" value="summary" />
          <Tab icon={<CreditCard />} iconPosition="start" label="Plan" value="plan" />
        </Tabs>
      </ContainerFluid>
      <Outlet />
    </>
  );
}

export default SettingsPage;
