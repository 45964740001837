import { useEffect, useState } from 'react';
import { useGetCurrentUserQuery } from 'redux/services/user';

const useHasSalesforceIdentity = () => {
  const { data: currentUser, isSuccess: currentUserSuccess } = useGetCurrentUserQuery();
  const [hasSalesforceIdentity, setHasSalesforceIdentity] = useState<boolean>(false);
  useEffect(() => {
    if (currentUserSuccess) {
      const permission = currentUser.identities.some((identity) => identity.provider === 'SALESFORCE');
      setHasSalesforceIdentity(permission);
    }
  }, [currentUser, currentUserSuccess]);

  return hasSalesforceIdentity;
};

export default useHasSalesforceIdentity;
