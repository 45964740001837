export const googleConfig = {
  clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
};

export const microsoftConfig = {
  clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID || '',
};

export const salesforceConfig = {
  clientId: process.env.REACT_APP_SALESFORCE_CLIENT_ID || '',
  orgUrl: process.env.REACT_APP_SALESFORCE_ORG_URL || '',
};

export const apiConfig = {
  entrypoint: process.env.REACT_APP_API_ENTRYPOINT || '',
};

export const BASE_URL = document.location.origin;

export const fullstoryConfig = {
  orgId: 'RVYTN',
};
