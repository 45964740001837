import { call, select, takeEvery } from 'redux-saga/effects';

import { authActions, authSelectors } from 'redux/Auth';
import { FullStory } from '@fullstory/browser';

const rootSaga = [takeEvery([authActions.loginSuccess, authActions.refreshTokenSuccess], onLogin)];

function* onLogin() {
  const uuid: string = yield select(authSelectors.getUserUuid);
  const displayName: string = yield select(authSelectors.getUsername);
  const email: string = yield select(authSelectors.getEmail);

  yield call(FullStory, 'setIdentity', {
    uid: uuid,
    properties: { displayName, email },
  });
}

export default rootSaga;
