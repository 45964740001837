import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appSelectors } from 'redux/App';
import { useDeleteVideoMutation, useRenameVideoMutation } from 'redux/services/video';
import { Video } from 'redux/services/video.types';

// write VideoSettingsButtonProps type
type VideoSettingsButtonProps = {
  video?: Video;
};

const VideoSettingsButton = ({ video }: VideoSettingsButtonProps) => {
  // Global
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const navigate = useNavigate();

  // Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Rename
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const handleRenameDialogOpen = () => {
    setNewVideoName(video?.title ?? null);
    handleMenuClose();
    setRenameDialogOpen(true);
  };
  const handleRenameDialogClose = () => {
    setRenameDialogOpen(false);
  };
  const [newVideoName, setNewVideoName] = useState<string | null>(null);
  const handleNewVideoNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewVideoName(event.target.value || null);
  };
  const [renameVideo] = useRenameVideoMutation();
  const handleRenameVideo = () => {
    if (video && video.uuid && newVideoName) renameVideo({ uuid: video.uuid, title: newVideoName });
    handleRenameDialogClose();
  };

  // Delete
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = () => {
    handleMenuClose();
    setDeleteDialogOpen(true);
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  const [deleteVideo] = useDeleteVideoMutation();

  const handleDeleteVideo = () => {
    if (video && video.uuid) {
      deleteVideo(video.uuid);
      navigate(`/o/${currentOrganizationUuid}/v`);
    }
  };

  if (!video) return null;
  return (
    <>
      <IconButton size="small" color="info" aria-label="Edit name" onClick={handleMenuIconClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="video-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{ minWidth: '200px' }}
        PaperProps={{
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 11,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem onClick={handleRenameDialogOpen}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteDialogOpen}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog open={renameDialogOpen} onClose={handleRenameDialogClose}>
        <DialogTitle sx={{ minWidth: '400px' }}>Rename Video as...</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            onChange={handleNewVideoNameChange}
            value={newVideoName}
          />
        </DialogContent>
        <DialogActions>
          <Button color="info" onClick={handleRenameDialogClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleRenameVideo}>
            Rename
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle sx={{ minWidth: '400px' }}>Delete Video?</DialogTitle>
        <DialogContent>
          <p>
            This video will no longer be available in Goelo.
            <br />
            You can re-import the video from Google Drive later if necessary.
          </p>
        </DialogContent>
        <DialogActions>
          <Button color="info" onClick={handleDeleteDialogClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleDeleteVideo}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VideoSettingsButton;
