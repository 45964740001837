import { styled } from '@mui/material';
import Slider from '@mui/material/Slider';
import { Seconds } from 'components/video/Timeline/Timeline.types';
import { SyntheticEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { playerActions } from 'redux/Player';
import { getProgress } from 'redux/Player/player.selectors';
import { timeUtils } from 'services/time/timeUtils';

export interface Props {
  duration: Seconds;
}

const valueLabelFormat = (value: number) => {
  return timeUtils.secondsToTime(value);
};

const SeekBarStyled = styled('div')`
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: -10px;
  .MuiSlider-root {
    height: 100%;
    padding: 0;
  }
  .MuiSlider-rail {
    display: none;
  }
  .MuiSlider-thumb {
    color: black;
    opacity: 0.2;
    border-radius: 0;
    width: 3px;
    height: 100%;

    &:focus,
    &:hover,
    &.Mui-focusVisible {
      box-shadow: none;
    }

    &:hover {
      opacity: 1;
    }
    &:before {
      display: none;
    }
  }
`;

const SeekBar = ({ duration }: Props) => {
  const dispatch = useDispatch();
  const playerProgress = useSelector(getProgress);

  const handleSeekChangeCommitted = useCallback(
    (event: SyntheticEvent | Event, value: number | number[]) => {
      if (typeof value !== 'number') return;
      dispatch(playerActions.seekTo(value));
    },
    [dispatch],
  );

  return (
    <SeekBarStyled>
      <Slider
        max={duration}
        color="primary"
        size="small"
        step={1}
        value={playerProgress}
        aria-label="Small"
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
        onChangeCommitted={handleSeekChangeCommitted}
        track={false}
      />
    </SeekBarStyled>
  );
};

export default SeekBar;
