import api from 'redux/services/api';
import { VideoComment, VideoCommentCreation, VideoCommentUpdate } from './api.types';

const videoCommentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getVideoComments: builder.query<VideoComment[], { videoUuid: string | undefined }>({
      query: (filters) => ({
        url: '/video-comments',
        params: filters,
      }),
      providesTags: ['VideoComment'],
    }),
    createVideoComment: builder.mutation<VideoComment, VideoCommentCreation>({
      query(body) {
        return {
          url: '/video-comments',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['VideoComment'],
    }),
    updateVideoComment: builder.mutation<VideoComment, VideoCommentUpdate>({
      query({ uuid, content }) {
        return {
          url: `/video-comments/${uuid}`,
          method: 'PUT',
          body: { content },
        };
      },
      invalidatesTags: ['VideoComment'],
    }),
    deleteVideoComment: builder.mutation<VideoComment, { uuid: string }>({
      query({ uuid }) {
        return {
          url: `/video-comments/${uuid}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['VideoComment'],
    }),
    addCommentCollection: builder.mutation<VideoComment, { uuid: string; collectionUuid: string }>({
      query: ({ uuid, collectionUuid }) => ({
        url: `/video-comments/${uuid}/collections`,
        method: 'POST',
        body: { collectionUuid },
      }),
      invalidatesTags: ['VideoComment', 'Collection'],
    }),
    removeCommentCollection: builder.mutation<VideoComment, { uuid: string; collectionUuid: string }>({
      query: ({ uuid, collectionUuid }) => ({
        url: `/video-comments/${uuid}/collections`,
        method: 'DELETE',
        body: { collectionUuid },
      }),
      invalidatesTags: ['VideoComment', 'Collection'],
    }),
  }),
});

export const {
  useGetVideoCommentsQuery,
  useCreateVideoCommentMutation,
  useUpdateVideoCommentMutation,
  useDeleteVideoCommentMutation,
  useAddCommentCollectionMutation,
  useRemoveCommentCollectionMutation,
} = videoCommentApi;
