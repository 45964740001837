import { createAction } from '@reduxjs/toolkit';

import { withOptionalPayloadType, withPayloadType } from '../rootTypes';

import { AuthParam, LoginFailureParam, LoginParam, State, Tokens } from 'redux/Auth/auth.types';

// source: https://github.com/bonjourio/demox/blob/staging/packages/common/src/redux/NewAuth/auth.actions.ts
const actions = {
  googleCodeAsked: createAction('auth/GOOGLE_CODE_ASKED', withOptionalPayloadType<AuthParam>()),
  salesforceCodeAsked: createAction('auth/SALESFORCE_CODE_ASKED', withOptionalPayloadType<AuthParam>()),
  microsoftCodeAsked: createAction('auth/MICROSOFT_CODE_ASKED', withOptionalPayloadType<AuthParam>()),
  loginWithGoogleCode: createAction('auth/LOGIN_WITH_GOOGLE_CODE', withPayloadType<LoginParam>()),
  loginWithMicrosoftCode: createAction('auth/LOGIN_WITH_MICROSOFT_CODE', withPayloadType<LoginParam>()),
  loginSuccess: createAction<State>('auth/LOGIN_SUCCESS'),
  loginFailure: createAction('auth/LOGIN_FAILURE', withOptionalPayloadType<LoginFailureParam>()),
  logout: createAction('auth/LOGOUT'),
  refreshTokenInitiated: createAction('auth/REFRESH_TOKEN_INITIATED'),
  refreshTokenSuccess: createAction<Tokens>('auth/REFRESH_TOKEN_SUCCESS'),
  refreshTokenFailure: createAction('auth/REFRESH_TOKEN_FAILURE'),
};

export default actions;
