import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, styled } from '@mui/material';
import MentionCardCollection from 'components/mention/MentionCardCollection';
import useCollectionByUuid from 'hooks/collection/useCollectionByUuid';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import appSelectors from 'redux/App/app.selectors';
import { isCommentItem, isVideoItem } from 'redux/services/video.types';
import CollectionPanelVideo from './CollectionPanelVideo';

type CollectionPanelProps = {
  collectionUuid: string;
  videoUuid: string;
};

const CollectionPanelStyled = styled('div')`
  border: 1px solid #e0e0e0;
  background-color: white;

  .icon {
    opacity: 0.87;
  }

  .content {
    padding: 10px;
    max-height: 300px;
    overflow-y: scroll;
  }
`;

const CollectionPanel = ({ collectionUuid, videoUuid }: CollectionPanelProps) => {
  const navigate = useNavigate();
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const collection = useCollectionByUuid(collectionUuid);

  const closeCollectionPanel = () => {
    navigate(`/o/${currentOrganizationUuid}/v/${videoUuid}`, { replace: true });
  };

  if (!collection) return null;

  return (
    <CollectionPanelStyled>
      <MentionCardCollection
        collection={collection}
        actions={
          <IconButton aria-label="clsoe" onClick={closeCollectionPanel}>
            <CloseIcon />
          </IconButton>
        }
      />
      <Box display="flex" flexDirection="column" gap={1} className="content">
        {collection &&
          collection.items &&
          collection.items.map((item) => (
            <>
              {isCommentItem(item) && (
                <CollectionPanelVideo
                  video={item.comment.video}
                  collection={collection}
                  time={item.comment.offset / 1000}
                />
              )}
              {isVideoItem(item) && <CollectionPanelVideo video={item.video} collection={collection} />}
            </>
          ))}
      </Box>
    </CollectionPanelStyled>
  );
};

export default CollectionPanel;
