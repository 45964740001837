import { styled } from '@mui/material';
import Comment from 'components/video/Comment/Comment';
import React from 'react';
import { User } from 'redux/services/api.types';
import { useGetVideoCommentsQuery } from 'redux/services/video-comment';

type CommentListProps = {
  videoUuid: string;
  mention: User[] | undefined;
};

const CommentListStyled = styled('div')`
  display: flex;
  flex-direction: column;

  .comment-and-replies {
    border: 1px solid #e0e0e0;
    margin: 10px;
    background-color: white;
  }

  .form {
    border-top: 1px solid #e0e0e0;
  }
`;

const CommentList = ({ videoUuid, mention = [] }: CommentListProps) => {
  const { data: comments } = useGetVideoCommentsQuery({ videoUuid });

  return (
    <CommentListStyled>
      {comments &&
        comments.map((comment) => {
          return (
            <div key={comment.uuid} className="comment-and-replies">
              <Comment videoUuid={videoUuid} comment={comment} mode="parent" mention={mention} />
              {comment.replies &&
                comment.replies.map((reply) => (
                  <Comment key={reply.uuid} videoUuid={videoUuid} comment={reply} mode="reply" mention={mention} />
                ))}
              {comment && (
                <div className="form">
                  <Comment videoUuid={videoUuid} parentUuid={comment.uuid} mode="reply" mention={mention} />
                </div>
              )}
            </div>
          );
        })}
    </CommentListStyled>
  );
};

export default CommentList;
