import { ActionReducerMapBuilder, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import actions from './auth.actions';
import { LoginFailureParam, State, Tokens } from './auth.types';

const initialState: State = {
  isLoginError: false,
  isLoggingIn: false,
  isRefreshingToken: false,
  loginErrorMessage: undefined,
};

function loginSuccess(state: State, { payload }: PayloadAction<State>) {
  // It must return the new state
  // see https://redux-toolkit.js.org/usage/immer-reducers#resetting-and-replacing-state
  return payload;
}

function loginFailure(state: State, { payload }: PayloadAction<LoginFailureParam>) {
  state.isLoginError = true;
  state.isLoggingIn = false;
  state.loginErrorMessage = payload?.message;
}

function logout(state: State) {
  return initialState;
}

function onRefreshTokenStart(state: State) {
  state.isRefreshingToken = true;
}

function onRefreshTokenFulfilled(state: State) {
  state.isRefreshingToken = false;
}

function updateToken(state: State, { payload }: PayloadAction<Tokens>) {
  state.accessToken = payload.accessToken;
  state.refreshToken = payload.refreshToken;
  onRefreshTokenFulfilled(state);
}

const reducer = createReducer(initialState, (builder: ActionReducerMapBuilder<State>) =>
  builder
    .addCase(actions.loginSuccess, loginSuccess)
    .addCase(actions.loginFailure, loginFailure)
    .addCase(actions.logout, logout)
    .addCase(actions.refreshTokenInitiated, onRefreshTokenStart)
    .addCase(actions.refreshTokenFailure, onRefreshTokenFulfilled)
    .addCase(actions.refreshTokenSuccess, updateToken),
);

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  whiteList: ['user', 'accessToken', 'refreshToken'],
};

export default persistReducer(authPersistConfig, reducer);
