import { Collection, Permission, Video, videoCollections } from './video.types';

export interface Organization {
  uuid: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  members: Member[];
  subscriptions: Subscription[];
  collections: Collection[];
  freePlan: {
    free: boolean;
    reached: boolean;
    limit: number;
    limitDate: Date | undefined;
    total: number;
  };
}

export interface OrganizationCreation {
  name: string;
}

export interface Subscription {
  seats: number;
  status: string;
}

export interface Member {
  uuid: string;
  user: User;
  organization: Organization;
}

export interface MemberRemoval {
  uuid: string;
}

export interface User {
  uuid: string;
  email: string;
  name: string;
  pictureUrl: string;
  identities: Identity[];
}

export interface UserSetting {
  uuid: string;
  autoRecordExternal: boolean;
  autoRecordInternal: boolean;
  autoRecordOrganizerOnly: boolean;
  defaultOrganizationPermission: Permission;
  defaultTeamPermissions: { [key: string]: Permission };
}

export interface AutoRecordSettingEdition {
  autoRecordExternal: boolean;
  autoRecordInternal: boolean;
  autoRecordOrganizerOnly: boolean;
  defaultOrganizationPermission?: Permission;
  defaultTeamPermissions?: { [key: string]: Permission };
}

export type Invitation = InvitationPending | InvitationAccepted | InvitationDeclined;

export type InvitationDeclined = {
  uuid: string;
  email: string;
  status: 'DECLINED';
  declinedAt: Date;
  inviter: User;
  invitee: User;
  organization: Organization;
};

export type InvitationPending = {
  uuid: string;
  email: string;
  status: 'PENDING';
  inviter: User;
  invitee: User;
  organization: Organization;
};

export type InvitationAccepted = {
  uuid: string;
  email: string;
  status: 'ACCEPTED';
  acceptedAt: Date;
  inviter: User;
  invitee: User;
  organization: Organization;
};

export interface InvitationCreation {
  name?: string;
  email: string;
  pictureUrl?: string;
}

export interface InvitationRevocation {
  uuid: string;
}

export interface InvitationAcceptance {
  uuid: string;
  status: 'ACCEPTED' | 'DECLINED';
}

export interface VideoComment {
  uuid: string;
  content: object;
  offset: number;
  offsetEnd?: number;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  user: User;
  parent: VideoComment | null;
  replies: VideoComment[];
  collections: videoCollections;
  video: Video;
}

export interface VideoCommentCreation {
  videoUuid: string;
  parentUuid?: string;
  content: object;
  offset: number;
  offsetEnd?: number;
}

export interface VideoCommentUpdate {
  uuid: string;
  content: object;
}

export interface Identity {
  provider: 'GOOGLE' | 'MICROSOFT' | 'SALESFORCE';
  scope: string;
  instanceUrl: string;
}

export enum GoogleScope {
  DRIVE_READONLY = 'https://www.googleapis.com/auth/drive.readonly',
  CALENDAR_READONLY = 'https://www.googleapis.com/auth/calendar.readonly',
}

/**
 * import { Prisma } from '@prisma/client';
 * GoogleCalendarEvent
 */
export type CalendarEvent = {
  id: number;
  uuid: string;
  kind: string;
  googleId: string;
  status: string | null;
  htmlLink: string;
  meetLink: string | null;
  zoomLink: any | null;
  teamsLink: string | null;
  summary: string | null;
  description: string | null;
  startDate: Date | null;
  startDateTime: Date | null;
  startTimeZone: string | null;
  endDate: Date | null;
  endDateTime: Date | null;
  endTimeZone: string | null;
  organizerEmail: string;
  eventType: string;
  recurrence: string[];
  userId: number;
  organizationId: number;
  bot: Bot | null;
  botRecordUserOverride: boolean | null;
  videos: Video[];
};

export type Transcript = {
  uuid: string;
  source: 'RECALL' | 'WHISPER';
  content: any;
};

export type VideoTags = { tag: Tag }[];

export type Tag = {
  uuid: string;
  label: string;
};

export type TagAddition = {
  videoUuid: string;
  tag: { uuid?: string; label: string };
};

export type TagDeletion = {
  videoUuid: string;
  tag: { uuid: string; label: string };
};

export type Bot = {
  uuid: string;
  language: string;
  summaryType: string;
  prompt?: Prompt;
};

// Salesforce

export type SalesforceSObjectName = 'LEAD' | 'ACCOUNT' | 'CONTACT' | 'OPPORTUNITY';

export type SalesforceRecord = {
  name: string;
  type: SalesforceSObjectName;
  salesforceId: string;
  [fieldName: string]: any;
  attributes: {
    type: SalesforceSObjectName;
    url: string;
  };
};

export type SalesforceResource = {
  id: string;
  type: string;
};

export type Prompt = {
  createdAt: Date;
  id: number;
  uuid: string;
  name: string;
  role: string;
  content: string;
  isDefault: boolean;
  language: string;
  user?: User;
};

export type PromptEdition = {
  uuid: string;
  name: string;
  role: string;
  content: string;
  isDefault?: boolean;
};

export interface Team {
  uuid: string;
  id: number;
  name: string;
  description: string;
  isDefault?: boolean;
  users: string[]; // To include member UUIDs
}
