import { styled, Typography } from '@mui/material';
import React from 'react';

export interface EnhancedRenderOptionProps extends React.HTMLAttributes<HTMLLIElement> {
  label?: string | React.ReactNode;
  description?: string | React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const LiStyled = styled('li')`
  display: flex;
  flex-direction: row;
  gap: 10px;

  .icon {
    align-self: start;
    height: 24px;
    padding-top: 2px;
  }

  .info {
    display: flex;
    flex-direction: column;
  }
`;

export function EnhancedRenderOption(props: EnhancedRenderOptionProps) {
  const { label, description, startIcon, endIcon, ...rest } = props;

  return (
    <LiStyled {...rest}>
      <div className="icon">{startIcon}</div>
      <div className="info">
        <Typography variant="body1">{label ?? 'Unknown'}</Typography>
        {description && <Typography variant="caption">{description}</Typography>}
      </div>
    </LiStyled>
  );
}
