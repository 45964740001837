import { MemberRemoval, Organization, OrganizationCreation } from 'redux/services/api.types';
import api from 'redux/services/api';

export type FreePlanLimit = {
  freePlanLimit: number;
  freePlanLimitDate: Date;
};

const organizationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createOrganization: builder.mutation<Organization, OrganizationCreation>({
      query(body) {
        return {
          url: '/organizations',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Organization'],
    }),
    getOrganizations: builder.query<Organization[], void>({
      query: () => ({
        url: '/organizations',
      }),
      providesTags: ['Organization'],
    }),
    getOrganization: builder.query<Organization, string | null>({
      query: (uuid) => ({
        url: `/organizations/${uuid}`,
      }),
      providesTags: (organization) => [{ type: 'Organization', uuid: organization?.uuid }],
    }),
    removeMember: builder.mutation<void, MemberRemoval>({
      query(body) {
        return {
          url: `/organizations/members/${body.uuid}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Organization'],
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useGetOrganizationQuery,
  useRemoveMemberMutation,
  useCreateOrganizationMutation,
} = organizationApi;
