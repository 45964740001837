import HelpIcon from '@mui/icons-material/Help';

import { SvgIconProps } from '@mui/material';
import SalesforceAccountIcon from 'components/salesforce/Icons/SalesforceAccountIcon';
import SalesforceContactIcon from 'components/salesforce/Icons/SalesforceContactIcon';
import SalesforceLeadIcon from 'components/salesforce/Icons/SalesforceLeadIcon';
import SalesforceOpportunityIcon from 'components/salesforce/Icons/SalesforceOpportunityIcon';
import React from 'react';
import { SalesforceRecordIconProps } from './SalesforceRecordIcon.types';

const getIcon = ({
  type,
  unknown,
  ...props
}: Pick<SalesforceRecordIconProps, 'type' | 'unknown' | 'fontSize' | 'className'>) => {
  let Icon: React.FC<SvgIconProps>;
  switch (type) {
    case 'LEAD':
    case 'Lead':
      Icon = SalesforceLeadIcon;
      break;
    case 'ACCOUNT':
    case 'Account':
      Icon = SalesforceAccountIcon;
      break;
    case 'CONTACT':
    case 'Contact':
      Icon = SalesforceContactIcon;
      break;
    case 'OPPORTUNITY':
    case 'Opportunity':
      Icon = SalesforceOpportunityIcon;
      break;
    default:
      Icon = HelpIcon;
  }
  return <Icon {...props} sx={{ opacity: unknown ? '50%' : undefined }} />;
};

const SalesforceRecordIcon: React.FC<SalesforceRecordIconProps> = ({
  type,
  fontSize = 'small',
  unknown = false,
  ...props
}) => {
  return getIcon({ type, fontSize, unknown, ...props });
};

export default SalesforceRecordIcon;
