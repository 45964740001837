import StyledThumbnails from 'components/video/Thumbnails/Thumbnails.style';
import { useState } from 'react';

function Thumbnails({
  url,
  blocked,
  height,
  width,
  radius,
}: {
  url: string | undefined;
  blocked?: boolean;
  width?: number;
  height?: number;
  radius?: number;
}) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const grid = [
    { x: 0, y: 0 },
    { x: 1, y: 0 },
    { x: 2, y: 0 },
    { x: 3, y: 0 },
    { x: 0, y: 1 },
    { x: 1, y: 1 },
    { x: 2, y: 1 },
    { x: 3, y: 1 },
    { x: 0, y: 2 },
    { x: 1, y: 2 },
    { x: 2, y: 2 },
    { x: 3, y: 2 },
    { x: 0, y: 3 },
    { x: 1, y: 3 },
    { x: 2, y: 3 },
    { x: 3, y: 3 },
  ];
  return (
    <StyledThumbnails url={url} position={position} blocked={blocked} height={height} width={width} radius={radius}>
      {grid.map((position, index) => (
        <div
          key={index}
          className="hover-grid-item"
          onMouseOver={() => {
            setPosition(position);
          }}
        ></div>
      ))}
    </StyledThumbnails>
  );
}

export default Thumbnails;
