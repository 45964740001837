import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Thumbnails from 'components/video/Thumbnails/Thumbnails';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import appSelectors from 'redux/App/app.selectors';
import { Collection, Video } from 'redux/services/video.types';

const CollectionPanelVideoStyled = styled('div')`
  .title {
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    max-height: 48px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    opacity: 0.87;
  }

  .user {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

type CollectionPanelVideoProps = {
  collection: Collection;
  video: Video;
  time?: number;
};

const CollectionPanelVideo = ({ collection, video, time }: CollectionPanelVideoProps) => {
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  let videoUrl = `/o/${currentOrganizationUuid}/v/${video.uuid}?c=${collection.uuid}`;
  if (time) videoUrl += `&t=${time}`;

  return (
    <CollectionPanelVideoStyled>
      <Link to={videoUrl} key={video.uuid}>
        <Box display="flex" flexDirection="row" gap={2}>
          <Box width="100px" height="56px">
            <Thumbnails url={video.thumbnailsUrl} height={56} width={100} radius={8} />
          </Box>
          <Box display="flex" flex={1} flexDirection="column" overflow="hidden">
            <Box className="title">{video.title}</Box>
            <Box className="user" flex={1}>
              {video.user.name}
            </Box>
          </Box>
        </Box>
      </Link>
    </CollectionPanelVideoStyled>
  );
};

export default CollectionPanelVideo;
