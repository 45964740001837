import { Card, CardActionArea, styled } from '@mui/material';
import MentionCardCollection from 'components/mention/MentionCardCollection';
import Thumbnails from 'components/video/Thumbnails/Thumbnails';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import appSelectors from 'redux/App/app.selectors';
import { Collection } from 'redux/services/video.types';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

type Props = {
  collection: Collection;
};

const EmptyCollectionStyled = styled('div')`
  height: 180px;
  background-color: black;
  color: white;
  border-radius: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const CollectionCard = ({ collection }: Props) => {
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);

  return (
    <Link to={`/o/${currentOrganizationUuid}/c/${collection.uuid}`} key={collection.uuid}>
      <Card sx={{ width: 320, border: 'none' }}>
        <CardActionArea>
          {collection.videos.length === 0 && (
            <EmptyCollectionStyled>
              <VideoLibraryIcon />
              <p>Empty collection</p>
            </EmptyCollectionStyled>
          )}
          {collection.videos.length > 0 && <Thumbnails url={collection.videos[0].video.thumbnailsUrl} />}
          <MentionCardCollection collection={collection} />
        </CardActionArea>
      </Card>
    </Link>
  );
};

export default CollectionCard;
