import { styled } from '@mui/material';

type Props = {
  url: string | undefined;
  position: { x: number; y: number };
  blocked?: boolean;
  width?: number;
  height?: number;
  radius?: number;
};

const StyledThumbnails = styled('div')<Props>`
  height: ${(props) => `${props.height || 180}px`};
  background-image: url(${(props) => props.url});
  background-size: 400%;
  background-repeat: no-repeat;
  background-position: ${(props) =>
    `${-(props.width || 320) * props.position.x}px ${-(props.height || 180) * props.position.y}px`};
  border-radius: ${(props) => `${props.radius || 12}px`};

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  filter: ${(props) => (props.blocked ? 'blur(4px)' : 'none')};

  .hover-grid-item {
    height: 100%;
    width: 6%;
  }
`;

export default StyledThumbnails;
