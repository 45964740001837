import React, { PropsWithChildren, useCallback, useState } from 'react';
import { ConfirmOptions } from 'ui-kit/confirm/confirm.type';
import ConfirmDialog from 'ui-kit/confirm/ConfirmDialog';
import ConfirmContext from './ConfirmContext';

const ConfirmProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [options, setOptions] = useState<ConfirmOptions>({});
  const [resolveReject, setResolveReject] = useState<any>([]);
  const [resolve, reject] = resolveReject;

  const confirm = useCallback<(options: ConfirmOptions) => Promise<void>>((options) => {
    return new Promise((resolve, reject) => {
      setOptions(options);
      setResolveReject([resolve, reject]);
    });
  }, []);

  const handleClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = useCallback(() => {
    if (reject) {
      reject();
      handleClose();
    }
  }, [reject, handleClose]);

  const handleConfirm = useCallback(() => {
    if (resolve) {
      resolve();
      handleClose();
    }
  }, [resolve, handleClose]);

  return (
    <>
      <ConfirmContext.Provider value={confirm}>{children}</ConfirmContext.Provider>
      <ConfirmDialog
        open={resolveReject.length === 2}
        onClose={handleClose}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        options={options}
      ></ConfirmDialog>
    </>
  );
};

export default ConfirmProvider;
