import * as React from 'react';
import fscreen from 'fscreen';

const useFullscreen = (
  elRef: React.MutableRefObject<HTMLDivElement | null>,
): [boolean, (fullscreen: boolean) => void] => {
  const [isFullscreen, setIsFullscreen] = React.useState(!!fscreen.fullscreenElement);

  const setFullscreen = React.useCallback(
    (fullscreen: boolean) => {
      if (elRef.current == null) return;

      if (fullscreen && !isFullscreen) {
        fscreen.requestFullscreen(elRef.current);
        setIsFullscreen(true);
      } else if (!fullscreen && isFullscreen) {
        fscreen.exitFullscreen();
        setIsFullscreen(false);
      }
    },
    [elRef, isFullscreen, setIsFullscreen],
  );

  React.useLayoutEffect(() => {
    fscreen.onfullscreenchange = () => {
      setIsFullscreen(!!fscreen.fullscreenElement);
    };

    return () => {
      fscreen.onfullscreenchange = () => true;
    };
  }, [setIsFullscreen]);

  return [isFullscreen, setFullscreen];
};

export default useFullscreen;
