import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import api from 'redux/services/api';
import { HttpClient } from 'services/network';
import createRootReducer from './rootReducer';
import saga from './rootSaga';

const httpClient = new HttpClient();

export default function createStore() {
  const sagaMiddleware = createSagaMiddleware({
    context: {
      httpClient,
    },
  });
  const middlewares = [sagaMiddleware, api.middleware];

  const store = configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(middlewares),
    reducer: createRootReducer(),
    devTools: process.env.NODE_ENV === 'development',
  });

  sagaMiddleware.run(saga);

  const persistor = persistStore(store);

  return { store, persistor };
}
