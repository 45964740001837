import React from 'react';

/**
 * @param delay in ms
 * @param startAuto
 *
 * @example
 * function useDelayLoading(delay: number, loading: boolean) {
 *   const { display: delayedLoading, start, reset } = useDelayDisplay(delay, false);
 *
 *   React.useEffect(() => {
 *     if (loading) {
 *       start();
 *     } else {
 *       reset();
 *     }
 *   }, [loading, start, reset]);
 *
 *   return delayedLoading;
 * }
 */
function useDelayDisplay(delay: number = 500, startAuto = true) {
  const [display, setDisplay] = React.useState(false);
  const [startTimer, setStartTimer] = React.useState(startAuto);

  React.useEffect(() => {
    if (!startTimer) {
      return;
    }

    setDisplay(false);

    const timer = setTimeout(() => {
      setDisplay(true);
      setStartTimer(false);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay, startTimer]);

  const reset = React.useCallback(() => {
    setDisplay(false);
    setStartTimer(startAuto);
  }, [startAuto]);

  const start = React.useCallback(() => {
    setStartTimer(true);
  }, []);

  return { display, reset, start };
}

export default useDelayDisplay;
