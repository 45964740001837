import useQuery from 'hooks/routing/useQuery';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSalesforceConnectWithCodeMutation } from 'redux/services/user';
import ContainerFluid from 'ui-kit/layout/ContainerFluid';

const SalesforceIntegrationPage = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const code = query.get('code');
  const stateQueryParam = query.get('state');
  const [connectSalesforce, connectSalesforceResult] = useSalesforceConnectWithCodeMutation();

  useEffect(() => {
    if (code) {
      connectSalesforce({ code });
    }
  }, [code, connectSalesforce]);

  useEffect(() => {
    if (connectSalesforceResult.isSuccess && stateQueryParam) {
      const state = JSON.parse(stateQueryParam);
      if (state.redirectionUri) {
        navigate(state.redirectionUri);
      } else {
        navigate('/user');
      }
    }
  }, [connectSalesforceResult, navigate, stateQueryParam]);

  return <ContainerFluid>Connecting to Salesforce...</ContainerFluid>;
};

export default SalesforceIntegrationPage;
