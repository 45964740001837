import { Delete } from '@mui/icons-material';
import { IconButton, ListItem } from '@mui/material';
import MentionCardUser from 'components/mention/MentionCardUser';
import React from 'react';
import { Member } from 'redux/services/api.types';
import { useRemoveMemberMutation } from 'redux/services/organization';
import { useGetCurrentUserQuery } from 'redux/services/user';
import useConfirm from 'ui-kit/confirm/useConfirm';

const MemberItem = ({ member, divider }: { member: Member; divider: boolean }) => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const [removeMemberMutation] = useRemoveMemberMutation();
  const confirm = useConfirm();
  const removeMember = function (member: Member) {
    confirm({
      title: 'Remove User',
      content: `Are you sure you want to remove this user? (${member.user.email})`,
      confirmationText: 'Remove',
      cancellationText: 'Cancel',
    })
      .then(async () => {
        await removeMemberMutation({ uuid: member.uuid });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <ListItem
      key={member.uuid}
      secondaryAction={
        currentUser?.uuid !== member.user.uuid && (
          <IconButton edge="end" aria-label="delete" onClick={() => removeMember(member)}>
            <Delete />
          </IconButton>
        )
      }
      divider={divider}
    >
      <MentionCardUser user={member.user} />
    </ListItem>
  );
};

export default MemberItem;
