import { Button, DialogActions, DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import React from 'react';
import { ConfirmationDialogProps } from 'ui-kit/confirm/confirm.type';

const ConfirmDialog = ({ open, onClose, onCancel, onConfirm, options }: ConfirmationDialogProps) => {
  const { title, content, confirmationText, cancellationText } = options;
  return (
    <Dialog open={open} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {content && (
        <DialogContent>
          <Typography>{content}</Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onCancel}>{cancellationText}</Button>
        <Button color="primary" onClick={onConfirm}>
          {confirmationText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
