import { Box, Card, CardActionArea, Typography } from '@mui/material';
import MentionCardVideoAndUser from 'components/mention/MentionCardVideoAndUser';
import Thumbnails from 'components/video/Thumbnails/Thumbnails';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import appSelectors from 'redux/App/app.selectors';
import { useGetTagsQuery, useSearchVideosQuery } from 'redux/services/video';
import ContainerFluid from 'ui-kit/layout/ContainerFluid';

function TagVideosPage() {
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: tags } = useGetTagsQuery();
  const { tagUuid } = useParams();
  const { data: videos } = useSearchVideosQuery({ tagUuid }, { skip: !tagUuid });
  return (
    <div>
      <ContainerFluid sx={{ paddingTop: '20px' }}>
        <Typography gutterBottom variant="h1">
          Tag: {tags ? tags.find((tag) => tag.uuid === tagUuid)?.label : ''}
        </Typography>
      </ContainerFluid>
      <ContainerFluid>
        <Box display="flex" justifyContent="left" flexWrap="wrap" gap="10px">
          {videos &&
            videos.map((video) => {
              return (
                <Link to={`/o/${currentOrganizationUuid}/v/${video.uuid}`} key={video.uuid}>
                  <Card sx={{ width: 320, border: 'none' }}>
                    <CardActionArea>
                      <Thumbnails url={video.thumbnailsUrl} />
                      <MentionCardVideoAndUser video={video} />
                    </CardActionArea>
                  </Card>
                </Link>
              );
            })}
        </Box>
      </ContainerFluid>
    </div>
  );
}

export default TagVideosPage;
