import api from 'redux/services/api';
import { Prompt, PromptEdition } from './api.types';

const promptApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPrompts: builder.query<Prompt[], void>({
      query: () => ({
        url: '/prompts',
      }),
      providesTags: ['Prompt'],
    }),
    updatePrompt: builder.mutation<Prompt, PromptEdition>({
      query: (prompt) => ({
        url: `/prompts/${prompt.uuid}`,
        method: 'PUT',
        body: prompt,
      }),
      invalidatesTags: ['Prompt'],
    }),
    createPrompt: builder.mutation<Prompt, PromptEdition>({
      query: (prompt) => ({
        url: '/prompts',
        method: 'POST',
        body: prompt,
      }),
      invalidatesTags: ['Prompt'],
    }),
    deletePrompt: builder.mutation<void, string>({
      query: (promptUuid) => ({
        url: `/prompts/${promptUuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Prompt'],
    }),
  }),
});

export const { useGetPromptsQuery, useUpdatePromptMutation, useCreatePromptMutation, useDeletePromptMutation } =
  promptApi;
