import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, Card, CardActionArea, CardActions } from '@mui/material';
import React from 'react';
import TodayIcon from '@mui/icons-material/Today';
import { useSelector } from 'react-redux';
import tokens from 'ui-kit/tokens';
import { useNavigate } from 'react-router-dom';
import { appSelectors } from '../../../redux/App';

const CalendarCard = () => {
  const navigate = useNavigate();
  const organizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);

  const handleOpen = () => {
    navigate(`/o/${organizationUuid}/calendar`);
  };

  return (
    <Card
      sx={{ width: 320, height: 180, backgroundColor: tokens.colors.primary.light, borderRadius: '12px' }}
      onClick={handleOpen}
    >
      <CardActionArea sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: '90px',
            color: tokens.colors.neutral.dark,
            marginTop: '10px',
          }}
        >
          <CloudUploadIcon sx={{ height: '100px', width: '100px' }} />
        </Box>
        <CardActions sx={{ flex: 1, width: '80%' }}>
          <Button startIcon={<TodayIcon />} fullWidth variant="contained" color="primary">
            Schedule a recording
          </Button>
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

export default CalendarCard;
