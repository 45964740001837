import { Avatar as MuiAvatar, AvatarProps } from '@mui/material';
import merge from 'lodash/merge';
import React from 'react';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

// @see: https://github.com/bonjourio/demox/blob/staging/packages/common/src/services/string/stringUtils.ts
function getInitials(fullName: string): string {
  const names = fullName.split(' ');
  const firstAndLastName: string[] = [];
  names.forEach((name, i, arr) => {
    // Test if 'name' is the first or the last name
    if (i === 0 || i === arr.length - 1) {
      firstAndLastName.push(name);
    }
  });
  return firstAndLastName
    .reduce((acc, name) => {
      return acc.concat(name.substring(0, 1));
    }, '')
    .toUpperCase();
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: getInitials(name),
  };
}

interface MyAvatarProps extends AvatarProps {
  name?: string;
}

function Avatar({ name, ...props }: MyAvatarProps) {
  if (props.src) {
    return <MuiAvatar {...props} />;
  }
  if (name) {
    const propsMerged = merge(props, stringAvatar(name));
    return <MuiAvatar {...propsMerged} />;
  }
  return <MuiAvatar {...props} />;
}

export default Avatar;
