import { Check, Code } from '@mui/icons-material';

import { Button, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, styled } from '@mui/material';
import Divider from '@mui/material/Divider';
import MentionCardOrganization from 'components/mention/MentionCardOrganization';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { appActions, appSelectors } from 'redux/App';
import { useGetOrganizationQuery, useGetOrganizationsQuery } from 'redux/services/organization';
import { useGetUserInvitationsQuery } from 'redux/services/user';

// https://github.com/jbeurel/demox/blob/staging/zoomapp/src/components/molecules/OrganizationSelector/OrganizationSelector.tsx
// Hint: Copycat Notion Menu

const OrganizationSelectorStyled = styled(Button)`
  width: 240px;
  display: flex;
  padding: 5px;
  color: #101a11;
  border-radius: 0;
  height: 54px;
  text-align: left;
  margin-left: -2px; // To align with the rest of the menu
  &:hover {
    background-color: #78bb7b;
  }
  .organization {
    flex: 1;
  }
  .arrow {
    display: flex;
    justify-content: center;
    width: 40px;
    .MuiSvgIcon-root {
      transform: rotate(90deg);
    }
  }
`;

function OrganizationSelector() {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  // Organization Data
  const { data: currentOrganization } = useGetOrganizationQuery(currentOrganizationUuid);
  const { data: organizations } = useGetOrganizationsQuery();
  // Organization Selection handling
  function handleSelection(value: string) {
    dispatch(appActions.currentOrganizationChanged(value)); // Redux
    setAnchorEl(null); // Menu close
    navigate(`/o/${value}/v`); // Redirect
  }
  // Invitation Data
  const { data: invitations } = useGetUserInvitationsQuery({ status: 'PENDING' });

  // Menu behaviours
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!currentOrganization) return <div>Loading...</div>;

  return (
    <>
      <OrganizationSelectorStyled onClick={handleClick}>
        <div className="organization">
          <MentionCardOrganization organization={currentOrganization} secondaryLine={false} />
        </div>
        <div className="arrow">
          <Code />
        </div>
      </OrganizationSelectorStyled>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ListSubheader>Organizations</ListSubheader>
        {organizations &&
          organizations.map((organization) => (
            <MenuItem
              key={organization.uuid}
              onClick={() => {
                handleSelection(organization.uuid);
              }}
              sx={{ padding: '0 10px 0 0' }}
            >
              <ListItemText>
                <MentionCardOrganization organization={organization} secondaryLine={false} />
              </ListItemText>
              {organization.uuid === currentOrganization.uuid && (
                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                  <Check />
                </ListItemIcon>
              )}
            </MenuItem>
          ))}
        <Divider />
        <MenuItem>
          <Link to="/user/organizations/create">
            <Button variant="outlined">Create New Organization</Button>
          </Link>
        </MenuItem>
        {invitations && invitations.length > 0 && (
          <>
            <Divider />
            <ListSubheader>Invitations</ListSubheader>
          </>
        )}
        {invitations &&
          invitations.map((invitation) => (
            <MenuItem key={invitation.uuid} sx={{ padding: '0' }}>
              <Link to="/user">
                <MentionCardOrganization
                  organization={invitation.organization}
                  secondary={`Invited by ${invitation.inviter.name}`}
                />
              </Link>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}

export default OrganizationSelector;
