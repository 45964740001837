import { LoadingButton } from '@mui/lab';
import { Box, Card, CardActions, CardContent, CardHeader, LinearProgress, TextField } from '@mui/material';
import { useFormik } from 'formik';
import InvitationReceivedItem from 'pages/User/components/InvitationReceivedItem';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appActions, appSelectors } from 'redux/App';
import { useCreateOrganizationMutation, useGetOrganizationsQuery } from 'redux/services/organization';
import { useGetUserInvitationsQuery } from 'redux/services/user';

const OnboardingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Invitation
  const { data: invitations } = useGetUserInvitationsQuery();

  // Create Organization
  const { data: organizations } = useGetOrganizationsQuery();
  const [createOrganization, createOrganizationResult] = useCreateOrganizationMutation();
  const formik = useFormik({
    initialValues: { name: '' },
    onSubmit: (values) => {
      createOrganization(values);
    },
  });

  // Define current organization
  useEffect(() => {
    if (organizations && organizations.length > 0) {
      console.log('[onboarding] organizations list changed. organizations[0].uuid:', organizations[0].uuid); // Debug
      dispatch(appActions.currentOrganizationChanged(organizations[0].uuid)); // Redux
    }
  }, [dispatch, organizations]);

  // Navigate to calendar onboarding page
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  useEffect(() => {
    if (currentOrganizationUuid) {
      console.log('[onboarding] currentOrganizationUuid changed. currentOrganizationUuid:', currentOrganizationUuid); // Debug
      navigate('/onboarding/calendar');
    }
  }, [currentOrganizationUuid, navigate]);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" gap={1} sx={{ width: '500px', margin: '5px 20px' }}>
        <LinearProgress variant="determinate" sx={{ flex: '1' }} />
        <LinearProgress variant="determinate" color="info" sx={{ flex: '1', opacity: '50%' }} />
        <LinearProgress variant="determinate" color="info" sx={{ flex: '1', opacity: '50%' }} />
      </Box>
      {invitations && invitations.filter((i) => i.status === 'PENDING' || i.status === 'ACCEPTED').length > 0 && (
        <Card sx={{ width: '500px', margin: '0 20px' }}>
          <CardHeader title="Invitations" subheader="You have been invited to join the following organization." />
          <CardContent>
            {invitations.map((invitation, index) => (
              <InvitationReceivedItem
                key={invitation.uuid}
                invitation={invitation}
                divider={index !== invitations.length - 1}
              />
            ))}
          </CardContent>
        </Card>
      )}
      {invitations && invitations.filter((i) => i.status === 'PENDING' || i.status === 'ACCEPTED').length === 0 && (
        <form onSubmit={formik.handleSubmit}>
          <Card sx={{ width: '500px', margin: '5px 20px' }}>
            <CardHeader title="Set up your organization" subheader="Provide information to create your workspace." />
            <CardContent>
              <TextField
                fullWidth
                id="name"
                label="What's the name of your company?"
                name="name"
                type="text"
                variant="outlined"
                placeholder="Acme Inc."
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </CardContent>
            <CardActions style={{ justifyContent: 'end' }}>
              <LoadingButton
                disabled={formik.values.name.length === 0}
                loading={createOrganizationResult.isLoading}
                variant="contained"
                color="primary"
                type="submit"
              >
                Create
              </LoadingButton>
            </CardActions>
          </Card>
        </form>
      )}
    </Box>
  );
};

export default OnboardingPage;
