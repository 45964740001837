import React, { PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import api from 'redux/services/api';
import { useCalendarIntegrationQuery } from 'redux/services/calendar';

const SynchronizingContainer: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const dispatch = useDispatch();
  const { data: calendarIntegrationQuery } = useCalendarIntegrationQuery();
  const [synchronized, setSynchronized] = React.useState(false);
  const refresh = useCallback(() => {
    dispatch(api.util.invalidateTags(['CalendarEvent']));
    dispatch(api.util.invalidateTags(['CalendarIntegration']));
  }, [dispatch]);

  const intervalRef = useRef<NodeJS.Timer | null>(null);
  useEffect(() => {
    if (synchronized) {
      if (intervalRef.current) clearInterval(intervalRef.current);
      return;
    }
    intervalRef.current = setInterval(() => {
      refresh();
    }, 2000);
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [refresh, synchronized]);

  useEffect(() => {
    if (calendarIntegrationQuery && calendarIntegrationQuery.synchronizing === false) {
      setSynchronized(true);
    }
    if (calendarIntegrationQuery && calendarIntegrationQuery.synchronizing === true) {
      setSynchronized(false);
    }
  }, [calendarIntegrationQuery]);

  if (synchronized) return null;

  return <>{children}</>;
};

export default SynchronizingContainer;
