import api from 'redux/services/api';
import { Collection } from 'redux/services/video.types';

const collectionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCollections: builder.query<Collection[], void>({
      query: () => ({
        url: '/collections',
      }),
      providesTags: ['Collection'],
    }),
    getCollection: builder.query<Collection, string | undefined>({
      query: (uuid) => ({
        url: `/collections/${uuid}`,
      }),
      providesTags: ['Collection'],
    }),
    createCollection: builder.mutation<Collection, Partial<Collection>>({
      query: ({ name }) => ({
        url: '/collections',
        method: 'POST',
        body: { name },
      }),
      invalidatesTags: ['Collection', 'Organization'],
    }),
    deleteCollection: builder.mutation<Collection, string>({
      query: (uuid) => ({
        url: `/collections/${uuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Collection', 'Organization'],
    }),
    renameCollection: builder.mutation<Collection, { uuid: string; name: string }>({
      query: ({ uuid, name }) => ({
        url: `/collections/${uuid}/rename`,
        method: 'PUT',
        body: { name },
      }),
      invalidatesTags: ['Collection', 'Organization'],
    }),
    addVideosToCollection: builder.mutation<Collection, { uuid: string; videoUuids: (string | number)[] }>({
      query: ({ uuid, videoUuids }) => ({
        url: `/collections/${uuid}/videos`,
        method: 'POST',
        body: { videoUuids },
      }),
      invalidatesTags: ['Collection', 'Video'],
    }),
  }),
});

export const {
  useGetCollectionsQuery,
  useGetCollectionQuery,
  useCreateCollectionMutation,
  useDeleteCollectionMutation,
  useRenameCollectionMutation,
  useAddVideosToCollectionMutation,
} = collectionApi;
