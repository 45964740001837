import MentionCard from 'components/mention/MentionCard';
import dayjs from 'dayjs';
import React from 'react';
import { Collection } from 'redux/services/video.types';
import Avatar from 'ui-kit/avatar/Avatar';
import tokens from 'ui-kit/tokens';
import { MentionCardProps } from './MentionCard';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
type MentionCardCollectionProps = Partial<MentionCardProps> & {
  collection: Collection;
};

const MentionCardCollection: React.FC<MentionCardCollectionProps> = ({ collection, ...props }) => {
  return (
    <MentionCard
      avatar={
        <Avatar sx={{ backgroundColor: tokens.colors.primary.main }}>
          <VideoLibraryIcon sx={{ height: '24px', width: '24px' }} />
        </Avatar>
      }
      avatarShape="circle"
      primary={collection.name}
      secondary={dayjs(collection.createdAt).format('MMM D, YYYY h:mm A')}
      {...props}
    />
  );
};

export default MentionCardCollection;
