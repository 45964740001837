import * as React from 'react';
import { throttle } from 'lodash';

const useClientDimensions = (): [(node: HTMLElement) => void, DOMRect | null] => {
  const [elementRef, setElementRef] = React.useState<HTMLElement | null>(null);
  const [elementBounds, setElementBounds] = React.useState<DOMRect | null>(null);

  const exposedRef = React.useCallback(
    (node: HTMLElement) => {
      if (node) {
        setElementRef(node);
        setElementBounds(node.getBoundingClientRect());
      }
    },
    [setElementBounds, setElementRef],
  );

  React.useEffect(() => {
    const throttledHandleResize = throttle(function handleResize() {
      if (elementRef) {
        setElementBounds(elementRef.getBoundingClientRect());
      }
    }, 100);

    window.addEventListener('resize', throttledHandleResize);

    return () => {
      window.removeEventListener('resize', throttledHandleResize);
    };
  }, [setElementBounds, elementRef]);

  return [exposedRef, elementBounds];
};

export default useClientDimensions;
