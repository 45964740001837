import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function SalesforceOpportunityIcon(
  props: SvgIconProps,
  ref: ((instance: SVGSVGElement | null) => void) | React.RefObject<SVGSVGElement> | null | undefined,
) {
  return (
    <SvgIcon {...props} ref={ref}>
      <rect width="24" height="24" rx="4" fill="#FCB95B" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" rx="4" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M17.6047 17.3125H6.39531C6.12969 17.3125 5.89062 17.5516 5.89062 17.8172V17.8438C5.89062 18.7203 6.60781 19.4375 7.48438 19.4375H16.5156C17.3922 19.4375 18.1094 18.7203 18.1094 17.8438V17.8172C18.1094 17.5516 17.8703 17.3125 17.6047 17.3125Z"
          fill="white"
        />
        <path
          d="M18.9062 6.42188C18.0297 6.42188 17.3125 7.13906 17.3125 8.01562C17.3125 8.49375 17.525 8.94531 17.8703 9.2375C17.4187 10.2734 16.3828 10.9906 15.1609 10.9375C13.7531 10.8578 12.6109 9.71562 12.5312 8.30781C12.5312 8.06875 12.5312 7.85625 12.5844 7.64375C13.1688 7.40469 13.5938 6.84687 13.5938 6.15625C13.5938 5.27969 12.8766 4.5625 12 4.5625C11.1234 4.5625 10.4062 5.27969 10.4062 6.15625C10.4062 6.82031 10.8313 7.40469 11.4156 7.64375C11.4688 7.85625 11.4688 8.06875 11.4688 8.30781C11.3891 9.71562 10.2469 10.8578 8.83906 10.9375C7.61719 11.0172 6.55469 10.2734 6.12969 9.2375C6.475 8.94531 6.6875 8.49375 6.6875 8.01562C6.6875 7.13906 5.97031 6.42188 5.09375 6.42188C4.21719 6.42188 3.5 7.13906 3.5 8.01562C3.5 8.89219 4.21719 9.60938 5.09375 9.60938L5.8375 15.2937C5.86406 15.5328 6.07656 15.7188 6.34219 15.7188H17.6578C17.8969 15.7188 18.1094 15.5328 18.1625 15.2937L18.9062 9.60938C19.7828 9.60938 20.5 8.89219 20.5 8.01562C20.5 7.13906 19.7828 6.42188 18.9062 6.42188Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
}

export default React.memo(React.forwardRef(SalesforceOpportunityIcon));
