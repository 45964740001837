import { Box } from '@mui/material';
import useClientDimensions from 'hooks/resizing/useClientDimensions';
import * as React from 'react';
import { ComputedDisplayPeriod, DisplayPeriod, Period, Pixels, RGBColor, Seconds } from './Timeline.types';
import { computePeriods } from './Timeline.utils';

export interface Props {
  duration: Seconds;
  periods: DisplayPeriod[];
  periodColor?: RGBColor;
  roundedPeriod?: boolean;
  minSizePeriod: Pixels;
  maxSpaceBetweenPeriods?: Pixels;
  label?: string;
  speakerName?: string;
  shouldMergePeriods?: (previous: Period, current: Period) => boolean;
  onTimelineStartSeek?: (event: any) => void;
}

/*
 ** IMPORTANT NOTE: Parent component must declare css property: flex-wrap: wrap;
 ** If you dont the flex processor will not accept to resize the component under the current timeline width.
 ** Reason: all combined PeriodDiv width reach 100% of its parent div.
 */
const Timeline = (props: Props) => {
  let {
    duration,
    periods,
    periodColor = '#E78578',
    minSizePeriod,
    maxSpaceBetweenPeriods = minSizePeriod,
    label,
    speakerName,
    roundedPeriod = false,
    shouldMergePeriods = () => true,
    onTimelineStartSeek,
  } = props;

  if (speakerName) {
    periods = periods.filter((period) => period.speaker_name === speakerName);
  }
  const [timelineRef, timelineBounds] = useClientDimensions();

  const computedPeriods = React.useMemo<ComputedDisplayPeriod[] | undefined>(() => {
    if (!timelineBounds) {
      return undefined;
    }

    return computePeriods(
      periods,
      timelineBounds.width,
      duration,
      minSizePeriod,
      maxSpaceBetweenPeriods,
      shouldMergePeriods,
    );
  }, [periods, duration, timelineBounds, minSizePeriod, maxSpaceBetweenPeriods, shouldMergePeriods]);

  return (
    <Box display="flex" flexDirection="column" flex="1">
      {speakerName && <p>{speakerName}</p>}
      {label && <p>{label}</p>}
      <Box
        // TODO remove ts ignore
        // ref typing not supported until MUI core 5.X https://github.com/mui-org/material-ui/issues/17010
        // @ts-ignore
        ref={timelineRef}
        onMouseDown={onTimelineStartSeek}
        height="10px"
        minHeight="10px"
        bgcolor="#f0f0f0"
        display="flex"
        flex="1"
        flexDirection="row"
        position="relative"
      >
        {computedPeriods &&
          computedPeriods.map((period, idx) => (
            <Box
              key={idx}
              left={`${period.left}%`}
              width={`${period.width}px`}
              bgcolor={period.periodColor || periodColor}
              borderRadius={`${roundedPeriod ? 5 : 0}px`}
              position="absolute"
              height="10px"
            />
          ))}
      </Box>
    </Box>
  );
};

export default Timeline;
