import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function SalesforceContactIcon(
  props: SvgIconProps,
  ref: ((instance: SVGSVGElement | null) => void) | React.RefObject<SVGSVGElement> | null | undefined,
) {
  return (
    <SvgIcon {...props} ref={ref}>
      <rect width="24" height="24" rx="4" fill="#A094ED" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" rx="4" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.2 6.19167H18.8C19.735 6.19167 20.5 6.95667 20.5 7.89167V16.1083C20.5 17.0433 19.735 17.8083 18.8 17.8083H5.2C4.265 17.8083 3.5 17.0433 3.5 16.1083V7.89167C3.5 6.95667 4.265 6.19167 5.2 6.19167ZM9.16667 15.825H11.6033C12.1417 15.825 12.5667 15.23 12.51 14.6633C12.4817 13.8133 11.6033 13.2467 10.6683 12.85C10.0167 12.595 9.93167 12.34 9.93167 12.0567C9.93167 11.7733 10.1017 11.5183 10.3283 11.32C10.725 10.98 10.9233 10.47 10.9233 9.90333C10.9233 8.82667 10.2717 7.92 9.13833 7.92C8.005 7.92 7.35333 8.82667 7.35333 9.90333C7.35333 10.47 7.58 10.98 7.94833 11.32C8.175 11.5183 8.345 11.7733 8.345 12.0567C8.345 12.34 8.26 12.595 7.60833 12.8783C6.67333 13.3033 5.795 13.8133 5.76667 14.6633C5.76667 15.23 6.19167 15.825 6.73 15.825H9.16667ZM18.2333 13.8417C18.2333 14.1533 17.9783 14.4083 17.6667 14.4083H15.1167C14.805 14.4083 14.55 14.1533 14.55 13.8417V12.9917C14.55 12.68 14.805 12.425 15.1167 12.425H17.6667C17.9783 12.425 18.2333 12.68 18.2333 12.9917V13.8417ZM17.6667 11.2917C17.9783 11.2917 18.2333 11.0367 18.2333 10.725V9.875C18.2333 9.56333 17.9783 9.30833 17.6667 9.30833H13.4167C13.105 9.30833 12.85 9.56333 12.85 9.875V10.725C12.85 11.0367 13.105 11.2917 13.4167 11.2917H17.6667Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
}

export default React.memo(React.forwardRef(SalesforceContactIcon));
