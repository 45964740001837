import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function SalesforceLeadIcon(
  props: SvgIconProps,
  ref: ((instance: SVGSVGElement | null) => void) | React.RefObject<SVGSVGElement> | null | undefined,
) {
  return (
    <SvgIcon {...props} ref={ref}>
      <rect width="24" height="24" rx="4" fill="#F88962" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" rx="4" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M19.6774 10.2177H12H4.32259C3.7742 10.2177 3.55485 10.9032 4.02098 11.2048L8.0242 13.7823C8.21614 13.9194 8.32582 14.1661 8.24356 14.3855L6.73549 19.4032C6.57098 19.9516 7.28388 20.3355 7.69517 19.9242L11.5887 15.8113C11.8081 15.5645 12.1919 15.5645 12.4113 15.8113L16.3048 19.9242C16.6887 20.3355 17.4016 19.9516 17.2645 19.4032L15.7565 14.3855C15.7016 14.1661 15.7839 13.9194 15.9758 13.7823L19.979 11.2048C20.4452 10.9032 20.2258 10.2177 19.6774 10.2177Z"
          fill="white"
        />
        <path
          d="M12 8.57258C13.3629 8.57258 14.4677 7.46773 14.4677 6.10484C14.4677 4.74194 13.3629 3.6371 12 3.6371C10.6371 3.6371 9.53223 4.74194 9.53223 6.10484C9.53223 7.46773 10.6371 8.57258 12 8.57258Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
}

export default React.memo(React.forwardRef(SalesforceLeadIcon));
