import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from 'redux/rootTypes';
import { SalesforceRecord } from 'redux/services/api.types';

const actions = {
  recordSelected: createAction('sidebar/RECORD_SELECTED', withPayloadType<SalesforceRecord>()),
  videoCommentSelected: createAction('sidebar/VIDEO_COMMENT_SELECTED', withPayloadType<string>()),
  videoChatSelected: createAction('sidebar/VIDEO_CHAT_SELECTED', withPayloadType<string>()),
  close: createAction('sidebar/CLOSE'),
};

export default actions;
