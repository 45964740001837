import CloseIcon from '@mui/icons-material/Close';
import CommentIcon from '@mui/icons-material/Comment';
import { Box, Divider, IconButton, styled } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appSelectors } from 'redux/App';
import { useGetOrganizationQuery } from 'redux/services/organization';
import sidebarActions from 'redux/Sidebar/sidebar.actions';
import SimpleBar from 'simplebar-react';
import Comment from './Comment';
import CommentList from './CommentList';

type CommentTimelineProps = {
  videoUuid: string;
};

const CommentTimelineStyled = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;

  .comment-title {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    overflow: hidden;
    flex: 1;

    .comment-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;
    }
  }
  .comment-content {
    flex: 1;
    overflow-y: auto;

    // workaround to fix simplebar scrollbar
    .simplebar-placeholder {
      display: none;
    }

    .comment-new {
      background-color: white;
      margin: 10px 10px 0 10px;
      border: 1px solid #e0e0e0;
    }
  }
`;

const CommentTimeline = ({ videoUuid }: CommentTimelineProps) => {
  const dispatch = useDispatch();

  /**
   * UI
   */
  const handleClear = useCallback(() => {
    dispatch(sidebarActions.close());
  }, [dispatch]);

  // Users
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization } = useGetOrganizationQuery(currentOrganizationUuid);
  const users = currentOrganization?.members.map((member) => {
    return member.user;
  });

  return (
    <CommentTimelineStyled>
      <Box display="flex" flexDirection="row" padding="10px" gap="10px">
        <IconButton size="small" onClick={handleClear}>
          <CloseIcon />
        </IconButton>
        <div className="comment-title">
          <CommentIcon />
          <div className="comment-name">Comment</div>
        </div>
      </Box>
      <Divider />
      <SimpleBar className="comment-content">
        <div className="comment-new">
          <Comment videoUuid={videoUuid} withOffset={true} mode="parent" mention={users} />
        </div>
        <CommentList videoUuid={videoUuid} mention={users} />
      </SimpleBar>
    </CommentTimelineStyled>
  );
};

export default CommentTimeline;
