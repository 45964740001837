import MentionCard from 'components/mention/MentionCard';
import dayjs from 'dayjs';
import React from 'react';
import Avatar from 'ui-kit/avatar/Avatar';
import { MentionCardProps } from './MentionCard';

type MentionCardVideoCommentProps = Partial<MentionCardProps> & {
  comment: {
    user: {
      name: string;
      email?: string;
      pictureUrl?: string;
    };
    offset: number;
    createdAt: string;
  };
};

const MentionCardVideoComment: React.FC<MentionCardVideoCommentProps> = ({ comment, ...props }) => {
  return (
    <MentionCard
      avatar={
        <Avatar src={comment.user.pictureUrl} name={comment.user.name} imgProps={{ referrerPolicy: 'no-referrer' }} />
      }
      avatarShape="circle"
      primary={comment.user.name}
      secondary={dayjs(comment.createdAt).format('MMM D, YYYY, h:mmA')}
      {...props}
    />
  );
};

export default MentionCardVideoComment;
