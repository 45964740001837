import { FormHelperText, FormLabel, TextFieldProps, FormControl as MuiFormControl } from '@mui/material';
import React from 'react';

const FormControl = (props: TextFieldProps, ref: React.Ref<any>) => {
  const { children, label, helperText, id, fullWidth, error } = props;

  return (
    <MuiFormControl fullWidth={fullWidth} error={error} ref={ref}>
      <FormLabel htmlFor={id} sx={{ padding: '8px 0 1px 0', fontSize: '11px' }}>
        {label}
      </FormLabel>
      {children}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </MuiFormControl>
  );
};

export default React.forwardRef(FormControl);
