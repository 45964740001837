import { SalesforceRecordFieldProps } from 'components/salesforce/salesforce.types';
import React, { useId } from 'react';
import FormControl from 'ui-kit/form/FormControl/FormControl';
import Input from 'ui-kit/form/Input/Input';

const SalesforceRecordFieldString = ({
  field,
  label,
  value,
  disabled,
  error,
  helperText,
  InputProps,
}: SalesforceRecordFieldProps) => {
  const id = useId();
  const innerHelperText = React.useMemo(() => {
    if (helperText) return helperText;
    if (!field.length) return null;
    if (value) return `${(value as string).length}/${field.length} characters`;
  }, [helperText, value, field.length]);

  return (
    <FormControl fullWidth id={id} helperText={innerHelperText} label={label} error={error}>
      <Input id={id} disabled={disabled} autoComplete="off" defaultValue={value} fullWidth {...InputProps} />
    </FormControl>
  );
};

export default SalesforceRecordFieldString;
