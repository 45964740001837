import api from 'redux/services/api';
import { GoogleDriveFile } from 'redux/services/video.types';

const driveApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFiles: builder.query<GoogleDriveFile[], { trashed?: boolean; starred?: boolean }>({
      query: (filters) => {
        return {
          url: '/google/drive/files/list',
          params: filters,
        };
      },
      providesTags: (result) =>
        result
          ? [...result.map(({ uuid }) => ({ type: 'Post' as const, uuid })), { type: 'GoogleDriveFile', id: 'LIST' }]
          : [{ type: 'GoogleDriveFile', id: 'LIST' }],
    }),
    importGoogleDriveFile: builder.mutation<GoogleDriveFile, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/google/drive/files/${uuid}/program-import`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { uuid }) => [{ type: 'GoogleDriveFile', uuid }],
      onQueryStarted: async ({ uuid }, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          driveApi.util.updateQueryData('getFiles', {}, (draftFiles) => {
            const draftFile = draftFiles.find((file) => file.uuid === uuid);
            if (draftFile) {
              draftFile.video = data.video;
            }
          }),
        );
      },
    }),
  }),
});

export const { useGetFilesQuery, useImportGoogleDriveFileMutation } = driveApi;
