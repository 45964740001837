import { Box, Card, CardActions, CardContent, Grid, List, ListItem, Paper, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button/Button';
import Divider from '@mui/material/Divider';
import { useFormik } from 'formik';
import InvitationReceivedItem from 'pages/User/components/InvitationReceivedItem';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appActions } from 'redux/App';
import { useCreateOrganizationMutation } from 'redux/services/organization';
import { useGetUserInvitationsQuery } from 'redux/services/user';
import Header from 'ui-kit/header/Header';
import ContainerFluid from 'ui-kit/layout/ContainerFluid';

const OrganizationCreationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createOrganization, createOrganizationResult] = useCreateOrganizationMutation();
  const formik = useFormik({
    initialValues: { name: '' },
    onSubmit: (values) => {
      createOrganization(values);
    },
  });

  useEffect(() => {
    if (createOrganizationResult.isSuccess) {
      dispatch(appActions.currentOrganizationChanged(createOrganizationResult.data.uuid)); // Redux
      navigate('/o/' + createOrganizationResult.data.uuid + '/settings');
    }
  }, [createOrganizationResult, dispatch, navigate]);

  // Invitation Data
  const { data: invitations } = useGetUserInvitationsQuery();

  return (
    <>
      <Header>
        <ContainerFluid>
          <Box display="flex">
            <Box sx={{ paddingTop: '5px' }}>Organization Creation</Box>
          </Box>
        </ContainerFluid>
      </Header>
      <ContainerFluid sx={{ paddingTop: '20px' }}>
        {invitations && invitations.length > 0 && (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Typography gutterBottom variant="h3">
                  Join existing Organization
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '2rem' }}>
                  Your team mates already invited you to join an organization? You can join it by accepting the
                  invitation.
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Paper sx={{ flexGrow: 1 }}>
                  <List>
                    {invitations &&
                      invitations.map((invitation, index) => (
                        <InvitationReceivedItem invitation={invitation} divider={index !== invitations.length - 1} />
                      ))}
                    {invitations && invitations.length === 0 && (
                      <ListItem>
                        <Typography variant="body2" noWrap>
                          You have not been invited to join an organisation yet.
                        </Typography>
                      </ListItem>
                    )}
                  </List>
                </Paper>
              </Grid>
            </Grid>
            <Divider sx={{ margin: '30px 0px' }} />
          </>
        )}
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography gutterBottom variant="h3">
              Create new Organization
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '2rem' }}>
              The Organization is where recordings will be saved and the space you can invite members.
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <form onSubmit={formik.handleSubmit}>
              <Card>
                <CardContent>
                  <TextField
                    fullWidth
                    id="name"
                    label="Organization Name"
                    helperText="The name of the organization can be changed later"
                    name="name"
                    type="text"
                    variant="outlined"
                    placeholder="Acme Inc."
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </CardContent>
                <CardActions style={{ justifyContent: 'end' }}>
                  <Button variant="contained" color="primary" type="submit">
                    Create
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Grid>
        </Grid>
      </ContainerFluid>
    </>
  );
};

export default OrganizationCreationPage;
