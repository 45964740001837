import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { State } from './calendar.reducer';

export const getWeekDates = createSelector(
  ({ calendar }: { calendar: State }) => {
    return {
      startDate: dayjs(calendar.start).toDate(),
      endDate: dayjs(calendar.end).toDate(),
    };
  },
  (dates) => dates,
);

const selectors = {
  getWeekDates,
};

export default selectors;
