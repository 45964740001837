import api from 'redux/services/api';
import { Summary, SummaryEdition } from './video.types';

const summaryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateSummary: builder.mutation<Summary, SummaryEdition>({
      query({ uuid, content }) {
        return {
          url: `/summaries/${uuid}`,
          method: 'PUT',
          body: { content },
        };
      },
      invalidatesTags: ['Summary', 'Video'],
    }),
  }),
});

export const { useUpdateSummaryMutation } = summaryApi;
