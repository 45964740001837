import { Clear, Send } from '@mui/icons-material';
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MentionCardOrganization from 'components/mention/MentionCardOrganization';
import UserLine from 'components/user/UserLine';
import InvitationItem from 'pages/Settings/tabs/InvitationItem';
import MemberItem from 'pages/Settings/tabs/MemberItem';
import React from 'react';
import { useSelector } from 'react-redux';
import { appSelectors } from 'redux/App';
import { InvitationCreation, User } from 'redux/services/api.types';
import { useGetInvitationsQuery, useSendInvitationMutation } from 'redux/services/invitation';
import { useGetOrganizationQuery } from 'redux/services/organization';
import { useSearchUsersQuery } from 'redux/services/user';
import { isValidEmail } from 'services/email/isValidEmail';
import ContainerFluid from 'ui-kit/layout/ContainerFluid';

function OrganizationTab() {
  // Organization
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization } = useGetOrganizationQuery(currentOrganizationUuid);

  // Invitation list (pending)
  const { data: invitations } = useGetInvitationsQuery({ status: 'PENDING' });

  // Invitation Modal
  const [invitationModalOpen, setInvitationModalOpen] = React.useState(false);
  const handleInviteButtonClick = () => setInvitationModalOpen(true);
  const handleInvitationModalClose = () => setInvitationModalOpen(false);

  // Invitation Form
  const [searchInputValue, setSearchInputValue] = React.useState<string>('');
  const [memberToInvite, setMemberToInvite] = React.useState<InvitationCreation | null>(null);
  let { data: userResults, isLoading: searching } = useSearchUsersQuery({ q: searchInputValue });
  const [sendInvitation] = useSendInvitationMutation();
  const clearMemberToInvite = () => setMemberToInvite(null);
  const handleInvite = () => {
    if (memberToInvite !== null) {
      sendInvitation(memberToInvite);
      setMemberToInvite(null);
    }
    handleInvitationModalClose();
  };

  if (userResults && userResults.length === 0 && isValidEmail(searchInputValue)) {
    userResults = [{ email: searchInputValue }];
  }

  const optionRendering = React.useCallback((props: any, user: User) => {
    return (
      <li {...props}>
        <UserLine user={user} />
      </li>
    );
  }, []);

  // TODO: Add the Throttle to the user search input -> https://github.com/mui/material-ui/blob/v5.8.6/docs/data/material/components/autocomplete/GoogleMaps.tsx#L61-L76

  return (
    <>
      <ContainerFluid sx={{ paddingTop: '20px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography gutterBottom variant="h3">
              Organization
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper sx={{ flexGrow: 1 }}>
              <MentionCardOrganization organization={currentOrganization} />
            </Paper>
          </Grid>
        </Grid>

        <Divider sx={{ margin: '30px 0px' }} />

        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography gutterBottom variant="h3">
              Members
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '2rem' }}>
              The users of this list are part of the organization. You can invite other users to join the organization
              by clicking on the link below.
            </Typography>
            <Button variant="contained" color="primary" endIcon={<Send />} onClick={handleInviteButtonClick}>
              Invite People
            </Button>
            <Dialog open={invitationModalOpen} onClose={handleInvitationModalClose}>
              <DialogTitle>Invite a member to the Organization</DialogTitle>
              <DialogContent>
                {memberToInvite && (
                  <Paper>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={clearMemberToInvite}>
                          <Clear />
                        </IconButton>
                      }
                    >
                      <UserLine user={memberToInvite} />
                    </ListItem>
                  </Paper>
                )}
                {memberToInvite === null && (
                  <Autocomplete
                    id="invitation-autocomplete"
                    value={memberToInvite}
                    onInputChange={(event, newValue) => setSearchInputValue(newValue)}
                    renderOption={optionRendering}
                    filterOptions={(x) => x}
                    noOptionsText="No users found."
                    options={userResults || []}
                    autoHighlight // The first option is automatically highlighted
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          margin="dense"
                          label="Search by full name or email address"
                          placeholder="John Doe, john@acme.com"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {searching ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      );
                    }}
                    onChange={(event, value) => {
                      setMemberToInvite(value);
                    }}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleInvitationModalClose}>Cancel</Button>
                <Button onClick={handleInvite} data-cy="invite-confirm">
                  Invite
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper sx={{ flexGrow: 1 }}>
              <List>
                {currentOrganization &&
                  currentOrganization.members.map((member, index) => (
                    <MemberItem member={member} divider={index !== currentOrganization.members.length - 1} />
                  ))}
              </List>
            </Paper>
          </Grid>
        </Grid>

        {invitations && invitations.length > 0 && (
          <>
            <Divider sx={{ margin: '30px 0px' }} />
            <Grid container spacing={4} data-cy="invitations-section">
              <Grid item xs={12} md={4}>
                <Typography gutterBottom variant="h3">
                  Pending Invites
                </Typography>
                <Typography variant="body1">
                  Pending invitations are displayed in this list. Or you can cancel the invitation by clicking on
                  "Revoke invite".
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Paper sx={{ flexGrow: 1 }}>
                  {invitations &&
                    invitations.map((invitation, index) => {
                      return <InvitationItem invitation={invitation} divider={index !== invitations.length - 1} />;
                    })}
                </Paper>
              </Grid>
            </Grid>
          </>
        )}

        <Divider sx={{ margin: '30px 0px' }} />

        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography gutterBottom variant="h3">
              Danger zone
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="body1">
              <b>Delete this organization.</b>
              <br />
              To delete this organization, please send an email to <i>support@goelo.com</i>
              <br />
              Once deleted, it will be gone forever. Please be certain.
            </Typography>
          </Grid>
        </Grid>
      </ContainerFluid>
    </>
  );
}

export default OrganizationTab;
