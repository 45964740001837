import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import BusinessIcon from '@mui/icons-material/Business';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import TodayIcon from '@mui/icons-material/Today';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListSubheader,
  Tooltip,
} from '@mui/material';
import NavLinkItem from 'components/nav/NavLinkItem';
import OrganizationSelector from 'components/organization';
import UploadVideoModal from 'components/video/Import/UploadVideoModal';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appSelectors } from 'redux/App';
import authSelectors from 'redux/Auth/auth.selectors';
import { useGetCollectionsQuery } from 'redux/services/collection';
import { useGetOrganizationQuery } from 'redux/services/organization';
import { useGetCurrentUserQuery } from 'redux/services/user';
import { useGetTeamsQuery } from 'redux/services/team';
import Avatar from 'ui-kit/avatar/Avatar';
import Header from 'ui-kit/header/Header';
import AskInput from './components/AskInput';
import GroupIcon from '@mui/icons-material/Group';
import { styled } from '@mui/material/styles';
import tokens from 'ui-kit/tokens';
import AddIcon from '@mui/icons-material/Add';

// Add this styled component
const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '4px',
  margin: '0 8px',
  padding: '0 6px',
  height: '37px',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: tokens.colors.beige.darker,
  },
  '&:hover .add-button': {
    display: 'flex',
  },
}));

// Add this new styled component for the IconButton
const StyledIconButton = styled(IconButton)({
  padding: 2,
  display: 'none',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
});

export default function MainMenu() {
  const navigate = useNavigate();
  const organizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization } = useGetOrganizationQuery(organizationUuid);
  const isAuthenticated = useSelector(authSelectors.isAuthenticated);
  const { data: currentUser } = useGetCurrentUserQuery();
  const handleUpgrade = () => {
    navigate(`/o/${organizationUuid}/settings/plan`);
  };
  const { data: collections } = useGetCollectionsQuery();
  const { data: teams } = useGetTeamsQuery();

  const [openLists, setOpenLists] = useState({
    private: true,
    teams: true,
    organization: true,
  });

  const toggleList = (listName: keyof typeof openLists) => {
    setOpenLists((prev) => ({ ...prev, [listName]: !prev[listName] }));
  };

  const handleAddTeam = () => {
    navigate(`/o/${organizationUuid}/settings/teams`);
  };

  if (!isAuthenticated) return null;

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          overflowX: 'hidden',
          boxSizing: 'border-box',
          width: '240px',
        },
        width: '240px',
      }}
      variant="permanent"
      anchor="left"
    >
      <Header>
        <OrganizationSelector />
      </Header>
      <AskInput />
      <List sx={{ paddingTop: '16px' }}>
        {currentUser && (
          <NavLinkItem
            to={'/user'}
            icon={<Avatar src={currentUser.pictureUrl} sx={{ width: '20px', height: '20px' }} />}
            title={'User Settings'}
          />
        )}
        <NavLinkItem to={'settings'} icon={<SettingsIcon />} title={'Organization Settings'} />
      </List>
      <List sx={{ paddingTop: '10px' }}>
        <StyledListSubheader disableSticky onClick={() => toggleList('private')}>
          Private
        </StyledListSubheader>
        <Collapse in={openLists.private} timeout="auto" unmountOnExit>
          <NavLinkItem to={`/o/${organizationUuid}/calendar`} icon={<TodayIcon />} title={'Calendar'} />
          <NavLinkItem to={`/o/${organizationUuid}/private`} icon={<PlayCircleIcon />} title={'Recordings'} />
        </Collapse>
      </List>
      {teams && teams.length > 0 && (
        <List sx={{ paddingTop: '16px' }}>
          <StyledListSubheader disableSticky>
            <Box display="flex" flex={1} alignItems="center" onClick={() => toggleList('teams')}>
              Teams
            </Box>
            <Tooltip title="New teamspace" placement="bottom">
              <StyledIconButton className="add-button" size="small" onClick={handleAddTeam}>
                <AddIcon fontSize="small" />
              </StyledIconButton>
            </Tooltip>
          </StyledListSubheader>
          <Collapse in={openLists.teams} timeout="auto" unmountOnExit>
            {teams.map((team) => (
              <NavLinkItem
                key={team.uuid}
                to={`/o/${organizationUuid}/teams/${team.uuid}`}
                icon={<GroupIcon />}
                title={team.name}
              />
            ))}
          </Collapse>
        </List>
      )}
      <List sx={{ paddingTop: '16px' }}>
        <StyledListSubheader disableSticky onClick={() => toggleList('organization')}>
          {currentOrganization ? `${currentOrganization.name}` : 'Organization'}
        </StyledListSubheader>
        <Collapse in={openLists.organization} timeout="auto" unmountOnExit>
          <NavLinkItem to={`/o/${organizationUuid}/v`} icon={<BusinessIcon />} title={'Workspace'} end />
          <NavLinkItem to={`/o/${organizationUuid}/c`} icon={<VideoLibraryIcon />} title={'Collections'} end />
          {collections && (
            <Box sx={{ paddingLeft: '20px' }}>
              {collections.map((collection) => {
                return (
                  <NavLinkItem
                    key={collection.uuid}
                    to={`/o/${organizationUuid}/c/${collection.uuid}`}
                    icon={<VideoLibraryIcon />}
                    title={collection.name}
                    end
                  />
                );
              })}
            </Box>
          )}
        </Collapse>
      </List>
      <Box py={3} px={2}>
        <UploadVideoModal />
      </Box>
      <Box flexGrow={1} />
      <Box py={3} px={2}>
        {currentOrganization && currentOrganization.freePlan.free && (
          <Card>
            {currentOrganization.freePlan.reached && (
              <CardContent sx={{ textAlign: 'center' }}>No more recordings left on your Free plan.</CardContent>
            )}
            {!currentOrganization.freePlan.reached && (
              <CardContent sx={{ textAlign: 'center' }}>
                {currentOrganization.freePlan.limit - currentOrganization.freePlan.total} recordings remaining on your
                Free plan.
              </CardContent>
            )}
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AutoAwesomeIcon />}
                fullWidth
                onClick={handleUpgrade}
              >
                Upgrade
              </Button>
            </CardActions>
          </Card>
        )}
      </Box>
    </Drawer>
  );
}
