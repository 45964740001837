import { createReducer, ActionReducerMapBuilder, PayloadAction, Draft } from '@reduxjs/toolkit';

import { seekTo, setProgress } from './player.actions';
import { State } from './player.types';

const initialState: State = {
  lastSeek: { seek: 0, timestamp: 0 },
  progress: 0,
};

function setProgressReducer(state: Draft<State>, action: PayloadAction<number>) {
  state.progress = action.payload;
}
function setLastSeek(state: Draft<State>, action: PayloadAction<number>) {
  state.lastSeek = { seek: action.payload, timestamp: Date.now() };
}

export default createReducer(initialState, (builder: ActionReducerMapBuilder<State>) =>
  builder.addCase(seekTo, setLastSeek).addCase(setProgress, setProgressReducer),
);
