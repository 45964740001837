import { Business, LogoutRounded } from '@mui/icons-material';
import { Box, Button, Grid, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Paper } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import UserLine from 'components/user/UserLine';
import InvitationReceivedItem from 'pages/User/components/InvitationReceivedItem';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appActions } from 'redux/App';
import { authActions } from 'redux/Auth';
import api from 'redux/services/api';
import { useGetOrganizationsQuery } from 'redux/services/organization';
import { useGetCurrentUserQuery, useGetUserInvitationsQuery } from 'redux/services/user';
import Avatar from 'ui-kit/avatar/Avatar';
import Header from 'ui-kit/header/Header';
import ContainerFluid from 'ui-kit/layout/ContainerFluid';

const UserPage = () => {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // User Data
  const { data: currentUser } = useGetCurrentUserQuery();

  // Organization Data
  const { data: organizations } = useGetOrganizationsQuery();
  // Organization Selection handling
  function handleOrganizationSelection(value: string) {
    dispatch(appActions.currentOrganizationChanged(value)); // Redux
    navigate(`/o/${value}/v`); // Redirect
  }

  // Invitation Data
  const { data: invitations } = useGetUserInvitationsQuery();

  return (
    <>
      <Header>
        <ContainerFluid>
          <Box display="flex">
            <Box sx={{ paddingTop: '5px' }}>User Settings</Box>
            <Box flexGrow={1} />
            <Box>
              <Button
                variant="outlined"
                size="small"
                endIcon={<LogoutRounded />}
                onClick={() => {
                  dispatch(authActions.logout());
                  dispatch(api.util.resetApiState());
                }}
              >
                Logout
              </Button>
            </Box>
          </Box>
        </ContainerFluid>
      </Header>
      <ContainerFluid sx={{ paddingTop: '20px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography gutterBottom variant="h3">
              User
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            {currentUser && (
              <Paper sx={{ flexGrow: 1 }}>
                <UserLine user={currentUser} />
              </Paper>
            )}
          </Grid>
        </Grid>
        <Divider sx={{ margin: '30px 0px' }} />
        <Grid container spacing={4} data-cy="organizations-section">
          <Grid item xs={12} md={4}>
            <Typography gutterBottom variant="h3">
              Organizations
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '2rem' }}>
              Select the organization where you'd like to work today.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              endIcon={<Business />}
              onClick={() => navigate('/user/organizations/create')}
            >
              Create a new Organization
            </Button>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper sx={{ flexGrow: 1 }}>
              <List>
                {organizations &&
                  organizations.map((organization, index) => (
                    <MenuItem
                      data-cy="organization-joined"
                      key={organization.uuid}
                      divider={index !== organizations.length - 1}
                      onClick={() => {
                        handleOrganizationSelection(organization.uuid);
                      }}
                    >
                      <ListItemAvatar sx={{ minWidth: 50 }}>
                        <Avatar name={organization.name} />
                      </ListItemAvatar>
                      <ListItemText>
                        <Typography variant="body2" noWrap>
                          {organization.name}
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  ))}
                {organizations && organizations.length === 0 && (
                  <ListItem>
                    <Typography variant="body2" noWrap>
                      You have no organizations.
                    </Typography>
                  </ListItem>
                )}
              </List>
            </Paper>
          </Grid>
        </Grid>
        <Divider sx={{ margin: '30px 0px' }} />
        <Grid container spacing={4} data-cy="invitations-section">
          <Grid item xs={12} md={4}>
            <Typography gutterBottom variant="h3">
              Invitations
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '2rem' }}>
              Accept or decline invitations to join an organization.
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper sx={{ flexGrow: 1 }}>
              <List>
                {invitations &&
                  invitations.map((invitation, index) => (
                    <InvitationReceivedItem invitation={invitation} divider={index !== invitations.length - 1} />
                  ))}
                {invitations && invitations.length === 0 && (
                  <ListItem>
                    <Typography variant="body2" noWrap>
                      You have not been invited to join an organisation yet.
                    </Typography>
                  </ListItem>
                )}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </ContainerFluid>
    </>
  );
};

export default UserPage;
