import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { appSelectors } from 'redux/App';
import authSelectors from 'redux/Auth/auth.selectors';
import { VideoComment } from 'redux/services/api.types';
import { useGetOrganizationQuery } from 'redux/services/organization';
import {
  useAddCommentCollectionMutation,
  useDeleteVideoCommentMutation,
  useRemoveCommentCollectionMutation,
} from 'redux/services/video-comment';

const CommentMenu = ({ comment, setEditable }: { comment: VideoComment; setEditable: (editable: boolean) => void }) => {
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization } = useGetOrganizationQuery(currentOrganizationUuid);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Hooks
  const [deleteVideoComment] = useDeleteVideoCommentMutation();
  const currentUserUuid = useSelector(authSelectors.getUserUuid);
  // Handlers
  const handleDelete = () => {
    deleteVideoComment({ uuid: comment.uuid });
  };

  const handleEdit = () => {
    handleClose();
    setEditable(true);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Collections
  const [addCollectionMutation] = useAddCommentCollectionMutation();
  const [removeCollectionMutation] = useRemoveCommentCollectionMutation();
  const handleCollectionChange = (videoUuid: string, collectionUuid: string, checked: boolean) => {
    if (checked) {
      addCollectionMutation({ uuid: videoUuid, collectionUuid });
    } else {
      removeCollectionMutation({ uuid: videoUuid, collectionUuid });
    }
  };
  const [anchorCollectionEl, setAnchorCollectionEl] = React.useState<null | HTMLElement>(null);
  const collectionsMenuOpen = Boolean(anchorCollectionEl);
  const handleCollectionsMenuIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorCollectionEl(event.currentTarget);
  };
  const handleCollectionsMenuClose = () => {
    setAnchorCollectionEl(null);
  };

  if (comment.user.uuid !== currentUserUuid) {
    return null;
  }

  return (
    <div key={`comment-menu-${comment.uuid}`}>
      <Button id={`comment-menu-button-${comment.uuid}`} onClick={handleClick}>
        <MoreHorizIcon />
      </Button>
      <Menu
        id={`comment-menu-button-${comment.uuid}`}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
      >
        {comment.offsetEnd && (
          <>
            <MenuItem onClick={handleCollectionsMenuIconClick}>
              <ListItemIcon>
                <VideoLibraryIcon />
              </ListItemIcon>
              <ListItemText>Collections</ListItemText>
            </MenuItem>
            <Menu
              anchorEl={anchorCollectionEl}
              id="comment-collections-menu"
              open={collectionsMenuOpen}
              onClose={handleCollectionsMenuClose}
              transformOrigin={{ horizontal: 'right', vertical: 'center' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
              sx={{ minWidth: '200px' }}
              PaperProps={{
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mr: 3,
                },
              }}
            >
              <List dense>
                {currentOrganization && currentOrganization.collections.length === 0 && (
                  <ListItem>
                    <ListItemText sx={{ padding: '10px' }}>
                      <Typography variant="body1">No collection</Typography>
                    </ListItemText>
                  </ListItem>
                )}
                {currentOrganization &&
                  currentOrganization.collections.map((collection) => {
                    const isChecked = comment.collections.some(
                      (commentCollection) => commentCollection.collection.uuid === collection.uuid,
                    );
                    return (
                      <ListItem
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            onChange={(event, checked) =>
                              handleCollectionChange(comment.uuid, collection.uuid, checked)
                            }
                            checked={isChecked}
                          />
                        }
                      >
                        <ListItemButton
                          onClick={() => handleCollectionChange(comment.uuid, collection.uuid, !isChecked)}
                        >
                          <ListItemText>
                            <Typography variant="body1">{collection.name}</Typography>
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
            </Menu>
          </>
        )}
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOpenDialog}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Comment</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this comment? This cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="info" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button color="error" onClick={handleDelete} autoFocus>
              Delete comment
            </Button>
          </DialogActions>
        </Dialog>
      </Menu>
    </div>
  );
};

export default CommentMenu;
