import { all, call, spawn } from 'redux-saga/effects';
import appSagas from 'redux/App/app.sagas';
import authSagas from 'redux/Auth/auth.sagas';
import fullstorySagas from 'redux/Fullstory/fullstory.sagas';
import crispSagas from 'redux/Crisp/crisp.sagas';

const sagas = [...authSagas, ...appSagas, ...fullstorySagas];
if (process.env.NODE_ENV === 'production') {
  sagas.push(...crispSagas);
}

export default function* rootSaga() {
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(function* () {
              yield saga;
            });
            break;
          } catch (error) {
            // Sentry Error
            // captureException(error);
            console.error(error);
          }
        }
      }),
    ),
  );
}
