import { Summary } from 'redux/services/video.types';
import SummarySection from './SummarySection';

const SummariesSection = ({ summaries }: { summaries: Summary[] }) => {
  if (!summaries || summaries.length === 0) return null;

  return <SummarySection summary={summaries[0]} />;
};

export default SummariesSection;
