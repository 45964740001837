import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
} from '@mui/material';
import styled from '@mui/material/styles/styled';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import FormControl from 'ui-kit/form/FormControl/FormControl';
import ContainerFluid from '../../../ui-kit/layout/ContainerFluid';
import Input from 'ui-kit/form/Input/Input';
import GroupIcon from '@mui/icons-material/Group';
import {
  useCreateTeamMutation,
  useDeleteTeamMutation,
  useGetTeamsQuery,
  useUpdateTeamMutation,
} from '../../../redux/services/team';
import { Team } from '../../../redux/services/api.types';
import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { v4 } from 'uuid';
import tokens from '../../../ui-kit/tokens';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { appSelectors } from '../../../redux/App';
import { useGetOrganizationQuery } from '../../../redux/services/organization';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import Avatar from '../../../ui-kit/avatar/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { useGetCurrentUserQuery } from '../../../redux/services/user';

const SummaryTabStyled = styled('div')`
  .prompt-selected {
    border: 1px solid ${tokens.colors.primary.main};

    .prompt-name {
      color: ${tokens.colors.primary.main};
    }
  }

  .prompt-name {
    font-weight: bold;
    font-size: 1rem;
  }
  .prompt-role {
    font-size: 0.8rem;
    color: #666;
  }
  .prompt-user {
    font-size: 0.8rem;
    color: #666;
    font-style: italic;
  }

  .template {
    .form-header {
      padding: 8px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
    }

    .secret {
      filter: blur(2px);
      pointer-events: none;
    }

    .form-control-line {
      display: flex;
      flex-direction: row;
      gap: 16px;
      width: 100%;

      .form-control {
        flex: 2;
      }

      .form-control-hint {
        flex: 1;
        padding-top: 24px;
      }
    }
  }
`;

function TeamsTab() {
  const organizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization } = useGetOrganizationQuery(organizationUuid);
  const { data: currentUser } = useGetCurrentUserQuery();

  // Team
  const { data, isFetching } = useGetTeamsQuery();
  const [teams, setTeams] = useState<{ [key: string]: Team }>({});
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);

  const [updateTeam, { isLoading: isUpdating }] = useUpdateTeamMutation();
  const [createTeam, { isLoading: isCreating }] = useCreateTeamMutation();

  // Normalize teams
  useEffect(() => {
    if (!data) return;
    const normalized: { [key: string]: Team } = {};
    data.forEach((item) => {
      normalized[item.uuid] = item;
    });
    setTeams(normalized);
  }, [data, setTeams]);

  useEffect(() => {
    if (Object.keys(teams).length > 0 && !selectedTeamId) {
      setSelectedTeamId(Object.keys(teams)[0]);
    }
  }, [teams, selectedTeamId, setSelectedTeamId]);

  const handleTeamSelection = (uuid: string) => {
    setSelectedTeamId(uuid);
  };

  const handleTeamCreation = () => {
    const uuidV4 = v4();
    createTeam({ uuid: uuidV4, name: '', description: '', users: [] });
    setSelectedTeamId(uuidV4);
  };

  // Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Delete
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = () => {
    handleMenuClose();
    setDeleteDialogOpen(true);
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  const [deleteTeam] = useDeleteTeamMutation();

  const handleDeleteTeam = () => {
    if (!selectedTeamId) return;
    setDeleteDialogOpen(false);
    deleteTeam(selectedTeamId);
  };

  return (
    <SummaryTabStyled>
      <ContainerFluid sx={{ paddingTop: '20px' }}>
        <Box display="flex" flexDirection="row" gap={2}>
          <Box display="flex" flexDirection="column" gap={2} flex="1">
            {Object.values(teams)
              ?.sort((a: Team, b: Team) => a.id - b.id)
              .map((team: Team, index: number) => (
                <Paper
                  className={team.uuid === selectedTeamId ? 'prompt-selected' : ''}
                  key={index}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleTeamSelection(team.uuid)}
                >
                  <Box display="flex" flexDirection="column" p={2} gap={1}>
                    <div className="prompt-name">{team.name ? team.name : <i>Untitled</i>}</div>
                    <div className="prompt-role">{team.description?.split(' ').slice(0, 30).join(' ')}...</div>
                  </Box>
                </Paper>
              ))}
            <Button variant="outlined" startIcon={<AddIcon />} disabled={isCreating} onClick={handleTeamCreation}>
              Create new team
            </Button>
          </Box>
          <Box display="flex" flexDirection="column" gap={2} flex="3">
            {selectedTeamId && teams[selectedTeamId] && (
              <Formik
                initialValues={teams[selectedTeamId]}
                onSubmit={updateTeam}
                key={JSON.stringify(teams[selectedTeamId])}
              >
                {({ values, handleSubmit, handleChange, handleBlur, dirty, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <Paper className="template">
                      <div className="form-header">
                        <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                          <GroupIcon />
                          <b>Team Edition</b>
                        </Box>
                        <Box display="flex" flexDirection="row" gap={1}>
                          <LoadingButton
                            variant="contained"
                            size="small"
                            type="submit"
                            startIcon={<SaveIcon />}
                            loading={isUpdating}
                            disabled={!dirty || isFetching}
                            loadingPosition="start"
                          >
                            Save
                          </LoadingButton>
                          <IconButton size="small" color="info" aria-label="Edit name" onClick={handleMenuIconClick}>
                            <MoreHorizIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            id="video-menu"
                            open={open}
                            onClose={handleMenuClose}
                            onClick={handleMenuClose}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            sx={{ minWidth: '200px' }}
                            PaperProps={{
                              sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '&:before': {
                                  content: '""',
                                  display: 'block',
                                  position: 'absolute',
                                  top: 0,
                                  right: 11,
                                  width: 10,
                                  height: 10,
                                  bgcolor: 'background.paper',
                                  transform: 'translateY(-50%) rotate(45deg)',
                                  zIndex: 0,
                                },
                              },
                            }}
                          >
                            {selectedTeamId && !teams[selectedTeamId].isDefault && (
                              <MenuItem onClick={handleDeleteDialogOpen}>
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText>Delete</ListItemText>
                              </MenuItem>
                            )}
                            {selectedTeamId && teams[selectedTeamId].isDefault && (
                              <MenuItem disabled>
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText>You can't delete the default Team</ListItemText>
                              </MenuItem>
                            )}
                          </Menu>
                          <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                            <DialogTitle sx={{ minWidth: '400px' }}>Delete Team?</DialogTitle>
                            <DialogContent>
                              <p>This team will no longer be available.</p>
                            </DialogContent>
                            <DialogActions>
                              <Button color="info" onClick={handleDeleteDialogClose}>
                                Cancel
                              </Button>
                              <Button variant="contained" onClick={handleDeleteTeam}>
                                Delete
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Box>
                      </div>
                      <Box display="flex" flexDirection="column" p={2} alignItems="flex-start" width="100%">
                        <FormControl fullWidth label="Team Name">
                          <Input
                            autoFocus
                            autoComplete="off"
                            fullWidth
                            placeholder="Sales Team"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                        <FormControl fullWidth label="Description">
                          <Input
                            autoComplete="off"
                            fullWidth
                            multiline
                            minRows="3"
                            placeholder="Describe the team's purpose and responsibilities..."
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                          />
                        </FormControl>
                        <FormControl fullWidth label="Users">
                          <List
                            sx={{
                              width: '100%',
                              border: '1px solid #e0e0e0',
                              borderRadius: '4px',
                              marginY: '4px',
                            }}
                          >
                            {currentOrganization?.members.map((member) => (
                              <ListItem key={member.user.uuid} disablePadding>
                                <FormControlLabel
                                  control={
                                    <Tooltip
                                      placement="left"
                                      title={
                                        currentUser && member.user.uuid === currentUser.uuid
                                          ? "You can't leave the team"
                                          : ''
                                      }
                                    >
                                      <span>
                                        <Checkbox
                                          checked={values.users.includes(member.user.uuid)}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          name="users"
                                          value={member.user.uuid}
                                          disabled={!currentUser || member.user.uuid === currentUser.uuid}
                                        />
                                      </span>
                                    </Tooltip>
                                  }
                                  label={
                                    <Box display="flex" alignItems="center" width="100%" pr={2}>
                                      <ListItemAvatar>
                                        <Avatar alt={member.user.name} src={member.user.pictureUrl} />
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={member.user.name}
                                        secondary={
                                          <>
                                            {member.user.email}
                                            {currentUser && member.user.uuid === currentUser.uuid && ' (You)'}
                                          </>
                                        }
                                      />
                                    </Box>
                                  }
                                  labelPlacement="start"
                                  sx={{
                                    margin: 0,
                                    padding: '5px 10px',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    '& .MuiFormControlLabel-label': {
                                      flex: 1,
                                    },
                                  }}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </FormControl>
                      </Box>
                    </Paper>
                  </form>
                )}
              </Formik>
            )}
          </Box>
        </Box>
      </ContainerFluid>
    </SummaryTabStyled>
  );
}

export default TeamsTab;
