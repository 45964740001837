import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from 'redux/Auth';
import { AuthParam } from 'redux/Auth/auth.types';

/**
 * Example
 * ```
 * const { askGoogleCode } = useGoogleAskCode({
 *   scope:
 *     'email profile openid https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/calendar.readonly',
 * });
 * ```
 * @param authParam
 */
const useGoogleAskCode = (authParam?: AuthParam) => {
  const dispatch = useDispatch();

  const askGoogleCode = useCallback(() => {
    dispatch(authActions.logout());
    dispatch(authActions.googleCodeAsked(authParam));
  }, [authParam, dispatch]);

  return { askGoogleCode };
};

export default useGoogleAskCode;
