import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import MemoryIcon from '@mui/icons-material/Memory';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { Badge, Box, IconButton, InputBase, Paper, styled, Typography } from '@mui/material';
import { useChat } from 'ai/react';
import MentionCardUser from 'components/mention/MentionCardUser';
import { apiConfig } from 'config';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import appSelectors from 'redux/App/app.selectors';
import { authSelectors } from 'redux/Auth';
import { useGetCurrentUserQuery } from 'redux/services/user';
import { useVectorizeMutation } from 'redux/services/video';
import { Video } from 'redux/services/video.types';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import goeloLogo from './logo-goelo-square-48.png';

type VideoChatProps = {
  video: Video;
};

const VideoChatStyled = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;

  .simplebar-scrollbar::before {
    background-color: #78bb7b;
  }

  .chat-messages {
    display: flex;
    flex-direction: column;

    padding: 10px;
    overflow-y: auto;

    .message + .message {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }

    .message-content {
      padding: 0 10px 10px 10px;
      white-space: pre-wrap;
    }
  }

  .chat-form {
    height: 70px;
    padding: 10px;

    .awesome-container {
      overflow: hidden;
      position: relative;

      .awesome-border {
        position: relative;

        display: flex; /* Use flexbox to center content */
        align-items: center; /* Center vertically */
        justify-content: center;

        margin: 2px;

        &::before {
          content: '';
          display: block;
          position: absolute;
          padding-bottom: 110%;
          width: 110%;
          top: -155px;
          left: -5%;
          background-image: conic-gradient(
            transparent,
            transparent,
            transparent,
            transparent,
            transparent,
            transparent,
            #29b6f6,
            #ffba6d
          );
          animation: awesome 4s linear infinite;
        }
      }
    }

    &.chat-form-loading {
      .awesome-container {
        .awesome-border {
          &::before {
            background-image: conic-gradient(
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              transparent,
              rgba(237, 239, 242, 0.46)
            );
            animation: awesome 1s linear infinite;
          }
        }
      }
    }
  }

  @keyframes awesome {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const VideoChat = ({ video }: VideoChatProps) => {
  const accessToken = useSelector(authSelectors.getAccessToken);
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: user } = useGetCurrentUserQuery();
  const [vectorizeVideo, { isLoading: vectorizing }] = useVectorizeMutation();

  const { messages, handleSubmit, input, handleInputChange, isLoading } = useChat({
    api: `${apiConfig.entrypoint}/ai/chat`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'x-app-organization-uuid': currentOrganizationUuid || '',
    },
    body: {
      videoUuid: video.uuid,
    },
  });

  const containerRef = useRef<Element>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messages]);

  if (!user) return null;

  return (
    <VideoChatStyled>
      {!video.vectorized && (
        <Box width="100%" p="100px 10px" textAlign="center">
          <Typography sx={{ padding: '0 20px 40px 20px', color: '#fff' }} variant="body1">
            Analyzing this meeting
            <br />
            unlocks the valuable Insights.
          </Typography>
          <Badge badgeContent="Beta" color="secondary">
            <LoadingButton
              variant="outlined"
              loading={vectorizing}
              loadingPosition="start"
              startIcon={<MemoryIcon />}
              onClick={() => {
                vectorizeVideo({ uuid: video.uuid });
              }}
            >
              Analyze this meeting
            </LoadingButton>
          </Badge>
        </Box>
      )}
      {video.vectorized && (
        <>
          <SimpleBar className="chat-messages" scrollableNodeProps={{ ref: containerRef }}>
            {messages.length > 0 && (
              <Paper>
                {messages.map((message, i) => (
                  <div className="message">
                    {message.role === 'user' && (
                      <div key={i}>
                        <MentionCardUser user={{ name: user.name, pictureUrl: user.pictureUrl }} />
                        <div className="message-content">{message.content}</div>
                      </div>
                    )}
                    {message.role === 'assistant' && (
                      <div key={i}>
                        <MentionCardUser
                          avatarBackgroundColor="secondary"
                          user={{ name: 'Goelo Assistant', pictureUrl: goeloLogo }}
                        />
                        <div className="message-content">{message.content}</div>
                      </div>
                    )}
                  </div>
                ))}
              </Paper>
            )}
          </SimpleBar>
          <div className={`chat-form ${isLoading ? 'chat-form-loading' : ''}`}>
            <div className="awesome-container">
              <div className="awesome-border">
                <Paper
                  component="form"
                  sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', zIndex: 10 }}
                  onSubmit={handleSubmit}
                >
                  <IconButton sx={{ p: '10px' }} aria-label="Awesome Icon" disabled>
                    <AutoAwesomeIcon />
                  </IconButton>
                  <InputBase
                    sx={{ flex: 1 }}
                    placeholder={isLoading ? 'Generating' : 'Ask a question'}
                    inputProps={{ 'aria-label': 'Ask a question' }}
                    name="prompt"
                    value={input}
                    onChange={handleInputChange}
                    id="input"
                    disabled={isLoading}
                    autoComplete="off"
                  />
                  <IconButton type="submit" sx={{ p: '10px' }} aria-label="Send" disabled={isLoading}>
                    <SendIcon />
                  </IconButton>
                </Paper>
              </div>
            </div>
          </div>
        </>
      )}
    </VideoChatStyled>
  );
};

export default VideoChat;
