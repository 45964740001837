import { Button, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import appSelectors from 'redux/App/app.selectors';
import { useImportGoogleDriveFileMutation } from 'redux/services/drive';
import { useGetOrganizationQuery } from 'redux/services/organization';
import { useCountVideosQuery } from 'redux/services/video';
import { GoogleDriveFile } from 'redux/services/video.types';

type ImportFileButtonProps = {
  uuid: string;
  onResult?: (file: GoogleDriveFile) => void;
};

const ImportFileButton = ({ uuid, onResult }: ImportFileButtonProps) => {
  // Plan
  const [quotaAvailable, setQuotaAvailable] = useState<string>('loading');
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization, isSuccess: currentOrganizationSuccess } =
    useGetOrganizationQuery(currentOrganizationUuid);
  const { data: countVideos, isSuccess: countVideosSuccess } = useCountVideosQuery();
  useEffect(() => {
    if (currentOrganizationSuccess && countVideosSuccess) {
      if (currentOrganization.subscriptions.length > 0) {
        setQuotaAvailable('yep');
      } else {
        if (countVideos.overLimit) {
          setQuotaAvailable('nop');
        } else {
          setQuotaAvailable('yep');
        }
      }
    }
  }, [countVideos, countVideosSuccess, currentOrganization, currentOrganizationSuccess]);
  // Import
  const [importDriveFile, importResult] = useImportGoogleDriveFileMutation();
  useEffect(() => {
    if (importResult.isSuccess && onResult) {
      onResult(importResult.data);
    }
  }, [importResult, onResult]);

  return (
    <>
      {quotaAvailable === 'yep' && (
        <Button
          variant="contained"
          onClick={() => {
            importDriveFile({ uuid });
          }}
        >
          Import
        </Button>
      )}
      {quotaAvailable === 'nop' && (
        <Tooltip
          title={
            <>
              You have reached your quota of 20 videos.
              <br />
              Please upgrade your plan to import more videos.
            </>
          }
          placement="top"
          style={{ cursor: 'not-allowed' }}
        >
          <Button variant="contained" color="info">
            Import
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default ImportFileButton;
