import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appSelectors } from 'redux/App';
import { useDeleteCollectionMutation, useRenameCollectionMutation } from 'redux/services/collection';
import { Collection } from 'redux/services/video.types';

// write VideoSettingsButtonProps type
type CollectionSettingsButtonProps = {
  collection?: Collection;
};

const CollectionSettingsButton = ({ collection }: CollectionSettingsButtonProps) => {
  // Global
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const navigate = useNavigate();

  // Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Rename
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const handleRenameDialogOpen = () => {
    setNewCollectionName(collection?.name ?? null);
    handleMenuClose();
    setRenameDialogOpen(true);
  };
  const handleRenameDialogClose = () => {
    setRenameDialogOpen(false);
  };
  const [newCollectionName, setNewCollectionName] = useState<string | null>(null);
  const handleNewCollectionNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCollectionName(event.target.value || null);
  };
  const [renameCollection] = useRenameCollectionMutation();
  const handleRenameCollection = () => {
    if (collection && collection.uuid && newCollectionName)
      renameCollection({ uuid: collection.uuid, name: newCollectionName });
    handleRenameDialogClose();
  };

  // Delete
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = () => {
    handleMenuClose();
    setDeleteDialogOpen(true);
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  const [deleteCollection] = useDeleteCollectionMutation();

  const handleDeleteVideo = () => {
    if (collection && collection.uuid) {
      deleteCollection(collection.uuid);
      navigate(`/o/${currentOrganizationUuid}/c`);
    }
  };

  if (!collection) return null;
  return (
    <>
      <IconButton size="small" color="info" aria-label="Edit name" onClick={handleMenuIconClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="video-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{ minWidth: '200px' }}
        PaperProps={{
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          },
        }}
      >
        <MenuItem onClick={handleRenameDialogOpen}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteDialogOpen}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog open={renameDialogOpen} onClose={handleRenameDialogClose}>
        <DialogTitle sx={{ minWidth: '400px' }}>Rename Collection as...</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            onChange={handleNewCollectionNameChange}
            value={newCollectionName}
          />
        </DialogContent>
        <DialogActions>
          <Button color="info" onClick={handleRenameDialogClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleRenameCollection}>
            Rename
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle sx={{ minWidth: '400px' }}>Delete Collection?</DialogTitle>
        <DialogContent>
          <p>This collection will no longer be available in Goelo.</p>
        </DialogContent>
        <DialogActions>
          <Button color="info" onClick={handleDeleteDialogClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleDeleteVideo}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CollectionSettingsButton;
