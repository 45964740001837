import styled from '@mui/material/styles/styled';
import React from 'react';

type IconStyledProps = {
  shape?: 'circle' | 'square' | 'rounded';
  backgroundColor?: string;
};

const IconStyled = styled('div', { shouldForwardProp: (prop) => prop !== 'backgroundColor' })<IconStyledProps>`
  padding: 12px 10px;
  width: 60px;
  display: flex;

  .mention-card-avatar-background {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;

    background-color: ${(props) => props.backgroundColor ?? props.theme.palette.primary.light};
    border-radius: ${(props) => (props.shape === 'circle' ? `50%` : '5px')};
  }

  .MuiSvgIcon-root {
    font-size: 2.5rem;
  }
`;

function Icon({ children, shape, backgroundColor }: React.PropsWithChildren<IconStyledProps>) {
  return (
    <IconStyled shape={shape} backgroundColor={backgroundColor}>
      <div className="mention-card-avatar-background">{children}</div>
    </IconStyled>
  );
}

export default Icon;
