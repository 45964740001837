import ClearIcon from '@mui/icons-material/Clear';
import { Button, ButtonGroup } from '@mui/material';
import SalesforceRecordIcon from 'components/salesforce/SalesforceRecordIcon/SalesforceRecordIcon';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SalesforceRecord } from 'redux/services/api.types';
import { useRemoveSalesforceRecordMutation } from 'redux/services/video';
import { Video } from 'redux/services/video.types';
import sidebarActions from 'redux/Sidebar/sidebar.actions';

type SalesforceRecordButtonProps = {
  salesforceRecord: SalesforceRecord;
  video: Video;
};

const SalesforceRecordButton = ({ salesforceRecord, video }: SalesforceRecordButtonProps) => {
  const dispatch = useDispatch();
  const [removeRecord] = useRemoveSalesforceRecordMutation();
  const handleRemove = useCallback(() => {
    removeRecord({
      uuid: video.uuid,
      salesforceRecordUuid: salesforceRecord.uuid,
    });
  }, [removeRecord, video.uuid, salesforceRecord.uuid]);

  const handleClick = useCallback(() => {
    dispatch(sidebarActions.recordSelected(salesforceRecord));
  }, [dispatch, salesforceRecord]);

  return (
    <ButtonGroup>
      <Button
        key={salesforceRecord.id}
        variant="outlined"
        color="info"
        startIcon={<SalesforceRecordIcon type={salesforceRecord.type} />}
        onClick={handleClick}
      >
        {salesforceRecord.name}
      </Button>
      <Button size="small" color="info" onClick={handleRemove}>
        <ClearIcon fontSize="small" />
      </Button>
    </ButtonGroup>
  );
};

export default SalesforceRecordButton;
