import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, IconButton, styled } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import SalesforceRecordField from 'components/salesforce/SalesforcePanel/SalesforceRecordField';
import SalesforceRecordIcon from 'components/salesforce/SalesforceRecordIcon/SalesforceRecordIcon';
import { Form, Formik, FormikProps } from 'formik';
import useOpenInSalesforce from 'hooks/salesforce/useOpenInSalesforce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appSelectors from 'redux/App/app.selectors';
import {
  useLazySalesforceSObjectSuggestionsQuery,
  useSalesforceDescribeSObjectQuery,
  useSalesforceRecordSObjectQuery,
  useUpdateSObjectMutation,
} from 'redux/services/salesforce';
import sidebarActions from 'redux/Sidebar/sidebar.actions';
import sidebarSelectors from 'redux/Sidebar/sidebar.selectors';
import SimpleBar from 'simplebar-react';
import ContainerFluid from 'ui-kit/layout/ContainerFluid';

type Values = { [fieldName: string]: any };

const SalesforcePanelDesigned = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f5f5f5;

  .salesforce-form {
    height: 100%;
    display: flex;
    flex-direction: column;

    // workaround to fix simplebar scrollbar
    .simplebar-placeholder {
      display: none;
    }
  }
  .salesforce-form-content {
    flex: 1;
    overflow-y: auto;
  }

  .salesforce-title {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    overflow: hidden;
    flex: 1;

    .salesforce-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-row: 1;
    }
  }
  .salesforce-actions {
    align-self: center;
  }
`;

const SalesforcePanel = () => {
  const formikRef = useRef<FormikProps<Values>>(null);
  const dispatch = useDispatch();
  const [updateSObject, updateSObjectResult] = useUpdateSObjectMutation();

  /**
   * UI
   */
  const handleClear = useCallback(() => {
    dispatch(sidebarActions.close());
  }, [dispatch]);
  const selectedRecord = useSelector(sidebarSelectors.getSelectedRecord);
  const currentVideoUuid = useSelector(appSelectors.getCurrentVideoUuid);

  /**
   * Formik
   */
  const [initialValues, setInitialValues] = useState<Values>({});
  const handleSubmit = useCallback(
    (values: Values) => {
      updateSObject({
        uuid: selectedRecord?.uuid || '',
        data: cleanValues(initialValues, values),
      });
    },
    [initialValues, selectedRecord?.uuid, updateSObject],
  );
  useEffect(() => {
    if (updateSObjectResult.isError && formikRef.current) {
      const errorResponse = updateSObjectResult.error as FetchBaseQueryError;
      const { fields, message } = errorResponse.data as any;
      const errors: Values = {};
      fields.forEach((field: string) => {
        errors[field] = message;
      });
      formikRef.current.setErrors(errors);
    }
  }, [updateSObjectResult]);

  /**
   * Salesforce API
   */
  const {
    data: sobject,
    isLoading: sobjectLoading,
    isFetching: sobjectFetching,
  } = useSalesforceRecordSObjectQuery(selectedRecord?.uuid, {
    skip: !selectedRecord,
  });
  const { data: metadata, isLoading: metadataLoading } = useSalesforceDescribeSObjectQuery(selectedRecord?.type || '', {
    skip: !selectedRecord,
  });
  const [fields, setFields] = useState([]);

  /**
   * Suggestions
   */
  const [suggestions, setSuggestions] = useState<Record<string, string | boolean>>({});
  const [suggestionTrigger, { data: suggestionsResponse, isLoading: suggestionsLoading }] =
    useLazySalesforceSObjectSuggestionsQuery(selectedRecord?.uuid);
  // Call
  const handleSuggestions = useCallback(() => {
    if (!selectedRecord?.uuid || !currentVideoUuid) return;
    suggestionTrigger({ uuid: selectedRecord?.uuid, videoUuid: currentVideoUuid });
  }, [currentVideoUuid, selectedRecord?.uuid, suggestionTrigger]);
  // State management
  useEffect(() => {
    if (!suggestionsResponse) return;
    setSuggestions(suggestionsResponse);
  }, [suggestionsResponse]);
  useEffect(() => {
    setSuggestions({});
  }, [selectedRecord]);

  /**
   * Salesforce header
   */
  const openInSalesforce = useOpenInSalesforce();
  const handleOpenInSalesforce = useCallback(() => {
    openInSalesforce(sobject.Id);
  }, [openInSalesforce, sobject]);

  useEffect(() => {
    if (!metadata?.fields || !sobject) {
      setFields([]);
      return;
    }
    if (metadata?.fields)
      setFields(
        metadata.fields.filter((field: { type: string }) => {
          return field.type === 'string' || field.type === 'boolean' || field.type === 'picklist';
        }),
      );
    if (metadata?.fields) {
      // Initialize formik values
      const values = {};
      metadata.fields.forEach((field: { name: string }) => {
        // @ts-ignore
        values[field.name] = sobject[field.name];
      });
      setInitialValues(values);
    }
  }, [metadata, selectedRecord, sobject]);

  // Returns

  if (!selectedRecord) return null;

  return (
    <SalesforcePanelDesigned>
      <Formik
        key={JSON.stringify(initialValues)} // workaround to reset formik values
        initialValues={initialValues}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(props) => (
          <Form className="salesforce-form">
            <Box display="flex" flexDirection="row" padding="10px" gap="10px" width="100%">
              <IconButton size="small" onClick={handleClear}>
                <CloseIcon />
              </IconButton>
              <div className="salesforce-title">
                <SalesforceRecordIcon type={selectedRecord.type} />
                <div className="salesforce-name">{selectedRecord.name}</div>
              </div>
              <div className="salesforce-actions">
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="small"
                  disabled={!props.dirty || sobjectFetching}
                  loading={updateSObjectResult.isLoading}
                >
                  Sync
                </LoadingButton>
              </div>
            </Box>
            <Divider />
            <SimpleBar className="salesforce-form-content">
              <ContainerFluid>
                <Box display="flex" flexDirection="row" gap="10px">
                  <LoadingButton
                    variant="outlined"
                    color="info"
                    size="small"
                    startIcon={<AutoAwesomeIcon fontSize="small" />}
                    onClick={handleSuggestions}
                    loading={suggestionsLoading}
                    loadingPosition="start"
                  >
                    Auto-fill
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    color="info"
                    size="small"
                    endIcon={<OpenInNewIcon fontSize="small" />}
                    onClick={handleOpenInSalesforce}
                  >
                    Open in Salesforce
                  </Button>
                </Box>
              </ContainerFluid>
              <Divider />
              {!metadataLoading && !sobjectLoading && (
                <ContainerFluid>
                  {fields.map((field: { name: string; label: string }) => (
                    <SalesforceRecordField
                      key={`${sobject.Id}-${field.name}`}
                      field={field}
                      suggestion={suggestions[field.name]}
                    />
                  ))}
                </ContainerFluid>
              )}
            </SimpleBar>
          </Form>
        )}
      </Formik>
    </SalesforcePanelDesigned>
  );
};

export default SalesforcePanel;

const cleanValues = (initialValue: Values, values: Values) => {
  const cleanValues: Values = {};
  Object.keys(values).forEach((key) => {
    if (initialValue[key] !== values[key]) {
      cleanValues[key] = values[key];
    }
  });
  return cleanValues;
};
