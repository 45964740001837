import { Alert, Link, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useQuery from 'hooks/routing/useQuery';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { authActions, authSelectors } from 'redux/Auth';
import GoogleLogo from 'ui-kit/logo/google/google.svg';
import MicrosoftLogo from 'ui-kit/logo/microsoft/microsoft.svg';
import tokens from 'ui-kit/tokens';

function LoginPage() {
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useSelector(authSelectors.isAuthenticated);
  const loggingErrorMessage = useSelector(authSelectors.getLoggingErrorMessage);

  const code = query.get('code');
  const stateQueryParam = query.get('state');

  // FIXME: This timeout allow to display the animation. But if it removes, the dispatch loginWithGoogleCode is not called
  useEffect(() => {
    if (isAuthenticated) {
      setTimeout(() => {
        if (stateQueryParam) {
          const state = JSON.parse(stateQueryParam);
          if (state.redirectionUri) {
            navigate(state.redirectionUri);
          }
        } else {
          navigate('/login/redirect');
        }
      }, 2000);
    }

    if (code) {
      if (isAuthenticated) return; // Avoid to call login twice

      if (location.pathname.includes('/microsoft')) {
        dispatch(authActions.loginWithMicrosoftCode({ code }));
      } else {
        dispatch(authActions.loginWithGoogleCode({ code }));
      }
    }
  }, [code, dispatch, isAuthenticated, location.pathname, navigate, stateQueryParam]);

  const askGoogleCode = useCallback(() => {
    dispatch(authActions.googleCodeAsked());
  }, [dispatch]);

  const askMicrosoftCode = useCallback(() => {
    dispatch(authActions.microsoftCodeAsked());
  }, [dispatch]);

  return (
    <Box display="flex" flexDirection="row">
      <Box
        width={isAuthenticated ? '240px' : '50%'}
        sx={{
          backgroundColor: isAuthenticated ? tokens.colors.beige.main : tokens.colors.primary.light,
          transition: 'width 2s, background-color 2s',
        }}
      />
      <Box
        flex={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        flexDirection="column"
        sx={{ opacity: isAuthenticated ? 0 : 1, transition: 'opacity 2s' }}
      >
        <Typography variant="h1">Welcome to Goelo</Typography>
        <Typography variant="subtitle1" sx={{ marginBottom: 10 }}>
          Login to your professional account.
        </Typography>
        {loggingErrorMessage && (
          <Alert sx={{ marginBottom: 1, width: '260px' }} severity="warning">
            {loggingErrorMessage}
          </Alert>
        )}
        <Button
          autoFocus
          focusRipple={false}
          variant="outlined"
          color="success"
          size="large"
          onClick={askGoogleCode}
          startIcon={<Avatar sx={{ img: { width: '80%', height: '70%' } }} src={GoogleLogo} variant="square" />}
          sx={{ marginBottom: 1, width: '260px' }}
        >
          Login with Google
        </Button>

        <Button
          autoFocus
          focusRipple={false}
          variant="outlined"
          color="success"
          size="large"
          onClick={askMicrosoftCode}
          startIcon={<Avatar sx={{ img: { width: '70%', height: '70%' } }} src={MicrosoftLogo} variant="square" />}
          sx={{ marginTop: 1, marginBottom: 10, width: '260px' }}
        >
          Login with Microsoft
        </Button>

        <Typography variant="body2" color="text.secondary">
          By using Goelo you agree to the
          <br />
          <Link target="_blank" href="https://www.goelo.com/legal/terms-of-use">
            Terms of Use
          </Link>{' '}
          and{' '}
          <Link target="_blank" href="https://www.goelo.com/legal/privacy">
            Privacy Policy
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}

export default LoginPage;
