import VideoCard from '../VideoCard/VideoCard';
import CalendarCard from '../CalendarCard/CalendarCard';
import { Box } from '@mui/material';
import React from 'react';
import { Video } from '../../../redux/services/video.types';
import { appSelectors } from '../../../redux/App';
import { useSelector } from 'react-redux';
import { useGetOrganizationQuery } from '../../../redux/services/organization';

const VideosGalleryView = ({ videos = [] }: { videos?: Video[] }) => {
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization } = useGetOrganizationQuery(currentOrganizationUuid);

  if (!currentOrganization) return null;

  return (
    <Box display="flex" justifyContent="left" flexWrap="wrap" gap="10px">
      {videos &&
        videos.map((video) => {
          return (
            <VideoCard key={video.uuid} video={video} freePlanLimitDate={currentOrganization.freePlan.limitDate} />
          );
        })}
      {videos && videos.length === 0 && <CalendarCard />}
    </Box>
  );
};

export default VideosGalleryView;
