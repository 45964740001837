import { createSelector } from '@reduxjs/toolkit';
import { State } from 'redux/Sidebar/sidebar.reducer';

const getSelectedVideoComment = createSelector(
  ({ sidebar }: { sidebar: State }) => {
    return sidebar.selectedVideoComment;
  },
  (selectedVideoComment) => selectedVideoComment,
);

const getSelectedVideoChat = createSelector(
  ({ sidebar }: { sidebar: State }) => {
    return sidebar.selectedVideoChat;
  },
  (selectedVideoChat) => selectedVideoChat,
);

const getSelectedRecord = createSelector(
  ({ sidebar }: { sidebar: State }) => {
    return sidebar.selectedRecord;
  },
  (selectedRecord) => selectedRecord,
);

const isOpen = createSelector(
  getSelectedVideoComment,
  getSelectedVideoChat,
  getSelectedRecord,
  (selectedVideoComment, selectedVideoChat, selectedRecord) =>
    selectedVideoComment !== null || selectedVideoChat !== null || selectedRecord !== null,
);

const selectors = { getSelectedVideoComment, getSelectedVideoChat, getSelectedRecord, isOpen };

export default selectors;
