import axios, { AxiosInstance, AxiosPromise, AxiosResponse } from 'axios';
import { apiConfig } from 'config';

/**
 * HttpClient
 *
 * Inspiration https://github.com/bonjour/demox/blob/staging/packages/redux-kit/src/services/network/Toufik/Toufik.ts
 */
export class HttpClient {
  private client: AxiosInstance;

  constructor() {
    // TODO: Get the baseURL from the config
    this.client = axios.create({
      baseURL: apiConfig.entrypoint,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public post = <T, R = AxiosResponse<T>>(url: string, data: any): AxiosPromise<R> => {
    return this.client.post(url, data);
  };
}
