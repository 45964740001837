import { styled } from '@mui/material';
import tokens from 'ui-kit/tokens';

export const StyledFilterMenu = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 8px;

  .menu-name {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #111827;
  }

  .menu-button {
    padding: 4px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  .menu-item-checkbox {
    padding: 2px;
  }

  .menu-form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .menu-form-control {
    margin-left: -4px;
  }

  .menu-item-button {
    width: 100%;
    font-weight: normal;
    justify-content: flex-start;
    text-align: left;
    color: ${tokens.colors.primary.contrastText};

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .menu-item-button-active {
    background-color: rgba(0, 0, 0, 0.04);
    font-weight: bold;
  }

  .calendar-shortcut {
    min-width: 160px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  .MuiPickersArrowSwitcher-root {
    padding: 0 16px 4px 16px;
  }
`;
