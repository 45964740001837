import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { SalesforceRecordFieldProps } from 'components/salesforce/salesforce.types';
import React, { useId } from 'react';

const SalesforceRecordFieldBoolean = ({ name, label, value, disabled, InputProps }: SalesforceRecordFieldProps) => {
  const id = useId();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (InputProps && InputProps.onChange) {
      InputProps.onChange(event);
    }
  };

  return (
    <FormControl fullWidth>
      <FormLabel htmlFor={id} sx={{ padding: '8px 0 1px 0', fontSize: '11px' }}>
        {label}
      </FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              checked={!!value as boolean}
              onChange={handleChange}
              disabled={disabled}
              size="small"
              sx={{
                padding: '3px 9px',
                '&.Mui-disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'unset',
                },
              }}
            />
          }
          label=""
        />
      </FormGroup>
    </FormControl>
  );
};

export default SalesforceRecordFieldBoolean;
