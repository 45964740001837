import { Area, AreaChart, Bar, BarChart, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Video } from '../../../redux/services/video.types';
import { Box, Card, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import duration from 'dayjs/plugin/duration';
import React, { useState } from 'react';
import BarChartIcon from '@mui/icons-material/BarChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import Divider from '@mui/material/Divider';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

dayjs.extend(duration);
dayjs.extend(isBetween);

type VideosGraphProps = {
  filteredVideos: Video[];
  periodFilter: { startDate: Dayjs | null; endDate: Dayjs | null };
  display?: 'bar' | 'area';
};

function formatDuration(milliseconds: number) {
  const seconds = milliseconds / 1000;
  const dur = dayjs.duration(seconds, 'seconds');

  const hours = Math.floor(dur.asHours());
  const minutes = dur.minutes();
  const secs = dur.seconds();

  let result = '';

  if (hours > 0) result += `${hours} h `;

  if (minutes > 0) result += `${minutes} min `;

  if ((secs > 0 && minutes < 1) || result === '') result += `${secs} s`;

  return result.trim();
}

function formatCustomDate(date: string | number | Date) {
  const inputDate = dayjs(date);
  const now = dayjs();

  let formattedDate = '';

  if (inputDate.isToday()) {
    formattedDate = `Today`;
  } else if (inputDate.isYesterday()) {
    formattedDate = `Yesterday`;
  } else if (inputDate.year() === now.year()) {
    formattedDate = `${inputDate.format('ddd, MMM D')}`;
  } else {
    formattedDate = `${inputDate.format('MMM D, YYYY')}`;
  }

  return formattedDate;
}

function defineStartDate(startDate: Dayjs | null, videos: { createdAt: Date }[]): Dayjs {
  if (startDate) return startDate;
  if (videos.length > 0) return dayjs(videos[0].createdAt);

  return dayjs().subtract(6, 'day');
}

function defineEndDate(endDate: Dayjs | null, videos: { createdAt: Date }[]): Dayjs {
  if (endDate) return endDate;
  if (videos.length > 1) return dayjs(videos[videos.length - 1].createdAt);

  return dayjs();
}

function generateChartData(videos: Video[], startDate: Dayjs | null, endDate: Dayjs | null) {
  const videosByCreatedAt = videos
    .map((video) => ({ createdAt: video.createdAt }))
    .sort((a, b) => dayjs(a.createdAt).diff(dayjs(b.createdAt)));
  const seriesCurrentStartDate = defineStartDate(startDate, videosByCreatedAt);
  const seriesCurrentEndDate = defineEndDate(endDate, videosByCreatedAt);
  const diffDays = seriesCurrentEndDate.diff(seriesCurrentStartDate, 'day');
  const seriesPreviousStartDate = seriesCurrentStartDate.subtract(diffDays + 1, 'day');
  const seriesPreviousEndDate = seriesCurrentStartDate.subtract(1, 'day');

  const dateMap: { [key: string]: number } = {};
  const dateMapPrevious: { [key: string]: number } = {};

  // Initialize dateMap with zero recordings for each day in the range of the current period
  for (
    let date = seriesCurrentStartDate;
    date.isBefore(seriesCurrentEndDate, 'day') || date.isSame(seriesCurrentEndDate, 'day');
    date = date.add(1, 'day')
  ) {
    const dateKey = date.format('YYYY-MM-DD');
    dateMap[dateKey] = 0;
    dateMapPrevious[dateKey] = 0;
  }

  // Group videos by day and count recordings for both current and previous periods
  videos.forEach((video) => {
    const videoDate = dayjs(video.createdAt);
    if (videoDate.isBetween(seriesCurrentStartDate, seriesCurrentEndDate, 'day', '[]')) {
      const dateKey = videoDate.format('YYYY-MM-DD');
      dateMap[dateKey] = (dateMap[dateKey] || 0) + 1;
    } else if (videoDate.isBetween(seriesPreviousStartDate, seriesPreviousEndDate, 'day', '[]')) {
      const shiftedDate = videoDate.add(diffDays + 1, 'day');
      const dateKey = shiftedDate.format('YYYY-MM-DD');
      if (dateKey in dateMapPrevious) {
        dateMapPrevious[dateKey] = (dateMapPrevious[dateKey] || 0) + 1;
      }
    }
  });

  // Convert dateMap to an array of objects
  return Object.keys(dateMap).map((date) => {
    const current = dateMap[date];
    const previous = dateMapPrevious[date];
    let percentageChange = 0;

    if (previous === 0 && current > 0) {
      percentageChange = 100;
    } else if (previous > 0) {
      percentageChange = ((current - previous) / previous) * 100;
    }

    return {
      date,
      current,
      previous,
      previousDate: dayjs(date)
        .subtract(diffDays + 1, 'day')
        .format('YYYY-MM-DD'),
      percentageChange: Number(percentageChange.toFixed(2)),
    };
  });
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    const info = payload[0].payload;
    return (
      <Card>
        <Box display="flex" flexDirection="column" p={1}>
          <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
            <Typography variant="body1" flexWrap="nowrap">
              Recordings
            </Typography>
            <Typography
              variant="body1"
              flexWrap="nowrap"
              ml={3}
              sx={{
                backgroundColor:
                  info.percentageChange > 0 ? '#e6f4ea' : info.percentageChange < 0 ? '#fff9c4' : '#f0f0f0',
                border:
                  info.percentageChange > 0
                    ? '1px solid #34a853'
                    : info.percentageChange < 0
                    ? '1px solid #fbc02d'
                    : '1px solid #9e9e9e',
                borderRadius: '4px',
                padding: '0px 6px',
                color: info.percentageChange > 0 ? '#34a853' : info.percentageChange < 0 ? '#fbc02d' : '#9e9e9e',
              }}
            >
              {info.percentageChange > 0 ? '+' : ''}
              {info.percentageChange}%
            </Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
            <Box display="flex" alignItems="center">
              <Box
                component="span"
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: '#78bb7b',
                  marginRight: 1,
                }}
              />
              <Typography variant="body1" flexWrap="nowrap" mr={3}>
                {formatCustomDate(info.date)}
              </Typography>
            </Box>
            <Typography variant="body1" flexWrap="nowrap">
              {info.current}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
            <Box display="flex" alignItems="center">
              <Box
                component="span"
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: '#787878',
                  marginRight: 1,
                }}
              />
              <Typography variant="body1" flexWrap="nowrap" mr={3}>
                {formatCustomDate(info.previousDate)}
              </Typography>
            </Box>
            <Typography variant="body1" flexWrap="nowrap">
              {info.previous}
            </Typography>
          </Box>
        </Box>
      </Card>
    );
  }

  return null;
};

function VideosGraph({ filteredVideos, periodFilter, display = 'area' }: VideosGraphProps) {
  const data = generateChartData(filteredVideos || [], periodFilter.startDate, periodFilter.endDate);
  const maxValue = Math.max(...data.map((d) => d.current), 1);

  // Calculate KPIs for the current and previous periods
  const currentPeriodVideos = filteredVideos.filter((video) =>
    dayjs(video.createdAt).isBetween(periodFilter.startDate, periodFilter.endDate, 'day', '[]'),
  );

  const periodDuration = periodFilter.endDate?.diff(periodFilter.startDate, 'day') || 0;
  const previousPeriodStart = periodFilter.startDate?.subtract(periodDuration + 1, 'day');
  const previousPeriodEnd = periodFilter.startDate?.subtract(1, 'day');

  const previousPeriodVideos = filteredVideos.filter((video) =>
    dayjs(video.createdAt).isBetween(previousPeriodStart, previousPeriodEnd, 'day', '[]'),
  );

  const calculateKPI = (videos: Video[]) => ({
    count: videos.length,
    duration: videos.reduce((acc, video) => acc + video.duration, 0),
  });

  const currentKPI = calculateKPI(currentPeriodVideos);
  const previousKPI = calculateKPI(previousPeriodVideos);

  const calculatePercentageChange = (current: number, previous: number) => {
    if (previous === 0) return current > 0 ? Infinity : 0;
    return Number((((current - previous) / previous) * 100).toFixed(2));
  };

  const recordingsChange = calculatePercentageChange(currentKPI.count, previousKPI.count);
  const durationChange = calculatePercentageChange(currentKPI.duration, previousKPI.duration);

  const renderChangePercentage = (change: number) => {
    const isInfinite = change === Infinity;
    const isPositive = change > 0;
    const isNegative = change < 0;

    return (
      <Box
        display="flex"
        alignItems="center"
        sx={{
          backgroundColor: isPositive ? '#e6f4ea' : isNegative ? '#fff9c4' : '#f0f0f0',
          border: isPositive ? '1px solid #34a853' : isNegative ? '1px solid #fbc02d' : '1px solid #9e9e9e',
          borderRadius: '4px',
          padding: '0px 6px',
          color: isPositive ? '#34a853' : isNegative ? '#fbc02d' : '#9e9e9e',
        }}
      >
        <Typography variant="body2" mr={0.5}>
          {isPositive ? '+' : ''}
        </Typography>
        {isInfinite ? (
          <AllInclusiveIcon sx={{ fontSize: '1.25rem' }} />
        ) : (
          <Typography variant="body2">{change}%</Typography>
        )}
      </Box>
    );
  };

  // Graph display options
  const [view, setView] = useState(display);
  const handleViewChange = (_event: React.SyntheticEvent, newValue: 'bar' | 'area') => {
    if (newValue === null) return;
    setView(newValue);
  };

  return (
    <>
      <Box display="flex" flexDirection="row" gap={3}>
        <Card sx={{ minWidth: 200 }}>
          <Box display="flex" flexDirection="column" p={2}>
            <Typography variant="subtitle2" color="text.secondary" mb={1}>
              Total Recordings
            </Typography>
            <Typography variant="h4" fontWeight="bold">
              {currentKPI.count}
            </Typography>
            <Box display="flex" alignItems="center" mt={1}>
              {renderChangePercentage(recordingsChange)}
              <Typography variant="body2" color="text.secondary" ml={1}>
                vs {previousKPI.count} prev
              </Typography>
            </Box>
          </Box>
        </Card>
        <Card sx={{ minWidth: 200 }}>
          <Box display="flex" flexDirection="column" p={2}>
            <Typography variant="subtitle2" color="text.secondary" mb={1}>
              Total Duration
            </Typography>
            <Typography variant="h4" fontWeight="bold">
              {formatDuration(currentKPI.duration)}
            </Typography>
            <Box display="flex" alignItems="center" mt={1}>
              {renderChangePercentage(durationChange)}
              <Typography variant="body2" color="text.secondary" ml={1}>
                vs {formatDuration(previousKPI.duration)} prev
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Box pt={2}>
          <ToggleButtonGroup value={view} exclusive size="small" onChange={handleViewChange} sx={{ height: '30px' }}>
            <ToggleButton value={'bar'}>
              <BarChartIcon />
            </ToggleButton>
            <ToggleButton value={'area'}>
              <TimelineIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {view === 'bar' && (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              width={150}
              height={40}
              data={data}
              margin={{ top: 30, right: 0, left: 0, bottom: 10 }}
              barSize={20}
            >
              {/*<CartesianGrid vertical={false} strokeDasharray="3 3" colorRendering="#548256" />*/}
              <YAxis allowDecimals={false} axisLine={{ stroke: '#e0e0e0' }} mirror={true} tickCount={maxValue + 1} />
              <XAxis
                dataKey="date"
                tickLine={false}
                minTickGap={32}
                tickMargin={8}
                tickFormatter={(value) => {
                  const date = new Date(value);
                  return formatCustomDate(date);
                }}
                axisLine={{ stroke: '#e0e0e0' }}
              />
              <Tooltip cursor={false} />
              <Bar dataKey="current" fill="#78bb7b" activeBar={<Rectangle fill="#548256" />}>
                {/*<LabelList position="top" offset={12} className="fill-foreground" fontSize={12} />*/}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
        {view === 'area' && (
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart
              width={150}
              height={40}
              data={data}
              margin={{ top: 30, right: 0, left: 0, bottom: 10 }}
              barSize={20}
            >
              <defs>
                <linearGradient id="colorCurrent" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#78bb7b" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#78bb7b" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPrevious" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#787878" stopOpacity={0.5} />
                  <stop offset="95%" stopColor="#787878" stopOpacity={0} />
                </linearGradient>
              </defs>
              {/*<CartesianGrid strokeDasharray="3 3" colorRendering="#548256" />*/}
              <YAxis allowDecimals={false} mirror={true} axisLine={{ stroke: '#e0e0e0' }} tickCount={maxValue + 1} />
              <XAxis
                dataKey="date"
                tickLine={false}
                minTickGap={32}
                tickMargin={8}
                tickFormatter={(value) => {
                  const date = new Date(value);
                  return formatCustomDate(date);
                }}
                axisLine={{ stroke: '#e0e0e0' }}
              />
              <Tooltip content={<CustomTooltip />} />
              {periodFilter.startDate && periodFilter.endDate && (
                <Area
                  type="monotone"
                  dataKey="previous"
                  stroke="#787878"
                  strokeDasharray="5 5"
                  fill="url(#colorPrevious)"
                  opacity="0.5"
                />
              )}
              <Area type="monotone" dataKey="current" stroke="#78bb7b" fill="url(#colorCurrent)" />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </Box>
    </>
  );
}

export default VideosGraph;
