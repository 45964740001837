import { createSelector } from '@reduxjs/toolkit';
import { State } from './app.types';

export const getCurrentOrganizationUuid = createSelector(
  ({ app }: { app: State }) => app.currentOrganizationUuid,
  (currentOrganizationUuid) => currentOrganizationUuid,
);

export const isVideoImportModalOpened = createSelector(
  ({ app }: { app: State }) => app.videoImportModalOpened,
  (videoImportModalOpened) => videoImportModalOpened,
);

const getCurrentVideoUuid = createSelector(
  ({ app }: { app: State }) => app.currentVideoUuid,
  (currentVideoUuid) => currentVideoUuid,
);

const getVideoView = createSelector(
  ({ app }: { app: State }) => app.videoView,
  (videoView) => videoView,
);

const selectors = {
  getCurrentOrganizationUuid,
  isVideoImportModalOpened,
  getCurrentVideoUuid,
  getVideoView,
};

export default selectors;
