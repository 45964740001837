import Box from '@mui/material/Box';
import useLoggedOrRedirect from 'hooks/routing/useLoggedOrRedirect';
import React from 'react';
import { Outlet } from 'react-router-dom';
import UserSidebar from './UserSidebar';

export default function UserLayout() {
  useLoggedOrRedirect();

  return (
    <Box display="flex" flexDirection="row">
      <UserSidebar />
      <Box sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
}
