import api from 'redux/services/api';
import { GoogleDriveChangesIntegration } from 'redux/services/google.type';

const googleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    driveChangesIntegration: builder.query<GoogleDriveChangesIntegration, void>({
      query: () => ({ url: '/google/drive/changes/integration' }),
      providesTags: ['GoogleDriveChangesIntegration'],
    }),
    driveChangesWatch: builder.mutation({
      query(body) {
        return {
          url: '/google/drive/changes/watch',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['GoogleDriveChangesIntegration'],
    }),
  }),
});

export const { useDriveChangesWatchMutation, useDriveChangesIntegrationQuery } = googleApi;
