import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro/DataGridPro/DataGridPro';
import React, { useEffect } from 'react';
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { LicenseInfo } from '@mui/x-license';
import { Video, videoCollections } from '../../../redux/services/video.types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import appSelectors from '../../../redux/App/app.selectors';
import { GridInputRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { GridCallbackDetails } from '@mui/x-data-grid/models/api';
import { useGetOrganizationQuery } from 'redux/services/organization';
import { Button } from '@mui/material';

LicenseInfo.setLicenseKey(
  'cd8e36cbe37444e9be28f1defb8f654dTz0xMjM0NSxFPTE3NTE4ODQ0OTMyMzAsUz1wcm8sTE09cGVycGV0dWFsLFBWPWluaXRpYWwsS1Y9Mg==',
);

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

/**
 * '' if no collections
 * 'collection1' if 1 collection
 * 'collection1 and X more' if X collections with X > 1
 *
 * @param collections
 */
function collectionsLabel(collections: videoCollections) {
  if (collections.length === 0) return '';
  if (collections.length === 1) return collections[0].collection.name;
  return `${collections[0].collection.name} and ${collections.length - 1} more`;
}

function formatDuration(milliseconds: number) {
  const seconds = milliseconds / 1000;
  const dur = dayjs.duration(seconds, 'seconds');

  const hours = dur.hours();
  const minutes = dur.minutes();
  const secs = dur.seconds();

  let result = '';

  if (hours > 0) result += `${hours} hours `;

  if (minutes > 0) result += `${minutes} minutes `;

  if ((secs > 0 && minutes < 1) || result === '') result += `${secs} seconds`;

  return result.trim();
}

function formatCustomDate(date: string | number | Date) {
  const inputDate = dayjs(date);
  const now = dayjs();
  const sevenDaysAgo = now.subtract(7, 'day');

  let formattedDate = '';

  if (inputDate.isToday()) {
    formattedDate = `Today, ${inputDate.format('h:mm A')}`;
  } else if (inputDate.isYesterday()) {
    formattedDate = `Yesterday, ${inputDate.format('h:mm A')}`;
  } else if (inputDate.isAfter(sevenDaysAgo)) {
    formattedDate = `${inputDate.format('ddd, MMM D [at] h:mm A')}`;
  } else if (inputDate.year() === now.year()) {
    formattedDate = `${inputDate.format('MMM D [at] h:mm A')}`;
  } else {
    formattedDate = `${inputDate.format('MMM D, YYYY h:mm A')}`;
  }

  return formattedDate;
}

function getRowId(row: { uuid: string }) {
  return row.uuid;
}

const createColumns = (currentOrganization: any): GridColDef[] => [
  {
    field: 'createdAt',
    headerName: 'Date',
    type: 'dateTime',
    valueFormatter: (value) => {
      return formatCustomDate(value);
    },
  },
  {
    field: 'title',
    headerName: 'Title',
    renderCell: (params) => {
      if (currentOrganization?.freePlan.limitDate && params.row.createdAt > currentOrganization.freePlan.limitDate) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            🔒 {params.row.title}
            <Button variant="contained" color="primary" size="small">
              Upgrade
            </Button>
          </div>
        );
      }
      return params.row.title;
    },
  },
  {
    field: 'duration',
    headerName: 'Duration',
    type: 'number',
    valueFormatter: (value, row) => {
      return formatDuration(row.duration);
    },
  },
  {
    field: 'user',
    headerName: 'Organizer',
    valueGetter: (value, row) => {
      return row.user.name;
    },
  },
  {
    field: 'collections',
    headerName: 'Collections',
    renderCell: (row: GridRenderCellParams<any, videoCollections>) => <div>{collectionsLabel(row.value || [])}</div>,
  },
  {
    field: 'share-organization',
    headerName: 'Organization Access',
    valueFormatter: (value, row) => {
      return row.organizationPermission === 'NONE' ? 'No access' : 'Can comment';
    },
  },
  {
    field: 'share-public',
    headerName: 'Public Access',
    valueFormatter: (value, row) => {
      return row.linkPermission === 'NONE' ? 'No access' : 'Can view';
    },
  },
];

interface VideoTableViewProps {
  videos?: Video[];
  rowSelectionModel?: GridInputRowSelectionModel;
  onRowSelectionModelChange?: (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => void;
}

const VideosTableView = ({ videos = [], rowSelectionModel, onRowSelectionModelChange }: VideoTableViewProps) => {
  const apiRef = useGridApiRef();
  const navigate = useNavigate();

  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization } = useGetOrganizationQuery(currentOrganizationUuid);

  useEffect(() => {
    if (videos.length === 0) return;
    const timeout = setTimeout(() => {
      apiRef.current.autosizeColumns({ includeHeaders: true, includeOutliers: true });
    }, 100);
    return () => clearTimeout(timeout);
  }, [apiRef, videos]);

  const handleRowClick = (params: any) => {
    if (currentOrganization?.freePlan.limitDate && params.row.createdAt > currentOrganization.freePlan.limitDate) {
      navigate(`/o/${currentOrganizationUuid}/settings/plan`);
      return;
    }
    navigate(`/o/${currentOrganizationUuid}/v/${params.row.uuid}`);
  };

  const columns = createColumns(currentOrganization);

  const getRowClassName = (params: any) => {
    if (currentOrganization?.freePlan.limitDate && params.row.createdAt > currentOrganization.freePlan.limitDate) {
      return 'locked-row';
    }
    return '';
  };

  return (
    <DataGrid
      apiRef={apiRef}
      getRowId={getRowId}
      rows={videos || []}
      columns={columns}
      getRowClassName={getRowClassName}
      autosizeOptions={{ includeHeaders: true, includeOutliers: true }}
      pageSizeOptions={[5]}
      checkboxSelection
      disableRowSelectionOnClick
      onRowClick={handleRowClick}
      disableColumnMenu={true}
      disableColumnReorder={true}
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={onRowSelectionModelChange}
      sx={{
        minHeight: 400,
        '& .MuiDataGrid-row': {
          cursor: 'pointer',
        },
        '& .locked-row': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-columnHeader:focus': {
          outline: 'none',
        },
      }}
    />
  );
};

export default VideosTableView;
