import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authSelectors } from 'redux/Auth';

function useLoggedOrRedirect() {
  const navigate = useNavigate();
  const isNotAuthenticated = useSelector(authSelectors.isNotAuthenticated);

  useEffect(() => {
    if (isNotAuthenticated) {
      navigate('/login');
    }
  }, [isNotAuthenticated, navigate]);
}

export default useLoggedOrRedirect;
