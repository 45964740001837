import api from 'redux/services/api';
import { Bot } from './api.types';

const botApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateBotLanguage: builder.mutation<Bot, { uuid: string; language: string }>({
      query: ({ uuid, language }) => ({
        url: `/bots/${uuid}/language`,
        method: 'PUT',
        body: { language },
      }),
      invalidatesTags: ['Bot', 'CalendarEvent'],
    }),
    updateBotPrompt: builder.mutation<Bot, { uuid: string; promptUuid: string }>({
      query: ({ uuid, promptUuid }) => ({
        url: `/bots/${uuid}/prompt`,
        method: 'PUT',
        body: { promptUuid },
      }),
      invalidatesTags: ['Bot', 'CalendarEvent'],
    }),
  }),
});

export const { useUpdateBotLanguageMutation, useUpdateBotPromptMutation } = botApi;
