import MentionCard from 'components/mention/MentionCard';
import React from 'react';
import { Invitation } from 'redux/services/api.types';
import Avatar from 'ui-kit/avatar/Avatar';
import { MentionCardProps } from './MentionCard';

type MentionCardInvitationProps = Partial<MentionCardProps> & {
  invitation: Invitation;
};

const MentionCardVideo: React.FC<MentionCardInvitationProps> = ({ invitation, ...props }) => {
  const primary = invitation.invitee ? invitation.invitee.name : invitation.email;
  const secondary = invitation.invitee ? invitation.invitee.email : undefined;
  const image = invitation.invitee ? invitation.invitee.pictureUrl : undefined;

  return (
    <MentionCard
      avatar={<Avatar src={image} name={primary} />}
      avatarShape="circle"
      primary={primary}
      secondary={secondary}
      chip="Pending"
      {...props}
    />
  );
};

export default MentionCardVideo;
