import { createSelector } from '@reduxjs/toolkit';
import { State } from './auth.types';

const getAccessToken = createSelector(
  ({ auth }: { auth: State }) => auth.accessToken,
  (accessToken) => accessToken,
);

const getRefreshToken = createSelector(
  ({ auth }: { auth: State }) => auth.refreshToken,
  (refreshToken) => refreshToken,
);

const isAuthenticated = createSelector(getAccessToken, (accessToken) => accessToken !== undefined);

const isNotAuthenticated = createSelector(getAccessToken, (accessToken) => accessToken === undefined);

const isLoggingIn = createSelector(
  ({ auth }: { auth: State }) => auth.isLoggingIn,
  (isLoggingIn) => isLoggingIn,
);

const getLoggingErrorMessage = createSelector(
  ({ auth }: { auth: State }) => auth.loginErrorMessage,
  (loginErrorMessage) => loginErrorMessage,
);

const getUser = createSelector(
  ({ auth }: { auth: State }) => auth.user,
  (user) => user,
);

const getUserUuid = createSelector(getUser, (user) => user?.uuid);
const getEmail = createSelector(getUser, (user) => user?.email);
const getUsername = createSelector(getUser, (user) => user?.username);
const getFirstName = createSelector(getUser, (user) => user?.firstName);
const getLastName = createSelector(getUser, (user) => user?.lastName);

const selectors = {
  getAccessToken,
  getRefreshToken,
  isAuthenticated,
  isNotAuthenticated,
  isLoggingIn,
  getLoggingErrorMessage,
  getUser,
  getUserUuid,
  getEmail,
  getUsername,
  getFirstName,
  getLastName,
};

export default selectors;
