import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { Button, Checkbox, List, ListItem, ListItemButton, ListItemText, Menu, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { appSelectors } from 'redux/App';
import { useGetOrganizationQuery } from 'redux/services/organization';
import { useAddCollectionMutation, useRemoveCollectionMutation } from 'redux/services/video';
import { Video } from 'redux/services/video.types';

type VideoSettingsButtonProps = {
  video: Video;
};

const VideoCollectionButton = ({ video }: VideoSettingsButtonProps) => {
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization } = useGetOrganizationQuery(currentOrganizationUuid);

  const [addCollectionMutation] = useAddCollectionMutation();
  const [removeCollectionMutation] = useRemoveCollectionMutation();
  const handleCollectionChange = (videoUuid: string, collectionUuid: string, checked: boolean) => {
    if (checked) {
      addCollectionMutation({ uuid: videoUuid, collectionUuid });
    } else {
      removeCollectionMutation({ uuid: videoUuid, collectionUuid });
    }
  };

  // Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (!video) return null;
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        aria-label="Share video"
        startIcon={<VideoLibraryIcon />}
        onClick={handleMenuIconClick}
      >
        Collections
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="video-menu"
        open={open}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{ minWidth: '200px' }}
        PaperProps={{
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <List dense>
          {currentOrganization && currentOrganization.collections.length === 0 && (
            <ListItem>
              <ListItemText sx={{ padding: '10px' }}>
                <Typography variant="body1">No collection</Typography>
              </ListItemText>
            </ListItem>
          )}
          {currentOrganization &&
            currentOrganization.collections.map((collection) => {
              const isChecked = video.collections.some(
                (videCollection) => videCollection.collection.uuid === collection.uuid,
              );
              return (
                <ListItem
                  key={collection.uuid}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      onChange={(event, checked) => handleCollectionChange(video.uuid, collection.uuid, checked)}
                      checked={isChecked}
                    />
                  }
                >
                  <ListItemButton onClick={() => handleCollectionChange(video.uuid, collection.uuid, !isChecked)}>
                    <ListItemText>
                      <Typography variant="body1">{collection.name}</Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </Menu>
    </>
  );
};

export default VideoCollectionButton;
