import { createSelector } from '@reduxjs/toolkit';

import { State } from './player.types';

const getLastSeek = createSelector(
  ({ player }: { player: State }) => player.lastSeek,
  (lastSeek) => lastSeek,
);

const getProgress = createSelector(
  ({ player }: { player: State }): number => player.progress,
  (progress) => progress,
);

export { getLastSeek, getProgress };
