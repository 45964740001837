import MentionCard from 'components/mention/MentionCard';
import dayjs from 'dayjs';
import React from 'react';
import { Video } from 'redux/services/video.types';
import Avatar from 'ui-kit/avatar/Avatar';
import { MentionCardProps } from './MentionCard';

type MentionCardVideoAndUserProps = Partial<MentionCardProps> & {
  video: Video;
};

const MentionCardVideoAndUser: React.FC<MentionCardVideoAndUserProps> = ({ video, ...props }) => {
  return (
    <MentionCard
      avatar={
        <Avatar src={video.user.pictureUrl} name={video.user.name} imgProps={{ referrerPolicy: 'no-referrer' }} />
      }
      avatarShape="circle"
      primary={video.title}
      secondary={
        <>
          {video.user.name}
          <br />
          {dayjs(video.createdAt).format('MMM D, YYYY h:mm A')}
        </>
      }
      {...props}
    />
  );
};

export default MentionCardVideoAndUser;
