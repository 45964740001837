import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authSelectors } from 'redux/Auth';

function LandingPage() {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(authSelectors.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/login/redirect');
    } else {
      navigate('/login');
    }
  });

  return (
    <Box display="flex" textAlign="center" justifyContent="center" height="100vh" flexDirection="column">
      <Box>Landing Page</Box>
    </Box>
  );
}

export default LandingPage;
