import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';
import { ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { Bot } from 'redux/services/api.types';
import { useGetPromptsQuery } from '../../../redux/services/prompt';
import { useUpdateBotPromptMutation } from '../../../redux/services/bot';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import NotesIcon from '@mui/icons-material/Notes';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import appSelectors from '../../../redux/App/app.selectors';

type BotSummaryTemplateSelectProps = {
  bot: Bot;
};

const BotSummaryTemplateSelect = ({ bot }: BotSummaryTemplateSelectProps) => {
  const navigate = useNavigate();
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const [updateBotPromptMutation, updateBotPromptResult] = useUpdateBotPromptMutation();
  const menuState = usePopupState({ variant: 'popover', popupId: 'language-select' });
  const { data: prompts } = useGetPromptsQuery();

  const selectedPrompt =
    prompts?.find((summary) => summary.uuid === bot.prompt?.uuid) || prompts?.find((summary) => summary.isDefault);

  return (
    <>
      <Tooltip title="Template to use for generating the summary" placement="top" arrow enterDelay={500}>
        <LoadingButton
          loading={updateBotPromptResult.isLoading}
          loadingPosition="start"
          size="small"
          variant="outlined"
          color="info"
          startIcon={<AutoAwesomeIcon />}
          endIcon={menuState.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          {...bindTrigger(menuState)}
        >
          {selectedPrompt ? selectedPrompt.name : 'Select Summary'}
        </LoadingButton>
      </Tooltip>
      <Menu {...bindMenu(menuState)}>
        {prompts?.map((summary) => (
          <MenuItem
            key={summary.uuid}
            onClick={() => {
              updateBotPromptMutation({ uuid: bot.uuid, promptUuid: summary.uuid });
              menuState.close();
            }}
            selected={summary.uuid === selectedPrompt?.uuid}
          >
            <ListItemIcon>
              <NotesIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{summary.name}</ListItemText>
            <ListItemIcon sx={{ paddingLeft: '12px' }}>
              {summary.uuid === selectedPrompt?.uuid ? <CheckIcon fontSize="small" /> : null}
            </ListItemIcon>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          onClick={() => {
            navigate(`/o/${currentOrganizationUuid}/settings/summary`);
          }}
        >
          <ListItemIcon>
            <AddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Create a new prompt</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default BotSummaryTemplateSelect;
