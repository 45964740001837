import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appActions } from 'redux/App';
import appSelectors from 'redux/App/app.selectors';
import { useGetOrganizationsQuery } from 'redux/services/organization';

/**
 * Hook to redirect to the current organization if it exists.
 * If not, redirect to the first organization,
 * or the Organization Creation page if there are no organizations.
 */
function useRedirectOrganization(): { isLoading: boolean } {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: organizations, isLoading } = useGetOrganizationsQuery();

  useEffect(() => {
    // Current Organization
    if (currentOrganizationUuid) {
      navigate(`/o/${currentOrganizationUuid}/calendar`);
    }
    // First Organization
    if (organizations && organizations.length > 0) {
      dispatch(appActions.currentOrganizationChanged(organizations[0].uuid));
      navigate(`/o/${organizations[0].uuid}/calendar`);
    }
    // Onboarding to create the 1st organization
    if (organizations && organizations.length === 0) {
      navigate('/onboarding');
    }
  }, [dispatch, navigate, currentOrganizationUuid, organizations]);

  return { isLoading };
}

export default useRedirectOrganization;
