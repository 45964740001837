import { Extension as ExtensionIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import useHasSalesforceIdentity from 'hooks/salesforce/useHasSalesforceIdentity';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { authActions } from 'redux/Auth';
import { useSalesforceDisconnectMutation } from 'redux/services/user';
import SalesforceLogo from 'ui-kit/logo/salesforce/salesforce.svg';

const SalesforceIntegration = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUrl = location.pathname + location.search;
  const hasSalesforceIdentity = useHasSalesforceIdentity();
  const askSalesforceCode = useCallback(() => {
    dispatch(authActions.salesforceCodeAsked({ state: { redirectionUri: currentUrl } }));
  }, [currentUrl, dispatch]);
  const [disconnectSalesforce, disconnectSalesforceResult] = useSalesforceDisconnectMutation();

  return (
    <Paper>
      <Box display="flex" flexDirection="row" p={2}>
        <Box width="100px" minHeight="75px">
          <img src={SalesforceLogo} alt="Salesforce Logo" width="48px" height="48px" />
        </Box>
        <Box flexGrow={1}>
          <Typography variant={'h3'} gutterBottom>
            Salesforce
          </Typography>
          <Typography variant={'body1'} gutterBottom>
            Sync calls from Goelo to Salesforce, to keep your Salesforce records up to date.
          </Typography>

          <Box display="flex" justifyContent="space-between" width="100%" mt={3}>
            {!hasSalesforceIdentity && (
              <Button variant="contained" onClick={askSalesforceCode}>
                Connect Salesforce
              </Button>
            )}
            {hasSalesforceIdentity && (
              <Button variant="contained" disabled={true} endIcon={<ExtensionIcon />}>
                Connected
              </Button>
            )}
            {hasSalesforceIdentity && (
              <LoadingButton
                color="error"
                variant="outlined"
                onClick={() => {
                  disconnectSalesforce();
                }}
                loadingPosition="end"
                loading={disconnectSalesforceResult.isLoading}
              >
                Disconnect
              </LoadingButton>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default SalesforceIntegration;
