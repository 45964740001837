import { ArrowBack } from '@mui/icons-material';
import { Box, Drawer } from '@mui/material';
import NavLinkItem from 'components/nav/NavLinkItem';
import React from 'react';
import Header from 'ui-kit/header/Header';

export default function UserSidebar() {
  return (
    <Drawer
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          overflowX: 'hidden',
          boxSizing: 'border-box',
          width: '240px',
        },
        width: '240px',
      }}
      variant="permanent"
      anchor="left"
    >
      <Header>
        <NavLinkItem to={`/o`} icon={<ArrowBack />} title={'Back to Organization'} />
      </Header>
      <Box flexGrow={1} />
    </Drawer>
  );
}
