import { ActionReducerMapBuilder, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SalesforceRecord } from 'redux/services/api.types';
import sidebarActions from 'redux/Sidebar/sidebar.actions';

export interface State {
  selectedVideoComment: string | null;
  selectedVideoChat: string | null;
  selectedRecord: SalesforceRecord | null;
}

const initialState = (): State => ({
  selectedVideoComment: null,
  selectedVideoChat: null,
  selectedRecord: null,
});

function close(state: State) {
  state.selectedVideoComment = null;
  state.selectedVideoChat = null;
  state.selectedRecord = null;
}

function videoCommentSelected(state: State, action: PayloadAction<string>) {
  state.selectedVideoComment = action.payload;
  state.selectedVideoChat = null;
  state.selectedRecord = null;
}

function videoChatSelected(state: State, action: PayloadAction<string>) {
  state.selectedVideoComment = null;
  state.selectedVideoChat = action.payload;
  state.selectedRecord = null;
}

function recordSelected(state: State, action: PayloadAction<SalesforceRecord>) {
  state.selectedVideoComment = null;
  state.selectedVideoChat = null;
  state.selectedRecord = action.payload;
}

export default createReducer(initialState(), (builder: ActionReducerMapBuilder<State>) =>
  builder
    .addCase(sidebarActions.close, close)
    .addCase(sidebarActions.videoCommentSelected, videoCommentSelected)
    .addCase(sidebarActions.videoChatSelected, videoChatSelected)
    .addCase(sidebarActions.recordSelected, recordSelected),
);
