import { SalesforceRecordFieldProps, SalesforceSObjectField } from 'components/salesforce/salesforce.types';
import SalesforceRecordFieldBoolean from 'components/salesforce/SalesforcePanel/SalesforceRecordFieldBoolean';
import SalesforceRecordFieldPicklist from 'components/salesforce/SalesforcePanel/SalesforceRecordFieldPicklist';
import SalesforceRecordFieldString from 'components/salesforce/SalesforcePanel/SalesforceRecordFieldString';
import React from 'react';

const fieldTypesComponents: Array<{
  supports: (field: SalesforceSObjectField) => boolean;
  component: React.FC<SalesforceRecordFieldProps>;
}> = [
  {
    supports: (field: SalesforceSObjectField) => field.type === 'string',
    component: SalesforceRecordFieldString,
  },
  {
    supports: (field: SalesforceSObjectField) => field.type === 'boolean',
    component: SalesforceRecordFieldBoolean,
  },
  {
    supports: (field: SalesforceSObjectField) => field.type === 'picklist',
    component: SalesforceRecordFieldPicklist,
  },
];

export const getFieldComponent = (field: SalesforceSObjectField): React.FC<SalesforceRecordFieldProps> | undefined => {
  return fieldTypesComponents.find((fieldComponent) => fieldComponent.supports(field))?.component;
};
