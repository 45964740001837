import { put, takeLatest } from 'redux-saga/effects';
import appActions from 'redux/App/app.actions';
import api, { apiTagsTypes } from 'redux/services/api';

const sagas = [takeLatest(appActions.currentOrganizationChanged.type, onCurrentOrganizationChanged)];

function* onCurrentOrganizationChanged() {
  yield put(api.util.invalidateTags(apiTagsTypes));
}

export default sagas;
