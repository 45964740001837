import LockIcon from '@mui/icons-material/Lock';
import { Box, Card, CardActionArea, Typography } from '@mui/material';
import MentionCardVideoAndUser from 'components/mention/MentionCardVideoAndUser';
import Thumbnails from 'components/video/Thumbnails/Thumbnails';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import appSelectors from 'redux/App/app.selectors';
import { Collection, Video } from 'redux/services/video.types';

type Props = {
  video: Video;
  collection?: Collection;
  time?: number;
  freePlanLimitDate?: Date;
};
const VideoCard = ({ video, collection, time, freePlanLimitDate }: Props) => {
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const planUrl = `/o/${currentOrganizationUuid}/settings/plan`;
  let videoUrl = `/o/${currentOrganizationUuid}/v/${video.uuid}`;
  const params = new URLSearchParams();
  if (collection) params.append('c', collection.uuid);
  if (time) params.append('t', time.toString());
  if (params.toString()) videoUrl += `?${params.toString()}`;

  if (freePlanLimitDate && video.createdAt > freePlanLimitDate) {
    return (
      <Link to={planUrl} key={video.uuid}>
        <Card sx={{ width: 320, border: 'none' }}>
          <CardActionArea>
            <Box sx={{ borderRadius: '12px', overflow: 'hidden', position: 'relative' }}>
              <Thumbnails url={video.thumbnailsUrl} blocked={true} />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  height: '180px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  pointerEvents: 'none',
                }}
              >
                <LockIcon sx={{ fontSize: '20px' }} />
                <Typography variant="body1" align="center">
                  Recording limit reached.
                  <br />
                  Upgrade to view recording.
                </Typography>
              </Box>
            </Box>
            <MentionCardVideoAndUser video={video} />
          </CardActionArea>
        </Card>
      </Link>
    );
  }

  return (
    <Link to={videoUrl.toString()} relative="route" key={video.uuid}>
      <Card sx={{ width: 320, border: 'none' }}>
        <CardActionArea>
          <Thumbnails url={video.thumbnailsUrl} />
          <MentionCardVideoAndUser video={video} />
        </CardActionArea>
      </Card>
    </Link>
  );
};

export default VideoCard;
