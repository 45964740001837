import { Check, Extension } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from 'redux/Auth';
import { GoogleScope } from 'redux/services/api.types';
import { useDriveChangesIntegrationQuery, useDriveChangesWatchMutation } from 'redux/services/google';
import { useGetCurrentUserQuery } from 'redux/services/user';
import ContainerNarrow from 'ui-kit/layout/ContainerNarrow';
import GoogleDriveLogo from 'ui-kit/logo/google/google-drive-logo.svg';
import SalesforceIntegration from './integrations/SalesforceIntegration';

function IntegrationsTab() {
  const dispatch = useDispatch();

  const [changesWatch, { isLoading: changesLoading }] = useDriveChangesWatchMutation();
  const { data: driveChangesIntegrationQuery, isLoading: driveChangesIntegrationLoading } =
    useDriveChangesIntegrationQuery();

  const [buttonProps, setButtonProps] = useState<{
    label: string;
    variant: 'text' | 'outlined' | 'contained';
    loading: boolean;
    connected: boolean;
  }>({
    label: 'Connect',
    variant: 'contained',
    loading: false,
    connected: false,
  });
  useEffect(() => {
    if (changesLoading) {
      setButtonProps({
        label: 'Connecting',
        variant: 'contained',
        loading: true,
        connected: false,
      });
    } else {
      if (driveChangesIntegrationLoading) {
        setButtonProps({
          label: 'Loading',
          variant: 'contained',
          loading: true,
          connected: false,
        });
      } else {
        if (driveChangesIntegrationQuery && driveChangesIntegrationQuery.active) {
          setButtonProps({
            label: 'Connected',
            variant: 'outlined',
            loading: false,
            connected: true,
          });
        } else {
          setButtonProps({
            label: 'Connect',
            variant: 'contained',
            loading: false,
            connected: false,
          });
        }
      }
    }
  }, [driveChangesIntegrationLoading, driveChangesIntegrationQuery, changesLoading]);

  // Drive Permission
  const [drivePermission, setDrivePermission] = useState<boolean>(true);
  const { data: currentUser, isSuccess: currentUserSuccess } = useGetCurrentUserQuery();
  useEffect(() => {
    if (currentUserSuccess) {
      const permission = currentUser.identities.some(
        (identity) => identity.provider === 'GOOGLE' && identity.scope.includes(GoogleScope.DRIVE_READONLY),
      );
      setDrivePermission(permission);
    }
  }, [currentUser, currentUserSuccess]);
  const askGoogleCode = useCallback(() => {
    dispatch(authActions.googleCodeAsked());
  }, [dispatch]);

  return (
    <ContainerNarrow sx={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingTop: '20px' }}>
      <Paper sx={{ display: 'none' }}>
        <Box display="flex" flexDirection="row" p={2}>
          <Box width="100px" minHeight="75px">
            <img src={GoogleDriveLogo} alt="Google Drive Logo" width="48px" height="48px" />
          </Box>
          <Box flexGrow={1}>
            <Typography variant={'h3'} gutterBottom>
              Google Meet & Google Drive
            </Typography>
            <Typography variant={'body1'} gutterBottom>
              Automatically import future Google Meet recordings into Goelo, to make your recordings easy to access and
              shareable.
            </Typography>
            <Typography variant={'body2'} gutterBottom>
              The Google Meet Recordings are stored in your Google Drive. Give access to Google Drive and choose which
              Google Drive folder to use.
            </Typography>

            <Box display="flex" justifyContent="space-between" width="100%" mt={3}>
              <div>
                <LoadingButton
                  disabled={!drivePermission}
                  loading={buttonProps.loading}
                  variant={buttonProps.variant}
                  loadingPosition="end"
                  endIcon={buttonProps.connected ? <Check /> : <Extension />}
                  onClick={() => {
                    changesWatch({ activate: true });
                  }}
                  sx={{ mr: 2 }}
                >
                  {buttonProps.label}
                </LoadingButton>
                {!drivePermission && (
                  <Button variant="contained" onClick={askGoogleCode}>
                    Authorize Google Drive Access
                  </Button>
                )}
              </div>
              {buttonProps.connected && (
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    changesWatch({ activate: false });
                  }}
                >
                  Disconnect
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
      <SalesforceIntegration />
    </ContainerNarrow>
  );
}

export default IntegrationsTab;
