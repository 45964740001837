import { CircularProgress, Slider, styled } from '@mui/material';

type Props = {
  isFullscreen: boolean;
};

const StyledVideoPlayer = styled('div', { shouldForwardProp: (prop) => prop !== 'isFullscreen' })<Props>`
  .video-player-aspect-ratio-outside {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: relative;
    background-color: black;
    height: ${(props) =>
      props.isFullscreen ? 'calc(100% - 48px)' : '500px'}; /* in fullscreen, we need to account for controls height */
    @media (max-width: 1168px) {
      /* (350 * 16 / 9) + side bar width */
      height: 0;
      overflow: hidden;
      padding-top: 56.25%; /* 16/9 */
    }
    .video-player-aspect-ratio-inside {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .video-player-progress-bar {
    height: 0;
    width: 100%;
    background-color: grey;
    position: relative;
    .progress-bar {
      padding: 0 10px;
      position: absolute;
      top: -15px;
      width: 100%;
    }
  }

  .video-player-controls {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: black;

    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    align-items: center;
    padding: 5px;

    .controls-group + .controls-group {
      margin-left: 10px;
    }

    .controls-group {
      display: flex;

      svg {
        color: white;
      }
      &.spacer {
        flex: 1;
      }
      &.right {
        justify-content: flex-end;
      }

      .control + .control {
        margin-left: 10px;
      }

      .control {
        &.rate {
          min-width: 37px;
          color: white;
        }
      }

      .volume-controls {
        .volume-slider-wrap {
          width: 0;
          height: 40px;
          padding: 5px;
          transition: all 200ms ease-out 0s;
          .MuiSlider-thumb {
            display: none;
            color: white;
          }
          .MuiSlider-track {
            color: white;
          }
          .MuiSlider-rail {
            color: #acc4e4;
          }

          .MuiSlider-active {
            color: white;
          }
        }

        &:hover {
          .volume-slider-wrap {
            width: 100px;

            .MuiSlider-thumb {
              display: flex;
            }
          }
        }
      }

      .duration {
        align-self: center;
        font-size: 12px;
        color: white;
      }
    }
  }
`;

export const SeekSlider = styled(Slider)`
  flex: 1;
  .MuiSlider-thumb {
    transition: left 0.1s linear;
    height: 12px;
    width: 12px;
    box-sizing: border-box;
    border: 1px solid #ffffff;
    background-color: #ffffff;
    &:hover {
      box-shadow: 0px 0px 0px 6px rgba(120, 187, 123, 0.16);
    }
    &.Mui-active {
      box-shadow: 0px 0px 0px 8px rgba(120, 187, 123, 0.16);
    }
  }
  .MuiSlider-rail {
    background-color: #ffffff;
    opacity: 0.2;
  }
`;

export const BufferLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
`;

export default StyledVideoPlayer;
