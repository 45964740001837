import SettingsIcon from '@mui/icons-material/Settings';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControlLabel,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { Team, UserSetting } from 'redux/services/api.types';
import {
  useGetCurrentUserQuery,
  useGetCurrentUserSettingQuery,
  useUpdateCurrentUserSettingAutoRecordMutation,
} from 'redux/services/user';
import { useGetTeamsQuery } from 'redux/services/team';
import { Permission } from 'redux/services/video.types';
import MentionCard from 'components/mention/MentionCard';
import MentionCardOrganization from 'components/mention/MentionCardOrganization';
import { PeopleAlt } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { appSelectors } from '../../../redux/App';
import { useGetOrganizationQuery } from '../../../redux/services/organization';
import { useFormik } from 'formik';

const RecordingSettingsButton = () => {
  console.log('RecordingSettingsButton rendering monitoring');
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization } = useGetOrganizationQuery(currentOrganizationUuid);
  const { data: settings, isLoading: userSettingIsLoading } = useGetCurrentUserSettingQuery();
  const { data: user } = useGetCurrentUserQuery();
  const [updateRecordingSettingsMutation, updateRecordingsSettingsResult] =
    useUpdateCurrentUserSettingAutoRecordMutation();
  const { data: teams } = useGetTeamsQuery();

  // Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { values, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: {
      recordChecked: false,
      organizationSharing: Permission.NONE,
      teamSharing: {} as { [key: string]: Permission },
    },
    onSubmit: (values) => {
      updateRecordingSettingsMutation({
        autoRecordExternal: values.recordChecked,
        autoRecordInternal: values.recordChecked,
        autoRecordOrganizerOnly: values.recordChecked,
        defaultOrganizationPermission: values.organizationSharing,
        defaultTeamPermissions: values.teamSharing,
      });
    },
  });

  const initForm = useCallback(
    (settings: UserSetting | undefined, teams: Team[] | undefined) => {
      if (settings) {
        setValues({
          recordChecked: settings.autoRecordExternal && settings.autoRecordInternal && settings.autoRecordOrganizerOnly,
          organizationSharing: settings.defaultOrganizationPermission || Permission.NONE,
          teamSharing:
            teams?.reduce((acc, team) => {
              acc[team.uuid] = settings.defaultTeamPermissions?.[team.uuid] || Permission.NONE;
              return acc;
            }, {} as { [key: string]: Permission }) || {},
        });
      }
    },
    [setValues],
  );

  useEffect(() => {
    if (settings) {
      initForm(settings, teams);
    }
  }, [settings, initForm, teams]);

  // Menu close
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleTransitionEnd = useCallback(() => {
    initForm(settings, teams);
  }, [initForm, settings, teams]);

  useEffect(() => {
    if (updateRecordingsSettingsResult.isSuccess) {
      handleMenuClose();
    }
  }, [handleMenuClose, updateRecordingsSettingsResult]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        aria-label="Recording Settings"
        startIcon={<SettingsIcon />}
        onClick={handleMenuIconClick}
      >
        Recording Settings
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="recording-menu"
        open={open}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{ minWidth: '200px' }}
        TransitionProps={{ onExited: handleTransitionEnd }}
      >
        <Card>
          <Typography sx={{ p: 2 }} variant="subtitle1">
            Recording Settings
          </Typography>
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Typography variant="subtitle2">Choose which meetings to record.</Typography>
            {user && <Typography variant="body2">Calendar connected: {user.email}</Typography>}
            <FormControlLabel
              sx={{ mt: 2 }}
              control={<Switch name="recordChecked" checked={values.recordChecked} onChange={handleChange} />}
              label="Record all meetings that I own"
            />
          </CardContent>
          <CardContent sx={{ p: 2 }}>
            <Typography variant="subtitle2">Choose the default sharing settings.</Typography>
            <Typography variant="body2">This settings will be used for all the next meetings.</Typography>
          </CardContent>
          <ListItem>
            <ListItemText>
              <MentionCardOrganization secondary="Organization" organization={currentOrganization} />
            </ListItemText>
            <Box sx={{ mx: 2, minWidth: '150px', textAlign: 'right' }}>
              <Select
                name="organizationSharing"
                value={values.organizationSharing}
                onChange={handleChange}
                size="small"
                variant="outlined"
              >
                <MenuItem value={Permission.COMMENT}>Can comment</MenuItem>
                <MenuItem value={Permission.NONE}>No access</MenuItem>
              </Select>
            </Box>
          </ListItem>
          {teams?.map((team) => (
            <ListItem key={team.uuid}>
              <ListItemText>
                <MentionCard
                  avatar={<PeopleAlt />}
                  avatarBackgroundColor="transparent"
                  primary={team.name}
                  secondary="Team"
                />
              </ListItemText>
              <Box sx={{ mx: 2, minWidth: '150px', textAlign: 'right' }}>
                <Select
                  name={`teamSharing.${team.uuid}`}
                  value={values.teamSharing[team.uuid] || Permission.NONE}
                  onChange={handleChange}
                  size="small"
                  variant="outlined"
                >
                  <MenuItem value={Permission.COMMENT}>Can comment</MenuItem>
                  <MenuItem value={Permission.NONE}>No access</MenuItem>
                </Select>
              </Box>
            </ListItem>
          ))}
          <Divider />
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button variant="text" color="info" onClick={handleMenuClose}>
              Cancel
            </Button>
            <LoadingButton
              loading={updateRecordingsSettingsResult.isLoading || userSettingIsLoading}
              variant="contained"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                handleSubmit();
              }}
            >
              Save
            </LoadingButton>
          </CardActions>
        </Card>
      </Menu>
    </>
  );
};

export default RecordingSettingsButton;
