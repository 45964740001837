import { Box } from '@mui/material';
import VideoCard from 'components/video/VideoCard/VideoCard';
import useCollectionByUuid from 'hooks/collection/useCollectionByUuid';
import React from 'react';
import { useParams } from 'react-router-dom';
import { isCommentItem, isVideoItem } from 'redux/services/video.types';
import Header from 'ui-kit/header/Header';
import ContainerFluid from 'ui-kit/layout/ContainerFluid';
import CollectionSettingsButton from './components/CollectionSettingsButton';

function CollectionPage() {
  const { collectionUuid } = useParams();
  const collection = useCollectionByUuid(collectionUuid);

  return (
    <div>
      <Header>
        <ContainerFluid>
          <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
            <Box flexGrow="1">Collections / {collection && collection.name}</Box>
            {collection && <CollectionSettingsButton collection={collection} />}
          </Box>
        </ContainerFluid>
      </Header>
      <ContainerFluid>
        <Box display="flex" justifyContent="left" flexWrap="wrap" gap="10px">
          {collection &&
            collection.items &&
            collection.items.map((item) => (
              <>
                {isCommentItem(item) && (
                  <VideoCard video={item.comment.video} collection={collection} time={item.comment.offset / 1000} />
                )}
                {isVideoItem(item) && <VideoCard video={item.video} collection={collection} />}
              </>
            ))}
        </Box>
      </ContainerFluid>
    </div>
  );
}

export default CollectionPage;
