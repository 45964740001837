import { Button, ListItem } from '@mui/material';
import { NavLink } from 'react-router-dom';
import tokens from 'ui-kit/tokens';

function NavLinkItem(props: { to: any; icon: any; title: any; end?: boolean }) {
  const { to, icon, title, end = false } = props;
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 1,
      }}
    >
      <NavLink to={to} style={{ flexGrow: 1 }} end={end}>
        {({ isActive }) => {
          return (
            <Button
              variant="text"
              startIcon={icon}
              sx={{
                backgroundColor: isActive ? tokens.colors.beige.dark : 'transparent',
                fontWeight: isActive ? 'bold' : 'normal',
                justifyContent: 'flex-start',
                textAlign: 'left',
                width: '100%',
                color: tokens.colors.primary.contrastText,
                '&:hover': {
                  backgroundColor: tokens.colors.beige.darker,
                },
                '& .MuiTouchRipple-child': {
                  color: tokens.colors.neutral.dark,
                },
              }}
            >
              {title}
            </Button>
          );
        }}
      </NavLink>
    </ListItem>
  );
}

export default NavLinkItem;
