import AvTimerIcon from '@mui/icons-material/AvTimer';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Chip, styled, Tab } from '@mui/material';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { playerActions } from 'redux/Player';
import { Transcript } from 'redux/services/api.types';
import { timeUtils } from 'services/time/timeUtils';
import tokens from 'ui-kit/tokens';

type TranscriptsSectionProps = {
  transcripts: Transcript[];
};

const TranscriptContainerStyled = styled('div')`
  .paragraph {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .timeColumn {
    }

    .textColumn {
      flex: 1;
      display: flex;
      flex-direction: column;

      .speaker {
        font-weight: bold;
      }
    }
  }
`;

const TranscriptsSection = ({ transcripts }: TranscriptsSectionProps) => {
  const [activeTab, setActiveTab] = useState(transcripts[0]?.uuid || 'no tab');

  const tabChange = (event: SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  if (transcripts.length === 0) return null;
  if (transcripts.length === 1) {
    return (
      <>
        {transcripts.map((transcript) => {
          return <TranscriptContainer key={transcript.uuid} transcript={transcript} />;
        })}
      </>
    );
  }
  if (transcripts.length > 1) {
    return (
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={tabChange} aria-label="basic tabs example">
            {transcripts.map((transcript) => {
              return <Tab label={transcript.source} value={transcript.uuid} />;
            })}
          </TabList>
        </Box>
        {transcripts.map((transcript) => {
          return (
            <TabPanel value={transcript.uuid}>
              <TranscriptContainer key={transcript.uuid} transcript={transcript} />
            </TabPanel>
          );
        })}
      </TabContext>
    );
  }
  return null;
};

type TranscriptContainerProps = {
  transcript: Transcript;
};

const TranscriptContainer = ({ transcript }: TranscriptContainerProps) => {
  const dispatch = useDispatch();

  const handleOffsetClick = useCallback(
    (offset: number) => {
      dispatch(playerActions.seekTo(offset));
    },
    [dispatch],
  );

  return (
    <TranscriptContainerStyled>
      {transcript.content.map((paragraph: { text: string; speaker_name: string; start: number }) => (
        <div className="paragraph" key={`start-${paragraph.start}`}>
          <div className="timeColumn">
            <Chip
              size="small"
              icon={<AvTimerIcon />}
              label={timeUtils.secondsToTime(paragraph.start)}
              style={{ cursor: 'pointer', color: tokens.colors.primary.dark }}
              onClick={() => handleOffsetClick(paragraph.start)}
            />
          </div>
          <div className="textColumn">
            <div className="speaker">{paragraph.speaker_name}</div>
            <div>{paragraph.text}</div>
          </div>
        </div>
      ))}
    </TranscriptContainerStyled>
  );
};

export default TranscriptsSection;
