import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from 'redux/rootTypes';

export const setProgress = createAction('player/SET_PROGRESS', withPayloadType<number>());
// Expects an offset in seconds to seek in the video
export const seekTo = createAction('player/SEEK_TO', withPayloadType<number>());

const actions = { setProgress, seekTo };

export default actions;
