import { Organization, SalesforceRecord, VideoComment, VideoTags } from 'redux/services/api.types';

export enum Permission {
  NONE = 'NONE',
  VIEW = 'VIEW',
  COMMENT = 'COMMENT',
}

export interface Video {
  uuid: string;
  title: string;
  duration: number;
  createdAt: Date;
  deletedAt?: Date;
  blocked?: boolean;
  user: User;
  organization: Organization;
  url: string;
  import: 'IMPORTED' | 'TO_IMPORT' | 'JOIN_REQUEST_DENIED' | 'WAITING_ROOM_TIMEOUT';
  thumbnailsUrl?: string;
  vectorized: boolean;
  organizationPermission: Permission;
  linkPermission: Permission;
  summaries: Summary[];
  tags: VideoTags;
  collections: videoCollections;
  salesforceRecords: VideoSalesforceRecords;
  teams: { team: { uuid: string } }[];
  _count: {
    comments: number;
  };
}

export interface Summary {
  uuid: string;
  content: string;
  model: string;
}

export type SummaryEdition = {
  uuid: string;
  content: string;
};

export interface VideoSharingSettings {
  linkPermission: Permission;
  organizationPermission: Permission;
  teamPermissions: { [key: string]: Permission };
}

export interface GoogleDriveFile {
  uuid: string;
  name: string;
  hasThumbnail: boolean;
  thumbnailLink?: string;
  user: User;
  video?: Video;
  createdAt: Date;
  webViewLink: string;
  trashed: boolean;
}

export interface User {
  uuid: string;
  name: string;
  email: string;
  pictureUrl: string;
}

export interface Collection {
  uuid: string;
  name: string;
  videos: CollectionVideos;
  createdAt: Date;
  updatedAt: Date;
  comments: CollectionComments;
}

export type CollectionItem = { comment: VideoComment; createdAt: Date } | { video: Video; createdAt: Date };

export type CollectionVideos = { video: Video; createdAt: Date }[];
export type CollectionComments = { comment: VideoComment; createdAt: Date }[];
export type videoCollections = { collection: Collection }[];

export const isCommentItem = (item: CollectionItem): item is { comment: VideoComment; createdAt: Date } => {
  return 'comment' in item;
};

export const isVideoItem = (item: CollectionItem): item is { video: Video; createdAt: Date } => {
  return 'video' in item;
};

export type VideoSalesforceRecords = { salesforceRecord: SalesforceRecord }[];
