import { useGetCollectionQuery } from 'redux/services/collection';
import { Collection, CollectionItem } from 'redux/services/video.types';

type CollectionWithItems = Collection & {
  items: CollectionItem[];
};

function useCollectionByUuid(uuid: string | undefined): CollectionWithItems | null {
  const { data: collection } = useGetCollectionQuery(uuid, { skip: !uuid });
  if (!collection) return null;

  const items: CollectionItem[] = [];
  if (collection) {
    items.push(...collection.comments);
    items.push(...collection.videos);
  }
  items.sort((a, b) => {
    return a.createdAt > b.createdAt ? -1 : 1;
  });

  return { ...collection, items };
}

export default useCollectionByUuid;
