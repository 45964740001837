import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import styled from '@mui/material/styles/styled';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import FormControl from 'ui-kit/form/FormControl/FormControl';
import ContainerFluid from '../../../ui-kit/layout/ContainerFluid';
import Input from 'ui-kit/form/Input/Input';
import NotesIcon from '@mui/icons-material/Notes';
import StarIcon from '@mui/icons-material/Star';
import {
  useCreatePromptMutation,
  useDeletePromptMutation,
  useGetPromptsQuery,
  useUpdatePromptMutation,
} from 'redux/services/prompt';
import { Prompt } from '../../../redux/services/api.types';
import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { v4 } from 'uuid';
import tokens from '../../../ui-kit/tokens';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import Select from '../../../ui-kit/form/Select/Select';
import { useSelector } from 'react-redux';
import { appSelectors } from '../../../redux/App';
import { useGetOrganizationQuery } from '../../../redux/services/organization';
import { useNavigate } from 'react-router-dom';

const languages = [
  { code: 'DA', name: 'Danish' },
  { code: 'NL', name: 'Dutch' },
  { code: 'EN', name: 'English' },
  { code: 'FR', name: 'French' },
  { code: 'DE', name: 'German' },
  { code: 'PL', name: 'Polish' },
  { code: 'PT', name: 'Portuguese' },
  { code: 'RU', name: 'Russian' },
  { code: 'ES', name: 'Spanish' },
  { code: 'SV', name: 'Swedish' },
];

const SummaryTabStyled = styled('div')`
  .prompt-selected {
    border: 1px solid ${tokens.colors.primary.main};

    .prompt-name {
      color: ${tokens.colors.primary.main};
    }
  }

  .prompt-name {
    font-weight: bold;
    font-size: 1rem;
  }
  .prompt-role {
    font-size: 0.8rem;
    color: #666;
  }
  .prompt-user {
    font-size: 0.8rem;
    color: #666;
    font-style: italic;
  }

  .template {
    .form-header {
      padding: 8px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
    }

    .secret {
      filter: blur(2px);
      pointer-events: none;
    }

    .form-control-line {
      display: flex;
      flex-direction: row;
      gap: 16px;
      width: 100%;

      .form-control {
        flex: 2;
      }

      .form-control-hint {
        flex: 1;
        padding-top: 24px;
      }
    }
  }
`;

function SummaryTab() {
  const navigate = useNavigate();
  const organizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization } = useGetOrganizationQuery(organizationUuid);

  // Prompt
  const { data, isFetching } = useGetPromptsQuery();
  const [prompts, setPrompts] = useState<{ [key: string]: Prompt }>({});
  const [selectedPromptId, setSelectedPromptId] = useState<string | null>(null);

  const [updatePrompt, { isLoading: isUpdating }] = useUpdatePromptMutation();
  const [createPrompt, { isLoading: isCreating }] = useCreatePromptMutation();

  // Normalize prompts
  useEffect(() => {
    if (!data) return;
    const normalized: { [key: string]: Prompt } = {};
    data.forEach((item) => {
      normalized[item.uuid] = item;
    });
    setPrompts(normalized);
  }, [data, setPrompts]);

  useEffect(() => {
    if (Object.keys(prompts).length > 0 && !selectedPromptId) {
      setSelectedPromptId(Object.keys(prompts)[0]);
    }
  }, [prompts, selectedPromptId, setSelectedPromptId]);

  const handlePromptSelection = (uuid: string) => {
    setSelectedPromptId(uuid);
  };

  const handlePromptCreation = () => {
    const uuidV4 = v4();
    createPrompt({ uuid: uuidV4, name: '', role: '', content: '' });
    setSelectedPromptId(uuidV4);
  };

  // Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Make Default
  const handleMakeDefault = () => {
    if (!selectedPromptId) return;
    updatePrompt({ ...prompts[selectedPromptId], isDefault: true });
  };

  // Delete
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = () => {
    handleMenuClose();
    setDeleteDialogOpen(true);
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  const [deletePrompt] = useDeletePromptMutation();

  const handleDeletePrompt = () => {
    if (!selectedPromptId) return;
    setDeleteDialogOpen(false);
    deletePrompt(selectedPromptId);
  };

  const isFreePlan = currentOrganization?.freePlan.free;

  return (
    <SummaryTabStyled>
      <ContainerFluid sx={{ paddingTop: '20px' }}>
        <Box display="flex" flexDirection="row" gap={2}>
          <Box display="flex" flexDirection="column" gap={2} flex="1">
            {Object.values(prompts)
              ?.sort((a: Prompt, b: Prompt) => a.id - b.id)
              .map((prompt: Prompt, index: number) => (
                <Paper
                  className={prompt.uuid === selectedPromptId ? 'prompt-selected' : ''}
                  key={index}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handlePromptSelection(prompt.uuid)}
                >
                  <Box display="flex" flexDirection="column" p={2} gap={1}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      gap={1}
                      className="prompt-name"
                    >
                      <div>{prompt.name ? prompt.name : <i>Untitled</i>}</div>
                      <Box display="flex" flexDirection="row" gap={1}>
                        {prompt.language && (
                          <Chip label={prompt.language.toLowerCase()} color="info" variant="outlined" size="small" />
                        )}
                        {prompt.isDefault && <Chip label="default" variant="outlined" color="primary" size="small" />}
                      </Box>
                    </Box>
                    <div className="prompt-role">{prompt.role?.split(' ').slice(0, 30).join(' ')}...</div>
                    <div className="prompt-user">Created by {prompt.user?.name}</div>
                  </Box>
                </Paper>
              ))}
            {!isFreePlan && (
              <Button variant="outlined" startIcon={<AddIcon />} disabled={isCreating} onClick={handlePromptCreation}>
                Create new prompt
              </Button>
            )}
            {isFreePlan && (
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                disabled={isCreating}
                onClick={() => {
                  navigate(`/o/${organizationUuid}/settings/plan`);
                }}
              >
                Create new prompt <Chip sx={{ marginLeft: '12px' }} label="Upgrade" color="primary" size="small" />
              </Button>
            )}
          </Box>
          <Box display="flex" flexDirection="column" gap={2} flex="3">
            {selectedPromptId && prompts[selectedPromptId] && (
              <Formik
                initialValues={prompts[selectedPromptId]}
                onSubmit={updatePrompt}
                key={JSON.stringify(prompts[selectedPromptId])}
              >
                {({ values, handleSubmit, handleChange, handleBlur, dirty }) => (
                  <form onSubmit={handleSubmit}>
                    <Paper className="template">
                      <div className="form-header">
                        <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                          <NotesIcon />
                          <b>Prompt Edition</b>
                        </Box>
                        <Box display="flex" flexDirection="row" gap={1}>
                          <LoadingButton
                            variant="contained"
                            size="small"
                            type="submit"
                            startIcon={<SaveIcon />}
                            loading={isUpdating}
                            disabled={!dirty || isFetching}
                            loadingPosition="start"
                          >
                            Save
                          </LoadingButton>
                          <IconButton size="small" color="info" aria-label="Edit name" onClick={handleMenuIconClick}>
                            <MoreHorizIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            id="video-menu"
                            open={open}
                            onClose={handleMenuClose}
                            onClick={handleMenuClose}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            sx={{ minWidth: '200px' }}
                            PaperProps={{
                              sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '&:before': {
                                  content: '""',
                                  display: 'block',
                                  position: 'absolute',
                                  top: 0,
                                  right: 11,
                                  width: 10,
                                  height: 10,
                                  bgcolor: 'background.paper',
                                  transform: 'translateY(-50%) rotate(45deg)',
                                  zIndex: 0,
                                },
                              },
                            }}
                          >
                            <MenuItem onClick={handleMakeDefault}>
                              <ListItemIcon>
                                <StarIcon />
                              </ListItemIcon>
                              <ListItemText>Make default</ListItemText>
                            </MenuItem>
                            {selectedPromptId && !prompts[selectedPromptId].isDefault && (
                              <MenuItem onClick={handleDeleteDialogOpen}>
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText>Delete</ListItemText>
                              </MenuItem>
                            )}
                            {selectedPromptId && prompts[selectedPromptId].isDefault && (
                              <MenuItem disabled>
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText>You can't delete the default Prompt</ListItemText>
                              </MenuItem>
                            )}
                          </Menu>
                          <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                            <DialogTitle sx={{ minWidth: '400px' }}>Delete AI Summary Template?</DialogTitle>
                            <DialogContent>
                              <p>This AI Summary template will no longer be available.</p>
                            </DialogContent>
                            <DialogActions>
                              <Button color="info" onClick={handleDeleteDialogClose}>
                                Cancel
                              </Button>
                              <Button variant="contained" onClick={handleDeletePrompt}>
                                Delete
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Box>
                      </div>
                      <Box display="flex" flexDirection="column" p={2} alignItems="flex-start">
                        <div className="form-control-line">
                          <div className="form-control">
                            <FormControl fullWidth label="Prompt Name">
                              <Input
                                autoFocus
                                autoComplete="off"
                                fullWidth
                                placeholder="Qualification"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </FormControl>
                          </div>
                          <div className="form-control-hint">
                            <Typography variant="caption" sx={{ width: '100%' }}>
                              Hint: This title is displayed in the interface but has no impact on the content generated.
                            </Typography>
                          </div>
                        </div>
                        <div className="form-control-line">
                          <div className="form-control">
                            <FormControl label="Language of the Summary">
                              <Select
                                id="language"
                                variant="outlined"
                                autoComplete="off"
                                name="language"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.language}
                              >
                                {languages.map((language) => (
                                  <MenuItem key={language.code} value={language.code}>
                                    {language.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                          <div className="form-control-hint">
                            <Typography variant="caption" sx={{ width: '100%' }}>
                              Hint: Whatever the language of the meeting, the summary will always be in the language of
                              your choice.
                            </Typography>
                          </div>
                        </div>
                        <div className="form-control-line">
                          <div className="form-control">
                            <FormControl
                              fullWidth
                              label={isFreePlan ? 'Role & Duties (Upgrade to Unlock)' : 'Role & Duties'}
                            >
                              <Input
                                autoComplete="off"
                                fullWidth
                                multiline
                                minRows="3"
                                placeholder="I want you to act as..."
                                name="role"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.role}
                                disabled={isFreePlan}
                                className={isFreePlan ? 'secret' : ''}
                              />
                            </FormControl>
                          </div>
                          <div className="form-control-hint">
                            <Typography variant="caption">
                              Hint: Giving the bot a role and obligations will help it to know what to produce.
                              <br />
                              Example: Act as a SDR, BDR, Account Executive Assistant...
                            </Typography>
                          </div>
                        </div>
                        <div className="form-control-line">
                          <div className="form-control">
                            <FormControl fullWidth label={isFreePlan ? 'Content (Upgrade to Unlock)' : 'Content'}>
                              <Input
                                autoComplete="off"
                                fullWidth
                                multiline
                                minRows="10"
                                placeholder="1) Summary: Concise en well-structured summary... &#10;2) Insights: Highlight the most important... &#10;3) Next steps: Identify the nex steps..."
                                name="content"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.content}
                                disabled={isFreePlan}
                                className={isFreePlan ? 'secret' : ''}
                              />
                            </FormControl>
                          </div>
                          <div className="form-control-hint">
                            <Typography variant="caption">
                              Hint: Describe each name and each piece of content you want generated. Be precise and
                              structured.
                            </Typography>
                          </div>
                        </div>
                      </Box>
                    </Paper>
                  </form>
                )}
              </Formik>
            )}
          </Box>
        </Box>
      </ContainerFluid>
    </SummaryTabStyled>
  );
}

export default SummaryTab;
