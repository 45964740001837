import api from 'redux/services/api';
import { GoogleCalendarEventsIntegration } from 'redux/services/google.type';
import { CalendarEvent } from './api.types';

const calendarApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<CalendarEvent[], { start: string }>({
      query: (filters) => {
        return {
          url: '/calendar/events',
          params: filters,
        };
      },
      providesTags: ['CalendarEvent'],
    }),
    countEvents: builder.query<number, void>({
      query: () => {
        return {
          url: '/calendar/events/count',
        };
      },
      providesTags: ['CalendarEvent'],
    }),
    bot: builder.mutation<CalendarEvent, { meetingUuid: string; action: 'add' | 'remove' | 'reset' }>({
      query: ({ meetingUuid, action }) => {
        return {
          url: `/calendar/events/${meetingUuid}/bot`,
          method: 'POST',
          body: { action },
        };
      },
      invalidatesTags: ['CalendarEvent'],
    }),
    // TODO: Refactor the Type (not google)
    calendarIntegration: builder.query<GoogleCalendarEventsIntegration, void>({
      query: () => {
        return {
          url: '/calendar/integration',
          method: 'GET',
        };
      },
      providesTags: ['CalendarIntegration'],
    }),
  }),
});

export const { useGetEventsQuery, useCountEventsQuery, useBotMutation, useCalendarIntegrationQuery } = calendarApi;
