import AvTimerIcon from '@mui/icons-material/AvTimer';
import { Button, Chip, ClickAwayListener, List, ListItemButton, MenuItem, Paper, Popper } from '@mui/material';
import styled from '@mui/material/styles/styled';
import Mention from '@tiptap/extension-mention';
import { Placeholder } from '@tiptap/extension-placeholder';
import { EditorContent, ReactRenderer, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import MentionCardUser from 'components/mention/MentionCardUser';
import MentionCardVideoComment from 'components/mention/MentionCardVideoComment';
import CommentMenu from 'components/video/Comment/CommentMenu';
import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { playerActions } from 'redux/Player';
import { getProgress } from 'redux/Player/player.selectors';
import { User, VideoComment } from 'redux/services/api.types';
import { useGetCurrentUserQuery } from 'redux/services/user';
import { useCreateVideoCommentMutation, useUpdateVideoCommentMutation } from 'redux/services/video-comment';
import { timeUtils } from 'services/time/timeUtils';
import tokens from 'ui-kit/tokens';

type CommentStyledProps = {
  isEditing: boolean;
  withOffset?: boolean;
};

type CommentProps = {
  videoUuid: string;
  parentUuid?: string;
  withOffset?: boolean;
  comment?: VideoComment | null; // null means new comment form
  mode: 'parent' | 'reply';
  mention: User[] | undefined;
};

const CommentStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'withOffset' && prop !== 'isEditing',
})<CommentStyledProps>`
  display: flex;
  flex-direction: ${(props) => (props.isEditing ? 'column' : 'row')};

  .editor {
    width: 100%;
    align-self: center;

    .ProseMirror {
      height: 100%;
      padding: ${(props) => (props.isEditing ? '0 10px' : '0')};
    }
    & .ProseMirror-focused:focus {
      outline: none;
    }
  }

  .actions {
    display: flex;
    text-align: right;
    align-self: ${(props) => (props.isEditing ? 'auto' : 'center')};
    margin-right: 10px;
    margin-bottom: ${(props) => (props.isEditing ? '10px' : 'center')};
    justify-content: ${(props) => (props.withOffset ? 'space-between' : 'right')};

    .offset {
      display: ${(props) => (props.withOffset ? 'block' : 'none')};
      margin: 10px 10px;
    }
  }

  .ProseMirror p.is-editor-empty:first-of-type::before {
    opacity: 33%;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  .comment-mention-user {
    border: 1px solid #000;
    border-radius: 0.4rem;
    padding: 0.1rem 0.3rem;
  }
`;

const MentionList = (props: any) => {
  const [open, setOpen] = useState<boolean>(true);
  const selectItem = (item: any) => {
    props.command({ id: item.uuid, label: item.name });
  };

  return (
    <Popper
      id="comment-mention-menu"
      anchorEl={document.querySelector('span.suggestion')}
      placement="bottom-start"
      open={open}
    >
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Paper>
          <List>
            {props.items.length ? (
              props.items.map((item: any) => (
                <ListItemButton key={item.uuid} onClick={() => selectItem(item)} sx={{ padding: '0 10px 0 0' }}>
                  <MentionCardUser user={item} />
                </ListItemButton>
              ))
            ) : (
              <MenuItem>No result</MenuItem>
            )}
          </List>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

// @see https://github.com/ueberdosis/tiptap/blob/343ce756a8e0efccadddcd05d5a1b414135d009a/packages/suggestion/src/suggestion.ts
const generateSuggestion = (users: User[] | undefined = []) => {
  return {
    items: ({ query }: { query: string }) => {
      return users.filter((item) => item.name.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5);
    },
    render: () => {
      let component: ReactRenderer<unknown, any>;
      return {
        onStart: (props: any) => {
          component = new ReactRenderer(MentionList, {
            props,
            editor: props.editor,
          });
        },
        onUpdate: (props: any) => {
          component.updateProps(props);
        },
        onExit: () => {
          component.destroy();
        },
      };
    },
  };
};

const Comment = ({ videoUuid, parentUuid, comment = null, mode, mention }: CommentProps) => {
  const dispatch = useDispatch();
  // Hooks
  const { data: currentUser } = useGetCurrentUserQuery();
  const [isFocused, setFocus] = useState<boolean>(false);
  const [isEditable, setEditable] = useState<boolean>(!comment);
  const [isLarge, setLarge] = useState<boolean>(false);
  Placeholder.configure({
    placeholder: 'Reply to this comment',
  });
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({ placeholder: parentUuid ? 'Write a reply...' : 'Comment...' }),
      Mention.configure({
        HTMLAttributes: {
          class: 'comment-mention-user',
        },
        suggestion: generateSuggestion(mention),
      }),
    ],
    editable: isEditable,
    content: comment?.content || '',
    onFocus: () => {
      setFocus(true);
    },
    onBlur: () => {
      setFocus(false);
    },
  });

  useEffect(() => {
    editor?.setEditable(isEditable);
    // TODO: Remove Autofocus ?
    // if (isEditable) {
    //   editor?.commands.focus('end');
    // }
  }, [isEditable, editor]);

  useEffect(() => {
    if (isFocused || (editor && !editor.isEmpty)) {
      setLarge(true);
    } else {
      setLarge(false);
    }
  }, [editor, isFocused]);

  const playerProgress = useSelector(getProgress);
  const [createComment, createCommentResult] = useCreateVideoCommentMutation();
  const [updateComment, updateCommentResult] = useUpdateVideoCommentMutation();

  // Creation
  const sendComment = useCallback(() => {
    editor && createComment({ videoUuid, parentUuid, offset: playerProgress, content: editor.getJSON() });
  }, [createComment, editor, parentUuid, videoUuid, playerProgress]);

  useEffect(() => {
    if (editor && createCommentResult.isSuccess) {
      editor.commands.setContent('');
      editor.commands.blur();
      setLarge(false);
    }
  }, [editor, createCommentResult]);

  // Edition
  const editComment = useCallback(() => {
    comment && editor && updateComment({ uuid: comment.uuid, content: editor.getJSON() });
  }, [updateComment, comment, editor]);

  // Cancel Edition
  const cancelEdit = useCallback(() => {
    comment && editor && editor.commands.setContent(comment.content);
    editor && editor.commands.blur();
    setEditable(false);
  }, [comment, editor]);

  useEffect(() => {
    if (editor && updateCommentResult.isSuccess) {
      setEditable(false);
    }
  }, [editor, updateCommentResult]);

  const handleEditorKeyboardInput = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (editor && event.key === 'Escape') {
        editor.commands.blur();
      }
      if (editor && event.key === 'Enter' && event.ctrlKey) {
        sendComment();
      }
    },
    [editor, sendComment],
  );

  const handleOffsetClick = useCallback(() => {
    if (comment) {
      dispatch(playerActions.seekTo(comment.offset / 1000));
    }
  }, [comment, dispatch]);

  // Early return
  if (!editor) {
    return null;
  }

  const chipLabel = (comment: VideoComment) => {
    if (comment.offsetEnd)
      return `${timeUtils.secondsToTime(comment.offset / 1000)} - ${timeUtils.secondsToTime(comment.offsetEnd / 1000)}`;
    else return timeUtils.secondsToTime(comment.offset / 1000);
  };

  return (
    <CommentStyled isEditing={isLarge} withOffset={mode === 'parent'}>
      <div>
        {!comment && <MentionCardUser user={currentUser || { name: 'Unknown' }} displayInfo={isLarge} />}
        {comment && (
          <MentionCardVideoComment
            comment={comment}
            actions={<CommentMenu comment={comment} setEditable={setEditable} />}
          />
        )}
      </div>
      <EditorContent className="editor" editor={editor} onKeyDownCapture={handleEditorKeyboardInput} />
      <div className="actions">
        <div className="offset">
          {!comment && <Chip size="small" icon={<AvTimerIcon />} label={timeUtils.secondsToTime(playerProgress)} />}
          {comment && (
            <Chip
              size="small"
              icon={<AvTimerIcon />}
              label={chipLabel(comment)}
              style={{ cursor: 'pointer', color: tokens.colors.primary.dark }}
              onClick={handleOffsetClick}
            />
          )}
        </div>
        {!comment && (
          <Button variant="outlined" onClick={sendComment}>
            {parentUuid ? 'Reply' : 'Send'}
          </Button>
        )}
        {comment && isEditable && (
          <div>
            <Button color="info" variant="outlined" onClick={cancelEdit} sx={{ marginRight: '10px' }}>
              Cancel
            </Button>
            <Button variant="outlined" onClick={editComment}>
              Edit
            </Button>
          </div>
        )}
      </div>
    </CommentStyled>
  );
};

export default Comment;
