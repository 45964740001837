import * as Fullstory from '@fullstory/browser';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { fullstoryConfig } from 'config';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfirmationProvider } from 'ui-kit/confirm';
import theme from 'ui-kit/theme';
import App from './App';
import './index.css';
import createStore from './redux/store';
import reportWebVitals from './reportWebVitals';

Fullstory.init(fullstoryConfig);

const { store, persistor } = createStore();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme()}>
          <CssBaseline />
          <BrowserRouter>
            <ConfirmationProvider>
              <App />
            </ConfirmationProvider>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
