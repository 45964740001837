import { combineReducers } from '@reduxjs/toolkit';
import { appReducer } from 'redux/App';
import { authReducer } from 'redux/Auth';
import api from 'redux/services/api';
import sidebarReducer from 'redux/Sidebar/sidebar.reducer';
import calendarReducer from './Calendar/calendar.reducer';
import { playerReducer } from './Player';

const rootReducer = () =>
  combineReducers({
    auth: authReducer,
    app: appReducer,
    player: playerReducer,
    calendar: calendarReducer,
    sidebar: sidebarReducer,
    [api.reducerPath]: api.reducer,
  });

export default rootReducer;
