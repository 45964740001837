import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { ButtonBase, styled } from '@mui/material';
import MentionCard from 'components/mention/MentionCard';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import appSelectors from 'redux/App/app.selectors';
import { useGetVideoQuery } from 'redux/services/video';
import { timeUtils } from 'services/time/timeUtils';

const VideoLinkStyled = styled(ButtonBase)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid rgba(95, 99, 109, 0.5);
  padding-right: 10px;
`;

type VideoLinkProps = {
  uuid: string;
  time: number | null;
};

const VideoLink = ({ uuid, time }: VideoLinkProps) => {
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: video } = useGetVideoQuery(uuid);
  if (!video) return null;

  let videoUrl = `/o/${currentOrganizationUuid}/v/${video.uuid}`;
  const params = new URLSearchParams();
  if (time) params.append('t', time.toString());
  if (params.toString()) videoUrl += `?${params.toString()}`;

  return (
    <>
      {video && (
        <Link to={videoUrl}>
          <VideoLinkStyled>
            <MentionCard
              avatar={<SmartDisplayIcon />}
              primary={`${video.title}${time ? ` (${timeUtils.secondsToTime(time)})` : ''}`}
              secondary={dayjs(video.createdAt).format('MMMM D, YYYY')}
            />
          </VideoLinkStyled>
        </Link>
      )}
    </>
  );
};

export default VideoLink;
