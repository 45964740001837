import { useEffect, useState } from 'react';
import { GoogleScope } from 'redux/services/api.types';
import { useGetCurrentUserQuery } from 'redux/services/user';

const useProviderPermissionState = () => {
  const [googleDriveReadPermission, setGoogleDriveReadPermission] = useState<boolean>(true);
  const [googleCalendarReadPermission, setGoogleCalendarReadPermission] = useState<boolean>(true);
  const [microsoftCalendarReadPermission, setMicrosoftCalendarReadPermission] = useState<boolean>(true);
  const [calendarReadPermission, setCalendarReadPermission] = useState<boolean>(true);
  const { data: currentUser, isSuccess: currentUserSuccess } = useGetCurrentUserQuery();

  useEffect(() => {
    if (currentUserSuccess) {
      const drivePermission = currentUser.identities.some(
        (identity) => identity.provider === 'GOOGLE' && identity.scope.includes(GoogleScope.DRIVE_READONLY),
      );
      setGoogleDriveReadPermission(drivePermission);
      const calendarPermission = currentUser.identities.some(
        (identity) => identity.provider === 'GOOGLE' && identity.scope.includes(GoogleScope.CALENDAR_READONLY),
      );
      setGoogleCalendarReadPermission(calendarPermission);
      const microsoftCalendarPermission = currentUser.identities.some(
        (identity) => identity.provider === 'MICROSOFT' && identity.scope.includes('Calendars.Read'),
      );
      setMicrosoftCalendarReadPermission(microsoftCalendarPermission);
      setCalendarReadPermission(calendarPermission || microsoftCalendarPermission);
    }
  }, [currentUser, currentUserSuccess]);

  return {
    googleDriveReadPermission,
    googleCalendarReadPermission,
    microsoftCalendarReadPermission,
    calendarReadPermission,
  };
};

export default useProviderPermissionState;
