import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControlLabel,
  LinearProgress,
  Switch,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetCurrentUserSettingQuery, useUpdateCurrentUserSettingAutoRecordMutation } from 'redux/services/user';
import onboardingBotImage from './assets/onboarding-bot.png';

const OnboardingRecordPage = () => {
  const navigate = useNavigate();
  const { data: settings, isLoading: userSettingIsLoading } = useGetCurrentUserSettingQuery();
  const [updateRecordingSettingsMutation, updateRecordingsSettingsResult] =
    useUpdateCurrentUserSettingAutoRecordMutation();

  // Form Initial
  const [recordChecked, setRecordChecked] = useState<boolean>(true);

  // Form submission
  const handleRecordChange = (switchEvent: any) => {
    setRecordChecked(switchEvent.target.checked);
  };
  const handleSaveRecordSettings = () => {
    updateRecordingSettingsMutation({
      autoRecordExternal: recordChecked,
      autoRecordInternal: recordChecked,
      autoRecordOrganizerOnly: recordChecked,
    });
  };
  useEffect(() => {
    if (updateRecordingsSettingsResult.isSuccess) {
      navigate('/login/redirect');
    }
  }, [navigate, updateRecordingsSettingsResult]);

  if (!settings) return null;
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" gap={1} sx={{ width: '400px', margin: '5px 20px' }}>
        <LinearProgress variant="determinate" sx={{ flex: '1' }} />
        <LinearProgress variant="determinate" sx={{ flex: '1' }} />
        <LinearProgress variant="determinate" sx={{ flex: '1' }} />
      </Box>
      <Card sx={{ width: '400px', margin: '0 20px' }}>
        <CardHeader
          title="Configure the Notetaker Assistant"
          subheader="The GPT-4 AI assistant will join your meetings."
        />
        <CardContent>
          <img src={onboardingBotImage} alt="notetaker bot example screenshot" width="100%" />
          <Typography variant="body1">
            Goelo Assistant automatically records, transcribes, and shares AI-generated summaries so you can focus on
            your customers.
          </Typography>
          <FormControlLabel
            sx={{ mt: 2 }}
            control={<Switch />}
            label="Record the future meetings"
            checked={recordChecked}
            onChange={handleRecordChange}
          />
        </CardContent>
        <CardActions style={{ justifyContent: 'end' }}>
          <LoadingButton
            loading={updateRecordingsSettingsResult.isLoading || userSettingIsLoading}
            variant="contained"
            color="primary"
            onClick={handleSaveRecordSettings}
            loadingPosition="start"
            startIcon={<AutoAwesomeIcon />}
          >
            Enjoy!
          </LoadingButton>
        </CardActions>
      </Card>
    </Box>
  );
};

export default OnboardingRecordPage;
