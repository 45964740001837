import { Box, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useCallback, useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import { StyledFilterMenu } from '../filter.style';
import { DateRange, DateRangeCalendar } from '@mui/x-date-pickers-pro';
import { LicenseInfo } from '@mui/x-license';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CheckIcon from '@mui/icons-material/Check';
import { Period } from '../filter.types';

LicenseInfo.setLicenseKey(
  'cd8e36cbe37444e9be28f1defb8f654dTz0xMjM0NSxFPTE3NTE4ODQ0OTMyMzAsUz1wcm8sTE09cGVycGV0dWFsLFBWPWluaXRpYWwsS1Y9Mg==',
);

interface DateFilterProps {
  initialPeriod: Period;
  onSubmit: (period: Period) => void;
}

const shortcutsItems = [
  {
    id: 'today',
    label: 'Today',
    getValue: (): DateRange<Dayjs> => {
      const today = dayjs();
      return [today, today];
    },
  },
  {
    id: 'yesterday',
    label: 'Yesterday',
    getValue: (): DateRange<Dayjs> => {
      const today = dayjs();
      return [today.subtract(1, 'day'), today.subtract(1, 'day')];
    },
  },
  {
    id: 'past7days',
    label: 'Past 7 Days',
    getValue: (): DateRange<Dayjs> => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    id: 'past30days',
    label: 'Past 30 Days',
    getValue: (): DateRange<Dayjs> => {
      const today = dayjs();
      return [today.subtract(30, 'day'), today];
    },
  },
  {
    id: 'currentWeek',
    label: 'Current Week',
    getValue: (): DateRange<Dayjs> => {
      const today = dayjs();
      return [today.startOf('week'), today.endOf('week')];
    },
  },
  {
    id: 'currentMonth',
    label: 'Current Month',
    getValue: (): DateRange<Dayjs> => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  {
    id: 'custom',
    label: 'Custom',
    getValue: (): DateRange<Dayjs> => {
      return [null, null];
    },
  },
];

function PeriodFilter({ initialPeriod, onSubmit }: DateFilterProps) {
  // Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setValue([initialPeriod.startDate, initialPeriod.endDate]);
    setShortcutSelected(initialPeriod.shortcut || 'custom');
  };
  const handleReset = () => {
    setValue([null, null]);
    setShortcutSelected('custom');
    setAnchorEl(null);
    onSubmit({ startDate: null, endDate: null, shortcut: null });
  };

  const [shortcutSelected, setShortcutSelected] = useState<string>(initialPeriod.shortcut || 'custom');

  const [value, setValue] = React.useState<DateRange<Dayjs>>([initialPeriod.startDate, initialPeriod.endDate]);
  const handleChangePeriod = (newValue: DateRange<Dayjs>) => {
    setValue(newValue);
    setShortcutSelected('custom');
  };

  // Utils
  const hasPeriod = useCallback(() => {
    return value[0] || value[1];
  }, [value]);

  // Label
  const [buttonLabel, setButtonLabel] = useState<string>('');
  useEffect(() => {
    if (!hasPeriod()) {
      setButtonLabel('');
      return;
    }
    if (shortcutSelected === 'custom') {
      setButtonLabel(`${value[0]?.format('MMM D, YYYY')} - ${value[1]?.format('MMM D, YYYY')}`);
    } else {
      setButtonLabel(shortcutsItems.find((item) => item.id === shortcutSelected)?.label || '');
    }
  }, [value, hasPeriod, shortcutSelected]);

  // Form
  const handleSubmit = () => {
    onSubmit({ startDate: value[0], endDate: value[1], shortcut: shortcutSelected });
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        color="info"
        sx={{ height: '30px' }}
        startIcon={
          hasPeriod() ? (
            <ClearIcon
              sx={{ ':hover': { color: 'error.main' } }}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleReset();
              }}
            />
          ) : (
            <AddIcon />
          )
        }
        endIcon={<ExpandMoreIcon />}
        onClick={handleClick}
      >
        <Box display="flex" flexDirection="row" gap="6px">
          <div>Period</div>
          {hasPeriod() && <Divider orientation="vertical" variant="middle" flexItem sx={{ marginY: '3px' }} />}
          {buttonLabel && <Box sx={{ color: 'primary.main' }}>{buttonLabel}</Box>}
        </Box>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className="filter-menu"
        slotProps={{
          paper: {
            sx: {
              marginTop: '6px',
              filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.10))',
            },
          },
        }}
      >
        <StyledFilterMenu>
          <Box display="flex" flexDirection="row">
            <Box className="calendar-shortcut" display="flex" flexDirection="column" gap={1}>
              <List dense>
                {shortcutsItems.map((shortcut, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        setShortcutSelected(shortcut.id || 'custom');
                        setValue(shortcut.getValue());
                      }}
                    >
                      {shortcutSelected === shortcut.id && (
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                          <CheckIcon fontSize="small" />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        inset={shortcutSelected !== shortcut.id}
                        primary={shortcut.label}
                        sx={{ '&.MuiListItemText-inset': { paddingLeft: '32px' } }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangeCalendar value={value} onChange={handleChangePeriod} calendars={2} />
            </LocalizationProvider>
          </Box>
          <div className="menu-item">
            <Box display="flex" flexDirection="row" gap={1} justifyContent="right">
              {hasPeriod() && (
                <div className="menu-item">
                  <Button
                    className="menu-button"
                    size="small"
                    fullWidth
                    variant="outlined"
                    color="info"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                </div>
              )}
              <Button
                type="submit"
                className="menu-button"
                size="small"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Apply
              </Button>
            </Box>
          </div>
        </StyledFilterMenu>
      </Menu>
    </>
  );
}

export default PeriodFilter;
