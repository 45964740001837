import useLoggedOrRedirect from 'hooks/routing/useLoggedOrRedirect';
import useRedirectOrganization from 'hooks/routing/useRedirectOrganization';
import React from 'react';

function LoginPageRedirect() {
  useLoggedOrRedirect();
  const { isLoading } = useRedirectOrganization();

  return <div>{isLoading ? 'Loading... ' : 'Done'}</div>;
}

export default LoginPageRedirect;
