import { useEffect, useState } from 'react';
import { Identity } from 'redux/services/api.types';
import { useGetCurrentUserQuery } from 'redux/services/user';

const useOpenInSalesforce = () => {
  const { data: currentUser, isSuccess: currentUserSuccess } = useGetCurrentUserQuery();
  const [salesforceIdentity, setSalesforceIdentity] = useState<Identity | null>(null);
  useEffect(() => {
    if (currentUserSuccess) {
      const sfdcIdentity = currentUser.identities.find((identity) => identity.provider === 'SALESFORCE');
      if (sfdcIdentity) {
        setSalesforceIdentity(sfdcIdentity);
      } else {
        setSalesforceIdentity(null);
      }
    }
  }, [currentUser, currentUserSuccess]);

  return (recordId: string) => {
    if (!salesforceIdentity) {
      console.log('You do not have a Salesforce identity.');
      return;
    }
    window.open(`${salesforceIdentity.instanceUrl}/${recordId}`, '_blank')?.focus();
  };
};

export default useOpenInSalesforce;
