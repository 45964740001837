import Box from '@mui/material/Box';
import { MainContent, MainMenu } from 'layouts/organization';
import MainSidebar from 'layouts/organization/MainSidebar';
import React from 'react';
import { Outlet } from 'react-router-dom';

export default function MainLayout() {
  return (
    <Box display="flex" flexDirection="row">
      <MainMenu />
      <MainContent>
        <Outlet />
      </MainContent>
      <MainSidebar />
    </Box>
  );
}
