import { createTheme, ThemeOptions } from '@mui/material';
import tokens from 'ui-kit/tokens';

const theme: ThemeOptions = {
  palette: {
    background: { default: '#fff' },
    primary: {
      main: tokens.colors.primary.main,
    },
    secondary: {
      main: tokens.colors.secondary.main,
    },
    info: {
      main: tokens.colors.neutral.main,
    },
  },
  typography: {
    body1: {
      fontSize: '14px',
      fontFamily: 'Roboto',
    },
    h1: {
      fontSize: '2rem',
      fontWeight: 'bold',
      defaultProps: {
        gutterBottom: true,
      },
    },
    h3: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          transition: 'none',
        },
        sizeMedium: {
          height: '37px',
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: tokens.colors.beige.main,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
        variant: 'outlined',
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '10px',
          '&:last-child': {
            paddingBottom: '10px',
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        variant: 'outlined',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        labelIcon: {
          minHeight: '46px',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          backgroundColor: 'inherit',
          fontWeight: 'bold',
          fontSize: '0.8rem',
          paddingLeft: '13px',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-inputRoot': {
            padding: '1px 8px',
          },
        },
        popper: {
          '& .MuiAutocomplete-groupUl .MuiAutocomplete-option': {
            paddingLeft: '16px',
            paddingTop: '2px',
            paddingBottom: '2px',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '0',
          fontSize: '10px',
        },
      },
    },
  },
};

const darkTheme: ThemeOptions = { ...theme, palette: { ...theme.palette, mode: 'dark' } };

const getTheme = () => {
  return createTheme(theme);
};

export const getDarkTheme = () => {
  return createTheme(darkTheme);
};

export default getTheme;
