import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Card, CardContent, IconButton, styled, TextareaAutosize } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useUpdateSummaryMutation } from 'redux/services/summary';
import { Summary } from 'redux/services/video.types';
import tokens from 'ui-kit/tokens';

const SummaryField = styled(TextareaAutosize)`
  width: 100%;
  border: none;
  outline: none;
  resize: vertical;
  font-size: 1rem;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  padding: 0;
  color: inherit;
`;

const SummarySection = ({ summary }: { summary: Summary }) => {
  const [editMode, setEditMode] = useState(false);
  const [summaryEdition, setSummaryEdition] = useState(summary.content);
  const [updateSummaryMutation] = useUpdateSummaryMutation();
  const onCancel = () => {
    setSummaryEdition(summary.content);
    setEditMode(false);
  };

  const onSave = () => {
    updateSummaryMutation({
      uuid: summary.uuid,
      content: summaryEdition,
    });
    setEditMode(false);
  };

  return (
    <Card sx={{ whiteSpace: 'pre-line', borderColor: tokens.colors.secondary.main }}>
      <CardContent>
        <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
          <Box flexGrow="1">
            <Typography
              sx={{
                backgroundImage: `linear-gradient(90deg,${tokens.colors.secondary.main},${tokens.colors.secondary.dark})`,
                WebkitBackgroundClip: 'text',
                display: 'inline-block',
                WebkitTextFillColor: 'transparent',
              }}
            >
              ✨ AI-powered summary
            </Typography>
          </Box>
          {!editMode && (
            <Box>
              <IconButton
                size="small"
                onClick={() => {
                  setEditMode(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </Box>
          )}
          {editMode && (
            <Box display="flex" flexDirection="row" gap="10px">
              <Button size="small" variant="outlined" color="info" onClick={onCancel}>
                Cancel
              </Button>
              <Button size="small" variant="outlined" color="primary" onClick={onSave}>
                Save
              </Button>
            </Box>
          )}
        </Box>
        {!editMode && <p>{summary.content}</p>}
      </CardContent>
      {editMode && (
        <CardContent>
          <SummaryField
            value={summaryEdition}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              setSummaryEdition(event.target.value);
            }}
          />
        </CardContent>
      )}
    </Card>
  );
};

export default SummarySection;
