import api from 'redux/services/api';

const salesforceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    salesforceRecordRecent: builder.query<any[], void>({
      query: () => ({
        url: '/salesforce/recent',
      }),
      providesTags: ['SalesforceRecord'],
    }),
    salesforceRecordSearch: builder.query<any[], { q: string; limit?: number }>({
      query: (filters) => ({
        url: '/salesforce/search',
        params: filters,
      }),
      providesTags: ['SalesforceRecord'],
    }),
    salesforceRecordSObject: builder.query<any, string>({
      query: (uuid) => ({
        url: `/salesforce/records/${uuid}/sobject`,
      }),
      providesTags: ['SalesforceRecord'],
    }),
    salesforceDescribeSObject: builder.query<any, string>({
      query: (type) => ({
        url: `/salesforce/describe/${type}`,
      }),
      providesTags: ['SalesforceDescribe'],
    }),
    updateSObject: builder.mutation<any, { uuid: string; data: any }>({
      query: ({ uuid, data }) => ({
        url: `/salesforce/records/${uuid}/sobject`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['SalesforceRecord']),
    }),
    salesforceSObjectSuggestions: builder.query<any, { uuid: string; videoUuid: string }>({
      query: ({ uuid, videoUuid }) => ({
        url: `/salesforce/records/${uuid}/video/${videoUuid}/suggestions`,
        method: 'GET',
      }),
      providesTags: ['SalesforceSuggestion'],
    }),
  }),
});

export const {
  useSalesforceRecordRecentQuery,
  useSalesforceRecordSearchQuery,
  useSalesforceRecordSObjectQuery,
  useSalesforceDescribeSObjectQuery,
  useLazySalesforceSObjectSuggestionsQuery,
  useUpdateSObjectMutation,
} = salesforceApi;
