import { Box, Button, Card, Typography } from '@mui/material';
import useGoogleAskCode from 'hooks/google/authentication/useGoogleAskCode';
import useMicrosoftAskCode from 'hooks/microsoft/authentication/useMicrosoftAskCode';
import React, { useEffect, useState } from 'react';
import { useGetCurrentUserQuery } from 'redux/services/user';
import GoogleAgendaLogo from 'ui-kit/logo/google/google-agenda-logo.svg';
import MicrosoftOutlookCalendarLogo from 'ui-kit/logo/microsoft/microsoft-outlook-calendar.svg';

const AuthorizeCalendarPermissionCard = () => {
  const [provider, setProvider] = useState<'GOOGLE' | 'MICROSOFT' | null>(null);
  const { data: currentUser, isSuccess: currentUserSuccess } = useGetCurrentUserQuery();
  const { askGoogleCode } = useGoogleAskCode();
  const { askMicrosoftCode } = useMicrosoftAskCode();

  useEffect(() => {
    if (currentUserSuccess) {
      currentUser.identities.forEach((identity) => {
        if (identity.provider === 'GOOGLE') {
          setProvider('GOOGLE');
        }
        if (identity.provider === 'MICROSOFT') {
          setProvider('MICROSOFT');
        }
      });
    }
  }, [currentUser, currentUserSuccess]);

  if (provider === null) return null;

  return (
    <>
      {provider === 'GOOGLE' && (
        <Card>
          <Box display="flex" flexDirection="row" p={2}>
            <Box width="100px" minHeight="75px">
              <img src={GoogleAgendaLogo} alt="Google Agenda Logo" width="48px" height="48px" />
            </Box>
            <Box flexGrow={1}>
              <Typography variant={'h3'} gutterBottom>
                Please, authorize Google calendar
              </Typography>
              <Typography variant={'body1'} gutterBottom>
                Goelo does not have access to your meetings.
                <br />
                Please authorize Goelo to access your Google Calendar.
              </Typography>
              <Typography variant={'body2'} gutterBottom>
                This permission is required to list your meetings.
              </Typography>
              <Box display="flex" justifyContent="space-between" width="100%" mt={3}>
                <Button color="primary" variant="contained" onClick={askGoogleCode}>
                  Authorize Google Calendar
                </Button>
              </Box>
            </Box>
          </Box>
        </Card>
      )}
      {provider === 'MICROSOFT' && (
        <Card>
          <Box display="flex" flexDirection="row" p={2}>
            <Box width="100px" minHeight="75px">
              <img
                src={MicrosoftOutlookCalendarLogo}
                alt="Microsoft Outlook Calendar Logo"
                width="48px"
                height="48px"
              />
            </Box>
            <Box flexGrow={1}>
              <Typography variant={'h3'} gutterBottom>
                Please, authorize Microsoft Outlook Calendar
              </Typography>
              <Typography variant={'body1'} gutterBottom>
                Goelo does not have access to your meetings.
                <br />
                Please authorize Goelo to access your Microsoft Outlook Calendar.
              </Typography>
              <Typography variant={'body2'} gutterBottom>
                This permission is required to list your meetings.
              </Typography>
              <Box display="flex" justifyContent="space-between" width="100%" mt={3}>
                <Button color="primary" variant="contained" onClick={askMicrosoftCode}>
                  Authorize Microsoft Outlook Calendar
                </Button>
              </Box>
            </Box>
          </Box>
        </Card>
      )}
    </>
  );
};

export default AuthorizeCalendarPermissionCard;
