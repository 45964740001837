import Timeline from 'components/video/Timeline';
import { DisplayPeriod } from 'components/video/Timeline/Timeline.types';
import React from 'react';

const TimelinesSection = ({ periods, duration }: { periods: DisplayPeriod[]; duration?: number }) => {
  const speakerNames = Array.from(new Set(periods.map((t) => t.speaker_name)));
  if (!duration) return null;

  return (
    <>
      {speakerNames.map((speaker) => (
        <Timeline
          key={speaker}
          speakerName={speaker}
          duration={duration}
          periods={periods}
          periodColor="#009DDD"
          minSizePeriod={2}
        />
      ))}
    </>
  );
};

export default TimelinesSection;
