import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';
import calendarActions from 'redux/Calendar/calendar.actions';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
dayjs.extend(weekday);

// TODO: non serializable Date. Change to string
export interface State {
  start: string;
  end: string;
}

const initialState = (): State => {
  const initialStart = dayjs().weekday(1).toISOString();
  const initialEnd = dayjs().weekday(7).toISOString();

  return {
    start: initialStart,
    end: initialEnd,
  };
};

function previousWeek(state: State) {
  state.start = dayjs(state.start).subtract(7, 'day').toISOString();
  state.end = dayjs(state.end).subtract(7, 'day').toISOString();
}

function nextWeek(state: State) {
  state.start = dayjs(state.start).add(7, 'day').toISOString();
  state.end = dayjs(state.end).add(7, 'day').toISOString();
}

function resetWeek() {
  return initialState();
}

export default createReducer(initialState(), (builder: ActionReducerMapBuilder<State>) =>
  builder
    .addCase(calendarActions.previousWeek, previousWeek)
    .addCase(calendarActions.nextWeek, nextWeek)
    .addCase(calendarActions.resetWeek, resetWeek),
);
