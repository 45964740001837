import { styled } from '@mui/material';
import SalesforcePanel from 'components/salesforce/SalesforcePanel/SalesforcePanel';
import CommentTimeline from 'components/video/Comment/CommentTimeline';
import CollectionPanel from 'pages/Collection/components/CollectionPanel';
import VideoChatPanel from 'pages/Video/components/VideoChatPanel';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import appSelectors from 'redux/App/app.selectors';
import sidebarSelectors from 'redux/Sidebar/sidebar.selectors';

const MainSidebarDesigned = styled('div')`
  width: 350px;
  border-left: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  overflow: hidden;

  .sidebar-content {
    position: fixed;
    height: 100%;
    width: 350px;

    display: flex;
    flex-direction: column;

    .sidebar-collection {
      padding: 10px;
    }

    .sidebar-panel {
      flex: 1;
      overflow-y: auto;
    }
  }
`;

const MainSidebar = () => {
  const selectedRecord = useSelector(sidebarSelectors.getSelectedRecord);
  const selectedVideoComment = useSelector(sidebarSelectors.getSelectedVideoComment);
  const selectedVideoChat = useSelector(sidebarSelectors.getSelectedVideoChat);
  const isOpen = useSelector(sidebarSelectors.isOpen);

  // Collection
  const [urlSearchParams] = useSearchParams();
  const collectionUuid = urlSearchParams.get('c');
  const videoUuid = useSelector(appSelectors.getCurrentVideoUuid);

  if (!isOpen && !collectionUuid) return null;

  return (
    <MainSidebarDesigned>
      <div className="sidebar-content">
        {collectionUuid && videoUuid && (
          <div className="sidebar-collection">
            <CollectionPanel collectionUuid={collectionUuid} videoUuid={videoUuid} />
          </div>
        )}
        <div className="sidebar-panel">
          {selectedRecord && <SalesforcePanel />}
          {selectedVideoComment && <CommentTimeline videoUuid={selectedVideoComment} />}
          {selectedVideoChat && <VideoChatPanel videoUuid={selectedVideoChat} />}
        </div>
      </div>
    </MainSidebarDesigned>
  );
};

export default MainSidebar;
