import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryReauth from 'redux/services/api.baseQuery';
import { apiConfig } from 'config';

export const apiTagsTypes = [
  'Bot',
  'CalendarEvent',
  'CalendarIntegration',
  'Collection',
  'GoogleDriveChangesIntegration',
  'GoogleDriveFile',
  'Identity',
  'Invitation',
  'Organization',
  'Prompt',
  'SalesforceRecord',
  'Tag',
  'Team',
  'User',
  'Video',
  'VideoComment',
  'VideoCount',
];

const api = createApi({
  reducerPath: 'api',
  tagTypes: apiTagsTypes,
  baseQuery: baseQueryReauth({ entrypoint: apiConfig.entrypoint }),
  endpoints: () => ({}),
});

export default api;
