import { MenuItem } from '@mui/material';
import { SalesforceRecordFieldProps } from 'components/salesforce/salesforce.types';
import { useId } from 'react';
import FormControl from 'ui-kit/form/FormControl/FormControl';
import Select from 'ui-kit/form/Select/Select';

const SalesforceRecordFieldPicklist = ({
  field,
  label,
  value,
  disabled,
  error,
  helperText,
  InputProps,
}: SalesforceRecordFieldProps) => {
  const id = useId();

  return (
    <FormControl fullWidth id={id} helperText={helperText} label={label} error={error}>
      <Select
        id={id}
        variant="outlined"
        disabled={disabled}
        autoComplete="off"
        defaultValue={value}
        fullWidth
        onChange={console.log}
        {...InputProps}
      >
        {field.picklistValues.map((picklistValue) => (
          <MenuItem key={picklistValue.value} value={picklistValue.value}>
            {picklistValue.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SalesforceRecordFieldPicklist;
