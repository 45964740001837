import { alpha } from '@mui/material';

const tokens = {
  colors: {
    primary: {
      main: '#78bb7b',
      light: '#c4e1c5',
      dark: '#4c8a4a',
      contrastText: '#101a11',
    },
    secondary: {
      main: '#29b6f6',
      light: '#4dd0e1',
      dark: '#0069c0',
      contrastText: '#fff',
    },
    neutral: {
      dark: '#3c4049',
      main: '#5f636d',
      light: '#8a8d96',
    },
    beige: {
      darker: '#ddd6cf',
      dark: '#e5dfda',
      main: '#f8f4f1',
    },
  },
  border: {
    colors: {
      transparent: alpha('rgb(0,0,0)', 0.12),
    },
  },
};
export default tokens;
