import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { BASE_URL, googleConfig, microsoftConfig, salesforceConfig } from 'config';
import jwtDecode from 'jwt-decode';
import { call, getContext, put, takeLatest, takeLeading } from 'redux-saga/effects';
import authActions from 'redux/Auth/auth.actions';
import { AuthorizationCode, AuthParam, LoginParam, ObjectQuery, State, User } from 'redux/Auth/auth.types';
import { HttpClient } from 'services/network';

const sagas = [
  takeLatest(authActions.googleCodeAsked.type, onGoogleCodeAsked),
  takeLeading(authActions.loginWithGoogleCode.type, onLoginWithGoogleCode),
  takeLatest(authActions.salesforceCodeAsked.type, onSalesforceCodeAsked),
  takeLatest(authActions.microsoftCodeAsked.type, onMicrosoftCodeAsked),
  takeLeading(authActions.loginWithMicrosoftCode.type, onLoginWithMicrosoftCode),
];

//////// GOOGLE ////////

/**
 * @param obj
 * example:
 * input -> { aKey: 'valueOfAKey', anotherKey: 'anotherValue' }
 * output -> ?aKey=valueOfAKey&anotherKey=anotherValue
 */
function toQueryString(obj: ObjectQuery): string {
  return Object.keys(obj)
    .map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    })
    .join('&');
}

function* onGoogleCodeAsked({ payload }: PayloadAction<AuthParam>) {
  // Parameters to pass to OAuth 2.0 endpoint.
  const params: { [key: string]: any } = {
    client_id: googleConfig.clientId,
    redirect_uri: `${BASE_URL}/login`, // URI should match with a redirect uri in the console.cloud.google.com
    response_type: 'code',
    access_type: 'offline',
    scope: payload?.scope ?? 'email profile openid https://www.googleapis.com/auth/calendar.readonly',
    include_granted_scopes: 'true', // Starting + Incremental requests
  };

  if (payload?.state) {
    params.state = JSON.stringify(payload.state);
  }

  const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?${toQueryString(params)}`;

  yield window.location.assign(authUrl);
}

//////// SALESFORCE ////////

function* onSalesforceCodeAsked({ payload }: PayloadAction<AuthParam>) {
  // Parameters to pass to OAuth 2.0 endpoint.
  const params: { [key: string]: any } = {
    client_id: salesforceConfig.clientId,
    redirect_uri: `${BASE_URL}/user/integrations/salesforce`, // URI should match with a redirect uri in the lightning/setup/ConnectedApplication/page
    response_type: 'code',
  };

  if (payload?.state) {
    params.state = JSON.stringify(payload.state);
  }

  const authUrl = `${salesforceConfig.orgUrl}/services/oauth2/authorize?${toQueryString(params)}`;

  yield window.location.assign(authUrl);
}

//////// MICROSOFT ////////

// https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=c814b6f3-8b92-4f64-a560-b716c0543aaf&redirect_uri=http%3A%2F%2Flocalhost&scope=openid+calendars.read+user.read+offline_access&response_type=code&response_mode=query
function* onMicrosoftCodeAsked({ payload }: PayloadAction<AuthParam>) {
  // Parameters to pass to OAuth 2.0 endpoint.
  const params: { [key: string]: any } = {
    client_id: microsoftConfig.clientId,
    redirect_uri: `${BASE_URL}/login/microsoft`, // URI should match with a redirect uri in the console.cloud.google.com
    response_type: 'code',
    scope: payload?.scope ?? 'openid calendars.read user.read offline_access',
    response_mode: 'query',
  };

  if (payload?.state) {
    params.state = JSON.stringify(payload.state);
  }

  const authUrl = `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?${toQueryString(params)}`;

  yield window.location.assign(authUrl);
}

function* onLoginWithGoogleCode({ payload }: PayloadAction<LoginParam>) {
  const httpClient: HttpClient = yield getContext('httpClient');
  let response: AxiosResponse<AuthorizationCode>;

  try {
    response = yield call(httpClient.post, '/auth/login', payload);

    const user = jwtDecode<User>(response.data.accessToken);

    const data: State = {
      user,
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
      isLoginError: false,
      isLoggingIn: false,
      isRefreshingToken: false,
    };

    yield put(authActions.loginSuccess(data));
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<{ message: string }>;
      const message = axiosError.response?.data?.message ? { message: axiosError.response?.data?.message } : undefined;
      yield put(authActions.loginFailure(message));
    } else {
      yield put(authActions.loginFailure());
    }
  }
}

function* onLoginWithMicrosoftCode({ payload }: PayloadAction<LoginParam>) {
  const httpClient: HttpClient = yield getContext('httpClient');
  let response: AxiosResponse<AuthorizationCode>;

  try {
    response = yield call(httpClient.post, '/auth/login/microsoft', payload);

    const user = jwtDecode<User>(response.data.accessToken);

    const data: State = {
      user,
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
      isLoginError: false,
      isLoggingIn: false,
      isRefreshingToken: false,
    };

    yield put(authActions.loginSuccess(data));
  } catch (error) {
    // TODO: Add Sentry catch error
    console.error(error);
    yield put(authActions.loginFailure());
  }
}

export default sagas;
