import { Autocomplete, Box, TextField } from '@mui/material';
import useSalesforceRecordSearch from 'hooks/salesforce/useSalesforceRecordSearch';
import sortBy from 'lodash/sortBy';
import React, { useCallback } from 'react';
import { SalesforceRecord } from 'redux/services/api.types';
import { useSalesforceRecordRecentQuery } from 'redux/services/salesforce';
import { useAddSalesforceRecordMutation } from 'redux/services/video';
import { Video } from 'redux/services/video.types';
import SalesforceIcon from 'ui-kit/logo/salesforce/salesforce-icon';
import { EnhancedRenderOption, EnhancedRenderOptionProps } from './Autocomplete/Autocomplete.EnhancedRenderOption';
import SalesforceRecordButton from './SalesforceRecordButton';
import SalesforceRecordIcon from './SalesforceRecordIcon/SalesforceRecordIcon';

const SalesforceSection = ({ video }: { video: Video }) => {
  const [currentSearchKey, setCurrentSearchKey] = React.useState<string>('initial');
  const [currentSearch, setCurrentSearch] = React.useState<string>('');
  const handleSearch = React.useCallback((_: any, value: string) => {
    setCurrentSearch(value);
  }, []);
  const { data: searchResult, isFetching: isFetchingSearch } = useSalesforceRecordSearch(currentSearch);
  const { data: recentResult, isFetching: isFetchingRecent } = useSalesforceRecordRecentQuery();
  const [addRecord] = useAddSalesforceRecordMutation();

  const matchingSuggestions = React.useMemo(() => {
    if (searchResult) return sortBy(searchResult, (record) => record.attributes.type);
    return [];
  }, [searchResult]);

  const recentSuggestions = React.useMemo(() => {
    if (recentResult) return sortBy(recentResult, (record) => record.attributes.type);
    return [];
  }, [recentResult]);

  const sortedSalesforceRecords = React.useMemo(() => {
    if (video.salesforceRecords) return sortBy(video.salesforceRecords, (record) => record.salesforceRecord.type);
    return [];
  }, [video.salesforceRecords]);

  function getOptionDescription(option: SalesforceRecord) {
    if (option.attributes.type === 'LEAD' && (option.Email || option.Company)) {
      if (!option.Email) {
        return option.Company;
      } else if (!option.Company) {
        return option.Email;
      }

      return `${option.Email} - ${option.Company}`;
    } else if (option.attributes.type === 'CONTACT' && (option.Email || option.Account?.Name)) {
      if (!option.Email) {
        return option.Account.Name;
      } else if (!option.Account?.Name) {
        return option.Email;
      }
      return `${option.Email} - ${option.Account.Name}`;
    }

    return undefined;
  }

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: SalesforceRecord) => {
    const description = getOptionDescription(option);

    return (
      <EnhancedRenderOption
        {...(props as EnhancedRenderOptionProps)}
        key={option.Id}
        label={option.Name}
        description={description ? <Box component="span">{description}</Box> : undefined}
        startIcon={<SalesforceRecordIcon type={option.attributes.type} className="MuiAutocomplete-option-startIcon" />}
      />
    );
  };

  const handleSelect = useCallback(
    (_: any, value: SalesforceRecord | null) => {
      setCurrentSearch('');
      setCurrentSearchKey(new Date().toISOString()); // Hack to force the Autocomplete to re-render and clear the input value.
      if (!value) return;
      addRecord({ videoUuid: video.uuid, salesforceResource: { id: value.Id, type: value.attributes.type } });
    },
    [addRecord, video.uuid],
  );

  return (
    <Box display="flex" flexDirection="row" gap="10px" flexWrap="wrap">
      {sortedSalesforceRecords.map((item) => (
        <SalesforceRecordButton video={video} salesforceRecord={item.salesforceRecord} />
      ))}
      <Autocomplete
        id="salesforce-record-autocomplete"
        key={currentSearchKey}
        options={currentSearch.length >= 2 ? matchingSuggestions : recentSuggestions ?? []}
        getOptionLabel={(option: SalesforceRecord) => option.Name ?? 'Unavailable name'}
        sx={{ width: 238 }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Link to Salesforce Record"
            InputProps={{
              ...params.InputProps,
              startAdornment: <SalesforceIcon fontSize="small" color="info" />,
            }}
          />
        )}
        noOptionsText="No Salesforce records found"
        inputValue={currentSearch}
        onInputChange={handleSearch}
        loading={isFetchingSearch || isFetchingRecent}
        filterOptions={(options) => options} // Component not filtering options.
        renderOption={renderOption}
        groupBy={(option) => option.attributes.type}
        onChange={handleSelect}
      />
    </Box>
  );
};

export default SalesforceSection;
