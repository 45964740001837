import { ListItem } from '@mui/material';
import Button from '@mui/material/Button';
import MentionCardOrganization from 'components/mention/MentionCardOrganization';
import dayjs from 'dayjs';
import React from 'react';
import { Invitation } from 'redux/services/api.types';
import { useAcceptOrDeclineInvitationMutation } from 'redux/services/user';

const InvitationReceivedItem = ({ invitation, divider }: { invitation: Invitation; divider: boolean }) => {
  const [acceptOrDeclineInvitation] = useAcceptOrDeclineInvitationMutation();

  let secondary = undefined;
  switch (invitation.status) {
    case 'PENDING':
      secondary = `Invited by ${invitation.inviter.name}`;
      break;
    case 'ACCEPTED':
      secondary = `Invited by ${invitation.inviter.name} (Accepted ${dayjs(invitation.acceptedAt).format(
        'MMM DD, YYYY',
      )})`;
      break;
    case 'DECLINED':
      secondary = `Invited by ${invitation.inviter.name} (Declined ${dayjs(invitation.declinedAt).format(
        'MMM DD, YYYY',
      )})`;
      break;
  }

  return (
    <ListItem
      key={invitation.uuid}
      data-cy="invitation-received"
      divider={divider}
      secondaryAction={
        invitation.status === 'PENDING' && (
          <>
            <Button
              size="small"
              variant="outlined"
              color="info"
              sx={{ marginRight: '20px' }}
              onClick={() => {
                acceptOrDeclineInvitation({ uuid: invitation.uuid, status: 'DECLINED' });
              }}
            >
              Decline
            </Button>
            <Button
              data-cy="accept-invitation-button"
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                acceptOrDeclineInvitation({ uuid: invitation.uuid, status: 'ACCEPTED' });
              }}
            >
              Accept invitation
            </Button>
          </>
        )
      }
    >
      <MentionCardOrganization organization={invitation.organization} secondary={secondary} />
    </ListItem>
  );
};

export default InvitationReceivedItem;
