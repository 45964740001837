import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { getCurrentOrganizationUuid } from 'redux/App/app.selectors';
import { State as AppState } from 'redux/App/app.types';
import { authActions, authSelectors } from 'redux/Auth';
import { State as AuthState } from 'redux/Auth/auth.types';

interface BaseQueryReauthOptions {
  entrypoint: string;
}

let refreshTokenQuery: MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>> | null = null;

function baseQueryReauth({
  entrypoint,
}: BaseQueryReauthOptions): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> {
  const baseQuery = fetchBaseQuery({
    baseUrl: entrypoint,
    prepareHeaders: (headers, { getState }) => {
      const token = authSelectors.getAccessToken(getState() as { auth: AuthState });
      const organizationUuid = getCurrentOrganizationUuid(getState() as { app: AppState });
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      if (organizationUuid) {
        //TODO: Change the name of custom header with the name of the application
        headers.set('x-app-organization-uuid', organizationUuid);
      }
      return headers;
    },
  });

  const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
  ) => {
    let result = await baseQuery(args, api, extraOptions);

    let wasAlreadyRefreshing = false;
    if (result.error && result.error.status === 401) {
      // In case a login is in process we wait for it to finish before trying to retry the request and the token
      while (authSelectors.isLoggingIn(api.getState() as { auth: AuthState })) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      // try to get a new token
      const refreshToken = authSelectors.getRefreshToken(api.getState() as { auth: AuthState });

      if (!refreshTokenQuery) {
        refreshTokenQuery = baseQuery(
          { url: '/auth/refresh', body: { rt: refreshToken }, method: 'POST' },
          api,
          extraOptions,
        );
        api.dispatch(authActions.refreshTokenInitiated());
      } else {
        wasAlreadyRefreshing = true;
      }

      const refreshResult = await refreshTokenQuery;
      refreshTokenQuery = null;

      if (refreshResult.data) {
        if (!wasAlreadyRefreshing) {
          const { accessToken, refreshToken } = refreshResult.data as { accessToken: string; refreshToken: string };
          // store the new token
          api.dispatch(authActions.refreshTokenSuccess({ accessToken, refreshToken }));
        }
        // retry the initial query
        result = await baseQuery(args, api, extraOptions);
      } else {
        if (!wasAlreadyRefreshing) {
          api.dispatch(authActions.refreshTokenFailure());
        }

        const authenticated = authSelectors.isAuthenticated(api.getState() as { auth: AuthState });
        if (authenticated) {
          api.dispatch(authActions.logout());
        }
      }
    }

    return result;
  };

  return baseQueryWithReauth;
}

export default baseQueryReauth;
