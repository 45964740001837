import api from 'redux/services/api';
type Session = {
  url: string;
};

// Type body with optional referrerId
export type CreateCheckoutSessionBody = {
  referralId?: string;
};

const stripeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createCheckoutSession: builder.mutation<Session, CreateCheckoutSessionBody>({
      query: (body = {}) => {
        return {
          url: '/stripe/create-checkout-session',
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const { useCreateCheckoutSessionMutation } = stripeApi;
