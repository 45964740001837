import { Chip } from '@mui/material';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Icon from 'components/icon/Icon';
import React from 'react';

type MentionCardStyledProps = {
  variant?: 'outlined';
  avatarShape?: 'circle' | 'square' | 'rounded';
  avatarBackgroundColor?: string;
};

export type MentionCardProps = MentionCardStyledProps & {
  avatar: React.ReactNode | string;
  primary: string;
  secondary?: React.ReactNode | string;
  chip?: string;
  actions?: React.ReactNode;
  secondaryLine?: boolean;
  displayInfo?: boolean;
};

const MentionCardStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'avatarShape' && prop !== 'avatarBackgroundColor',
})<MentionCardStyledProps>`
  display: flex;
  flex-direction: row;
  min-height: 60px;
  border: ${(props) => (props.variant === 'outlined' ? `1px solid #e0e0e0` : 'none')};

  .mention-card-info-zone {
    flex: 1;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .mention-card-actions-zone {
    padding: 12px 10px;
  }
`;

function MentionCard({
  avatar,
  avatarShape = 'rounded',
  avatarBackgroundColor,
  primary = 'loading',
  secondary,
  chip,
  variant,
  actions,
  secondaryLine = true,
  displayInfo = true,
}: MentionCardProps) {
  return (
    <MentionCardStyled variant={variant}>
      {avatar && (
        <Icon shape={avatarShape} backgroundColor={avatarBackgroundColor}>
          {avatar}
        </Icon>
      )}
      {displayInfo && (
        <div className="mention-card-info-zone">
          <Typography variant="body1" sx={{ textAlign: 'left' }}>
            {primary} {chip && <Chip label={chip} size="small" />}
          </Typography>
          {secondaryLine && secondary && (
            <Typography variant="body2" color="text.secondary" display="block" sx={{ textAlign: 'left' }}>
              {secondary}
            </Typography>
          )}
        </div>
      )}
      {actions && <div className="mention-card-actions-zone">{actions}</div>}
    </MentionCardStyled>
  );
}

export default MentionCard;
