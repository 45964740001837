import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, IconButton, styled, ThemeProvider, Typography } from '@mui/material';
import VideoChat from 'pages/Video/components/VideoChat';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useGetVideoQuery } from 'redux/services/video';
import sidebarActions from 'redux/Sidebar/sidebar.actions';
import { getDarkTheme } from 'ui-kit/theme';

type VideoChatPanelProps = {
  videoUuid: string;
};

const VideoChatPanelStyled = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: #212121;
  height: 100%;

  .chat-title {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    overflow: hidden;
    flex: 1;
    color: rgba(255, 255, 255, 0.7);

    .chat-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;
    }
  }

  .chat-content {
    flex: 1;
    overflow: hidden;
  }
`;

const VideoChatPanel = ({ videoUuid }: VideoChatPanelProps) => {
  const dispatch = useDispatch();
  const { data: video } = useGetVideoQuery(videoUuid);

  /**
   * UI
   */
  const handleClear = useCallback(() => {
    dispatch(sidebarActions.close());
  }, [dispatch]);

  if (!video) return null;

  return (
    <ThemeProvider theme={getDarkTheme}>
      <VideoChatPanelStyled>
        <Box display="flex" flexDirection="row" padding="10px" gap="10px">
          <IconButton size="small" onClick={handleClear}>
            <CloseIcon />
          </IconButton>
          <Typography variant="body1" className="chat-title">
            <AutoAwesomeIcon fontSize="small" />
            <div className="chat-name">Insights</div>
          </Typography>
        </Box>
        <Divider />
        <div className="chat-content">
          <VideoChat video={video} />
        </div>
      </VideoChatPanelStyled>
    </ThemeProvider>
  );
};

export default VideoChatPanel;
